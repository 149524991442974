<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-dialog v-model="reportDialog" max-width="600">
            <v-card>
                <v-card-title>
                    نتایج رای گیری تا این لحظه
                </v-card-title>
                <v-card-text v-if="reportItem!=null">
                    <v-row justify="center">
                        <v-col cols="6" sm="auto" class="text-center">
                            میانگین درصد
                            <v-chip class="mx-2 primary">{{reportItem.averageCollectedPercentage}}</v-chip>
                        </v-col>
                        <v-col cols="6" sm="auto" class="text-center">
                            تعداد آرا
                            <v-chip class="mx-2 primary">{{reportItem.voteCount}}</v-chip>
                        </v-col>
                        <v-col cols="6" sm="auto" class="text-center">
                            سهام تقریبی
                            <v-chip class="mx-2 primary">{{reportItem.approximateCollectedShareAmount}}</v-chip>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="reportDialog = false">بستن</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-fade-transition>

            <v-dialog v-model="requestVoteDialog" persistent max-width="600">

                <v-card v-if="requestVoteItem!=null">
                    <v-card-title class="headline">
                        <span>{{requestDialogTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                        به منظور ثبت درخواست برای اخذ رای، شما باید برنامه توسعه خود را برای مجمع مورد نظر ارسال نمایید.
                        <v-file-input show-size label="رزومه"
                                      dense
                                      accept="file/docs,xlsx,xsl"
                                      outlined
                                      @change="onFileChange"
                                      class="ma-3"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="requestVoteDialog = false">بستن</v-btn>
                        <v-btn color="primary darken-1" class="elevation-0" @click="sendRequestVote">ارسال</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </v-fade-transition>


        <v-fade-transition>
            <v-dialog
                    v-model="removeItemDialog"
                    v-if="requestToRemove !=null"
                    max-width="400"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا از حذف این آیتم اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="secondary"
                                text
                                @click="removeItemDialog = false"
                        >
                            خیر
                        </v-btn>

                        <v-btn
                                color="primary"
                                text
                                @click="removeVoteRequest"
                        >
                            بله
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>

        <v-fade-transition>
            <v-dialog v-model="editingMode" v-if="editingItem != null" max-width="600px">
                <v-card>
                    <v-card-title>
                    <span class="headline">
                        <span>ویرایش سهام</span>
                    </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" class="pa-0 ma-0 text-center">
                                    <v-avatar size="80" class="elevation-1">
                                        <v-img

                                                :src="staticBaseURL + editingItem.company.avatar"
                                                alt="Company-logo"
                                                contain
                                        >
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    نام شرکت : {{editingItem.company.name}}
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span>میزان سهام: </span>
                                    <span>{{editingItem.shareAmount}}</span>

                                </v-col>
                                <v-col cols="6" class="text-center">
                                    <v-text-field v-model="editingItemShare" outlined
                                                  label="میزان سهام جدید"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="finishEditing">ذخیره</v-btn>
                        <v-btn color="red darken-1" text @click="cancelEditing">بستن</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>

        <v-card-title>
            درخواست اخذ رای
            <v-spacer></v-spacer>
            <!--<v-btn small @click="showAddCompany = true" class="primary elevation-0">-->
            <!--<v-icon small>mdi-plus</v-icon>-->
            <!--افزودن شرکت جدید-->
            <!--</v-btn>-->
        </v-card-title>

        <hr>
        <v-container>

            <v-row>
                <v-col>
                    <div class="title">
                        <span>درخواست های ارسالی</span>
                    </div>
                    <v-card class="mt-3 elevation-1">

                        <v-card-text>

                            <v-data-table
                                    :headers="sentRequestHeaders"
                                    hide-default-footer
                                    :items="sentRequests"
                            >
                                <template v-slot:item.vote.companyBaseInfo.avatar="{item}">

                                    <v-avatar large class="elevation-0">
                                        <v-img :src="'http://betabours.ir:8080'+item.vote.companyBaseInfo.avatar"
                                               contain
                                        >
                                        </v-img>
                                    </v-avatar>

                                </template>
                                <template v-slot:item.companyBaseInfo.name="{item}">

                                    {{item.vote.companyBaseInfo.name}}

                                </template>

                                <template v-slot:item.title="{item}">

                                    {{item.vote.title}}

                                </template>

                                <template v-slot:item.startDateTime="{item}">
                                    {{item.vote.startDateTime | toJalali}}
                                </template>
                                <template v-slot:item.endDateTime="{item}">
                                    {{item.vote.endDateTime | toJalali}}
                                </template>
                                <template v-slot:item.state.title="{item}">
                                    <v-chip :class="{
                                    'green white--text':item.state.value == 2,
                                    'red white--text':item.state.value == 3
                                    }">
                                        {{item.state.title}}
                                    </v-chip>
                                </template>


                                <template v-slot:item.actions="{item}">


                                    <v-btn small text @click="showEditRequestVoteDialog(item)">
                                        <v-icon color="primary" small>fas fa-vote-yea</v-icon>
                                    </v-btn>

                                    <v-btn small text @click="showVoteRequestDeleteDialog(item)">
                                        <v-icon color="red" small>fas fa-trash</v-icon>
                                    </v-btn>

                                    <v-btn small text @click="showReportsDialog(item)">
                                        <v-icon color="green" small>fas fa-poll</v-icon>
                                    </v-btn>

                                </template>

                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-pagination
                                    v-model="page"
                                    :length="length"
                                    :total-visible="totalVisible"
                            ></v-pagination>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="title">
                        <span>
                            مجامع در حال برگذاری
                        </span>
                    </div>
                    <v-card class="mt-2 elevation-1">
                        <v-card-text>
                            <v-data-table
                                    :headers="headers"
                                    hide-default-footer
                                    :items="userAssemblies"
                            >
                                <template v-slot:item.company.avatar="{item}">

                                    <v-avatar large class="elevation-0">
                                        <v-img :src="'http://betabours.ir:8080'+item.companyBaseInfo.avatar"
                                               contain
                                        >
                                        </v-img>
                                    </v-avatar>

                                </template>

                                <template v-slot:item.startDateTime="{item}">
                                    {{item.startDateTime | toJalali}}
                                </template>
                                <template v-slot:item.endDateTime="{item}">
                                    {{item.endDateTime | toJalali}}
                                </template>


                                <template v-slot:item.actions="{item}">


                                    <v-btn small text @click="showRequestVoteDialog(item)">
                                        <v-icon color="primary" small>fas fa-vote-yea</v-icon>
                                    </v-btn>


                                    <!--<v-fade-transition mode="out-in">-->
                                    <!---->
                                    <!--<span v-else>-->
                                    <!--<v-btn small v-if="editingItem" @click="finishEditing(item)" text>-->
                                    <!--<v-icon small color="blue darken-1">fas fa-check</v-icon>-->
                                    <!--</v-btn>-->
                                    <!--<v-btn small v-if="editingMode" @click="cancelEditing" text>-->
                                    <!--<v-icon small color="red darken-1">fas fa-times</v-icon>-->
                                    <!--</v-btn>-->
                                    <!--</span>-->

                                    <!--</v-fade-transition>-->


                                </template>

                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-pagination
                                    v-model="page"
                                    :length="length"
                                    :total-visible="totalVisible"
                            ></v-pagination>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>


        </v-container>
    </v-card>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex';
    import moment from 'moment-jalali';
    // import AddCompany from '@/components/AddCompany'
    // import Assemblies from '@/components/Assemblies'
    import {encodeImageFileAsURL} from '../../utilities';
    import {EventBus} from "../../event-bus";


    export default {
        // components: {AddCompany, Assemblies},
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            }),
            sentRequestHeaders() {
                return [
                    {
                        text: this.$t('userAssembliesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'vote.companyBaseInfo.name'
                    },
                    {
                        text: this.$t('userAssembliesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'vote.companyBaseInfo.avatar'
                    },
                    {
                        text: this.$t('userAssemblesTableAssemblyNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'vote.title'
                    },
                    {
                        text: this.$t('userAssemblesTableStartDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'vote.startDateTime'
                    },
                    {
                        text: this.$t('userAssemblesTableEndDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'vote.endDateTime'
                    },
                    {
                        text: this.$t('userAssemblesTableStatusColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'state.title'
                    },
                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},
                ];
            },
            headers() {
                return [
                    {
                        text: this.$t('userAssembliesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'companyBaseInfo.name'
                    },
                    {
                        text: this.$t('userAssembliesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'company.avatar'
                    },
                    {
                        text: this.$t('userAssemblesTableAssemblyNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'title'
                    },
                    {
                        text: this.$t('userAssemblesTableStartDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'startDateTime'
                    },
                    {
                        text: this.$t('userAssemblesTableEndDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'endDateTime'
                    },
                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},


                ]
            }
        },
        data: () => ({
            reportDialog: false,
            reportItem: null,
            showRequestVote: false,
            requestToRemove: null,
            actionType: null, //1 - add 2- edit
            requestDialogTitle: 'ثبت درخواست برای آرای مجمع',
            sentRequests: [],
            devPlan: null,
            extension: null,
            requestVoteItem: null,
            requestVoteDialog: false,
            userAssemblies: [],
            showAddCompany: false,
            page: null,
            length: null,
            totalVisible: 7,
            editingItemShare: "",
            editingMode: false,
            editingItem: null,
            removeItemDialog: false,
            itemToRemove: null,
            moment: null

        }),
        created() {
            this.getAllRequests();

            this.getAssemblies();
            this.moment = moment;
        },
        methods: {
            showReportsDialog(item) {
                this.$store.dispatch('getVotingReport', item.vote.id).then((resp) => {
                    this.reportItem = resp.result;
                    this.reportDialog = true;
                });
            },
            removeVoteRequest() {

                this.$store.dispatch('removeVoteRequest', {id: this.requestToRemove.id}).then((resp) => {
                    this.requestToRemove = null;
                    console.log("--------------------resp--------------------");
                    console.log(resp);
                    console.log("--------------------resp--------------------");

                }).finally(() => {

                    this.removeItemDialog = false;
                });

            },
            showVoteRequestDeleteDialog(item) {
                this.requestToRemove = item;
                this.removeItemDialog = true;
            },
            showEditRequestVoteDialog(item) {
                this.requestDialogTitle = 'ویرایش درخواست برای آرای مجمع';
                this.actionType = 2;
                this.requestVoteItem = item;
                this.requestVoteDialog = true;
            },
            getAllRequests() {
                this.$store.dispatch('getRequestedVoteAssemblies', {
                    "pageNumber": 1,
                }).then((resp) => {
                    this.sentRequests = resp.result.data;
                    console.log(resp);
                });
            },
            onFileChange(file) {

                if (!file || file == undefined) return;
                this.extension = file.name.substr(file.name.lastIndexOf('.') + 1);

                encodeImageFileAsURL(file).then(resp => {
                    this.devPlan = resp;
                });
            },
            showRequestVoteDialog(item) {
                this.actionType = 1;
                this.requestDialogTitle = 'ثبت درخواست برای آرای مجمع';
                this.requestVoteItem = item;
                this.requestVoteDialog = true;

            },
            sendRequestVote() {
                if (this.devPlan == null) {
                    EventBus.$emit('notif', 'red', undefined, 'لطفا فایل طرح توسعه خود را انتخاب نمایید.');
                    return;
                }
                let data = {
                    payload: {
                        developmentPlan: this.devPlan.substr(this.devPlan.indexOf('base64,') + 7)
                    }
                };
                if (this.actionType == 1) {
                    data.assembly = this.requestVoteItem.id;
                } else if (this.actionType == 2) {
                    data.assembly = this.requestVoteItem.vote.id;
                }
                this.requestVote(data).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'درخواست با موفقیت ثبت شد.',
                    });
                    this.requestVoteDialog = false;
                });
            },
            removeItem() {

                this.removeUserCompany({id: this.itemToRemove.company.id}).then(() => {
                    this.itemToRemove = null;
                    this.getAssemblies();
                }).finally(() => {
                    this.removeItemDialog = false;
                })
            },
            getAssemblies() {
                let payload = {
                    "pageNumber": 1,
                    "pageSize": 6
                };
                this.getAllAssemblies(payload).then((resp) => {
                    this.userAssemblies = resp.result.data;
                    console.log("--------------------resp--------------------");
                    console.log(resp);
                    console.log("--------------------resp--------------------");
                });
            },
            cancelEditing() {
                this.editingMode = false;
                this.editingItem = null;
            },
            removeCompany(item) {
                this.itemToRemove = item;
                this.removeItemDialog = true;

            },
            finishEditing() {

                this.addCompanyShare({
                    id: this.editingItem.company.id,
                    shareAmount: this.editingItemShare
                }).then(() => {
                    this.editingItem.shareAmount = this.editingItemShare;
                }).finally(() => {
                    this.cancelEditing();
                });
            },
            editCompanyShare(item) {
                console.log("--------------------item--------------------");
                console.log(item);
                console.log("--------------------item--------------------");
                this.editingItem = item;
                this.editingItemShare = item.shareAmount;
                this.editingMode = true;

            },
            ...mapActions({
                getUserCompanies: 'getUserCompanies',
                getCompaniesList: 'searchCompanies',
                addCompanyShare: 'addCompanyShare',
                removeUserCompany: 'removeUserCompany',
                getAllAssemblies: 'getAssemblies',
                requestVote: 'requestVote'
            }),

            search() {
                clearTimeout(this.searchHandler);

                if (this.searchInput != null && this.searchInput.length >= 3) {
                    let data = {
                        "name": this.searchInput,
                        "pageNumber": 1,
                        "pageSize": 20
                    };
                    this.loadingList = true;
                    this.searchHandler = setTimeout(() => {

                        this.getCompaniesList(data).then((resp) => {
                            this.companiesList = [];
                            this.companiesList = resp.result.data;
                        }).finally(() => {
                            this.loadingList = false;
                        });
                    }, 1000);

                } else {
                    this.loadingList = false;
                }
            }
        },
        watch: {
            searchInput() {
                this.search();
            }
        },
        filters: {
            toJalali(input) {
                return moment(input).format('HH:MM - jYYYY/jMM/jDD');
            }
        }
    }

</script>

<style>

</style>