<template>
    <v-parallax
            dark
            :src="require('../assets/homeparallax2.jpg')"
            class="pa-0 ma-0"
            :height="imageViewHeight"
    >
        <v-container fluid>
            <v-row class="">
                <v-col cols="12" lg="4" md="6" sm="8" class="pa-3 pa-md-2">
                    <v-card class="pa-3">
                        <login-component :redirect="true"
                                         :title="$t('loginText') + ' | ' + $t('signUpText')"></login-component>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-parallax>
</template>
<script>

    import LoginComponent from '@/components/LoginComponent'

    export default {
        components: {
            LoginComponent
        },
        data() {
            return {

                showPassword: false,
                email: "",
                emailRules: [],
                password: "",
                errorMessages: '',
                name: null,
                address: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                formHasErrors: false,
                mobile: 0,
                firstName: "",
                lastName: "",
                imageViewHeight: window.innerHeight - 150

            }
        },
        computed: {
            form() {
                return {
                    // name: this.name,
                    // address: this.address,
                    // city: this.city,
                    // state: this.state,
                    // zip: this.zip,
                    // country: this.country,
                    email: this.email
                }
            },
        },
        watch: {
            name() {
                this.errorMessages = ''
            },
        },
        methods: {
            addressCheck() {
                this.errorMessages = this.address && !this.name
                    ? 'Hey! I\'m required'
                    : ''

                return true
            },
            resetForm() {
                this.errorMessages = []
                this.formHasErrors = false

                Object.keys(this.form).forEach(f => {
                    this.$refs[f].reset()
                })
            },
            submit() {
                console.log("SUBMITTED");

                // this.formHasErrors = false
                //
                // Object.keys(this.form).forEach(f => {
                //     if (!this.form[f]) this.formHasErrors = true
                //
                //     this.$refs[f].validate(true)
                // })
            },
        },
    }
</script>
<style>

</style>