<template>
    <v-fade-transition>
        <v-dialog v-model="requestVoteDialog" persistent max-width="600">

            <v-card v-if="assembly!=null">
                <v-card-title class="headline">
                    <span>{{requestDialogTitle}}</span>
                </v-card-title>
                <v-card-text>
                    به منظور ثبت درخواست برای اخذ رای، شما باید برنامه توسعه خود را برای مجمع مورد نظر ارسال نمایید.
                    <v-file-input show-size label="رزومه"
                                  dense
                                  accept="file/docs,xlsx,xsl"
                                  outlined
                                  @change="onFileChange"
                                  class="ma-3"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="requestVoteDialog = false">بستن</v-btn>
                    <v-btn color="primary darken-1" class="elevation-0" @click="sendRequestVote">ارسال</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-fade-transition>
</template>

<script>
    import {encodeImageFileAsURL} from '../../utilities';
    import {EventBus} from "../../event-bus";


    export default {
        props: ['assembly'],
        data() {
            return {
                requestVoteDialog: true,
                requestDialogTitle: 'ثبت درخواست برای آرای مجمع',
                extension: null,
                devPlan: null,
            }
        },
        methods: {
            onFileChange(file) {

                if (!file || file == undefined) return;
                this.extension = file.name.substr(file.name.lastIndexOf('.') + 1);

                encodeImageFileAsURL(file).then(resp => {
                    this.devPlan = resp;
                });
            },
            sendRequestVote() {
                if (this.devPlan == null) {
                    EventBus.$emit('notif', 'red', undefined, 'لطفا فایل طرح توسعه خود را انتخاب نمایید.');
                    return;
                }
                let data = {
                    payload: {
                        developmentPlan: this.devPlan.substr(this.devPlan.indexOf('base64,') + 7)
                    }
                };

                data.assembly = this.assembly.id;

                this.$store.dispatch('requestVote', data).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'درخواست با موفقیت ثبت شد.',
                    });
                    this.requestVoteDialog = false;
                });
            },
        },
        watch: {
            requestVoteDialog() {
                if (!this.requestVoteDialog) {
                    this.$emit('voteDialogClosed');
                }
            }
        }
    }
</script>

<style scoped>

</style>