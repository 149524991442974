/*eslint-disable*/
import {ajaxPost, ajaxGet, LS} from '../../utilities.js';
import {EventBus} from "../../event-bus";

export default {
    // namespaced: true,
    state: {},
    mutations: {},
    actions: {
        getUserAgencyRequests({rootState}) {
            return ajaxGet(`${rootState.baseURL}user/agency/requests`);
        },
        addAgency({rootState}, payload) {
            return ajaxPost(`${rootState.baseURL}user/agency/${payload}/add`);
        },
        cancelAgencyRequest({rootState}, payload) {
            return ajaxPost(`${rootState.baseURL}user/agency/${payload}/quit`);
        },
        companyVotingEvents({rootState}, companyId) {
            return ajaxPost(`${rootState.baseURL}user/agency/votes/${companyId}`);
        },
            companyAddVotingEvent({rootState}, payload) {
            return ajaxPost(`${rootState.baseURL}user/agency/votes/${payload.companyId}/add`, payload.assemblyToAdd);
        },
        companyDeleteVotingEvent({rootState}, payload) {
            return ajaxPost(`${rootState.baseURL}user/agency/votes/${payload.companyId}/delete/${payload.voteId}`);
        },
        companyEditVotingEvent({rootState}, payload) {
            return ajaxPost(`${rootState.baseURL}user/agency/votes/${payload.companyId}/edit/${payload.voteId}`, payload.assemblyToEdit);
        },


    },
    getters: {}
};
