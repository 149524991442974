<template>
    <v-container style="min-height: 100vh">
        <company-info :company="company" />
        <v-container class="mt-12">
            <h2>مجامع</h2>
            <v-divider class="mb-8 mt-5" />
            <v-row justify="center"  v-if="company.votes.length != 0">
                <v-col md="11" v-for="(assembly,index) in company.votes" :key="index">
                    <company-meeting
                            :item="assembly" :company="company"
                            @selectAssembly="selectAssembly(assembly)"
                            @requestVoteAssembly="requestVoteAssembly(assembly)" />
                </v-col>
            </v-row>
            <v-row v-else justify="center">
                <h4 class="font-weight-light">
                    در حال حاضر مجمعی وجود ندارد
                </h4>
            </v-row>
        </v-container>

        <VoteRequest v-if="requestingVote" @voteDialogClosed="finishVoteRequest"
                     :assembly="voteRequestAssembly"></VoteRequest>
        <UserVote v-if="selectedAssembly!=null" :assembly="selectedAssembly"></UserVote>


        <VoteRequest v-if="requestingVote" @voteDialogClosed="finishVoteRequest"
                     :assembly="voteRequestAssembly"></VoteRequest>



        <!--        <v-row class="pa-5">-->
        <!--            <v-col cols="12" class="text-center">-->
        <!--                <v-avatar size="136" class="elevation-2">-->
        <!--                    <v-img-->
        <!--                            :src="$store.getters.getStaticBaseURL+company.avatar"-->
        <!--                            contain-->
        <!--                            :alt="company.name"-->
        <!--                    >-->
        <!--                    </v-img>-->
        <!--                </v-avatar>-->

        <!--            </v-col>-->
        <!--            <v-col cols="12" class="text-center pt-1 title font-weight-bold">-->
        <!--                <span>-->
        <!--                    {{company.name}}-->
        <!--                </span>-->
        <!--            </v-col>-->

        <!--            <v-col cols="2" class="text-center ">-->
        <!--                <span>نام:</span>-->
        <!--            </v-col>-->
        <!--            <v-col cols="4" class="text-right ">-->
        <!--                <span>{{company.name}}</span>-->

        <!--            </v-col>-->

        <!--            <v-col cols="2" class="text-center ">-->
        <!--                تلفن:-->
        <!--            </v-col>-->
        <!--            <v-col cols="4" class="text-right ">-->
        <!--                {{company.phoneNumber}}-->
        <!--            </v-col>-->

        <!--            <v-col cols="2" class="text-center">-->
        <!--                <span>آدرس : </span>-->
        <!--            </v-col>-->
        <!--            <v-col cols="10">-->
        <!--                {{company.address}}-->
        <!--            </v-col>-->
        <!--            <v-col cols="2" class="text-center">-->
        <!--                <span>توضیحات:</span>-->
        <!--            </v-col>-->
        <!--            <v-col cols="10">-->
        <!--                <span v-html="company.description">-->
        <!--                </span>-->

        <!--            </v-col>-->

        <!--        </v-row>-->
        <!--        <v-stepper v-model="step" class="elevation-0">-->
        <!--            <v-stepper-items>-->
        <!--                <v-stepper-content step="1">-->

        <!--                    <v-row justify="center" class="pa-1">-->

        <!--                        <v-col cols="12">-->

        <!--                            <v-card-title>-->
        <!--                                مجامع-->
        <!--                            </v-card-title>-->
        <!--                            <v-divider></v-divider>-->
        <!--                        </v-col>-->


        <!--                        &lt;!&ndash;<v-card-text>&ndash;&gt;-->
        <!--                        &lt;!&ndash;&ndash;&gt;-->
        <!--                        &lt;!&ndash;</v-card-text>&ndash;&gt;-->

        <!--                        <v-col v-for="(assembly,index) in company.votes" :key="index" cols="6" sm="4" class="my-4">-->
        <!--                            <v-card class="elevation-1">-->
        <!--                                <v-img :src="'http://betabours.ir:8080'+company.avatar"-->
        <!--                                       max-height="150"-->
        <!--                                >-->
        <!--                                </v-img>-->
        <!--                                <v-card-title>-->
        <!--                                    {{assembly.title | excerpt}}-->
        <!--                                </v-card-title>-->
        <!--                            </v-card>-->
        <!--                            <v-card-actions>-->
        <!--                                <v-btn class="primary" text @click="selectAssembly(assembly)">ارائه حق رای-->
        <!--                                </v-btn>-->
        <!--                                <v-spacer></v-spacer>-->
        <!--                                <v-btn class="primary" text-->
        <!--                                       @click="requestVoteAssembly(assembly)">اخذ وکالت-->
        <!--                                </v-btn>-->

        <!--                            </v-card-actions>-->

        <!--                        </v-col>-->
        <!--                    </v-row>-->
        <!--                </v-stepper-content>-->
        <!--                <v-stepper-content step="2">-->
        <!--                    <v-btn @click="step=1" class="ma-2 primary&#45;&#45;text" text>-->
        <!--                        <v-icon small>fas fa-long-arrow-alt-right</v-icon>-->
        <!--                        <span>بازگشت به مجامع</span>-->
        <!--                    </v-btn>-->

        <!--                    <UserVote v-if="selectedAssembly!=null" :assembly="selectedAssembly"></UserVote>-->

        <!--                </v-stepper-content>-->
        <!--            </v-stepper-items>-->
        <!--        </v-stepper>-->
    </v-container>
</template>

<script>
    import CompanyInfo from "../components/Company/CompanyInfo";
    import CompanyMeeting from "../components/Company/CompanyMeeting";
    import VoteRequest from "../components/Trader/VoteRequest";
    import UserVote from "../components/UserVote";
    import {EventBus} from "../event-bus";
    import {mapGetters} from "vuex";

    export default {
        components: {
            CompanyInfo,
            VoteRequest,
            CompanyMeeting,
            UserVote
        },
        data() {
            return {
                canRequestVote: false,
                voteRequestAssembly: null,
                requestingVote: false,
                selectedAssembly: null,
                step: 1,
                company: {
                    id: null,
                    name: null,
                    slug: null,
                    avatar: null,
                    phoneNumber: null,
                    address: null,
                    description: null,
                    votes: [],
                },
                tab: null,
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            }
        },
        methods: {
            finishVoteRequest() {
                this.voteRequestAssembly = null;
                this.requestingVote = null;
            },

            selectAssembly(assembly) {
                if (!this.loggedIn) {
                    let message = 'لطفا ابتدا وارد شوید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }
                if (!this.$store.getters.isSigned) {

                    let message = 'لطفا ابتدا پروفایل خود را تکمیل نمایید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }

                this.selectedAssembly = assembly;
                this.step++;
            },
            requestVoteAssembly(assembly) {
                if (!this.loggedIn) {
                    let message = 'لطفا ابتدا وارد شوید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }

                if (!this.$store.getters.userHasRole('trader')) {
                    let message = 'لطفا ابتدا به عنوان یک متخصص ثبت نام نمایید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }

                this.voteRequestAssembly = assembly;
                this.requestingVote = true;
            }
        },

        mounted() {
            console.log(this.$route.params.code);
            this.canRequestVote = this.$store.getters.userHasRole('trader');
            this.$store.dispatch('getCompany',this.$route.params.code).then((resp) => {
                this.company = resp.result;
                console.log(resp);
            })
        },
        filters: {
            excerpt: (val) => {
                if (val.length > 36) {
                    return val.split(" ", 5).join(' ') + '...';
                }
                return val;

            }
        },
        computed: {
            ...mapGetters({
                loggedIn: 'userIsLoggedIn'
            })
        }

    }
</script>

<style>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }
</style>

<!--<template>-->

<!--    <v-container>-->

<!--        <VoteRequest v-if="requestingVote" @voteDialogClosed="finishVoteRequest"-->
<!--                     :assembly="voteRequestAssembly"></VoteRequest>-->

<!--        <v-row class="pa-5">-->
<!--            <v-col cols="12" class="text-center">-->
<!--                <v-avatar size="136" class="elevation-2">-->
<!--                    <v-img-->
<!--                            :src="$store.getters.getStaticBaseURL+company.avatar"-->
<!--                            contain-->
<!--                            :alt="company.name"-->
<!--                    >-->
<!--                    </v-img>-->
<!--                </v-avatar>-->

<!--            </v-col>-->
<!--            <v-col cols="12" class="text-center pt-1 title font-weight-bold">-->
<!--                <span>-->
<!--                    {{company.name}}-->
<!--                </span>-->
<!--            </v-col>-->

<!--            <v-col cols="2" class="text-center ">-->
<!--                <span>نام:</span>-->
<!--            </v-col>-->
<!--            <v-col cols="4" class="text-right ">-->
<!--                <span>{{company.name}}</span>-->

<!--            </v-col>-->

<!--            <v-col cols="2" class="text-center ">-->
<!--                تلفن:-->
<!--            </v-col>-->
<!--            <v-col cols="4" class="text-right ">-->
<!--                {{company.phoneNumber}}-->
<!--            </v-col>-->

<!--            <v-col cols="2" class="text-center">-->
<!--                <span>آدرس : </span>-->
<!--            </v-col>-->
<!--            <v-col cols="10">-->
<!--                {{company.address}}-->
<!--            </v-col>-->
<!--            <v-col cols="2" class="text-center">-->
<!--                <span>توضیحات:</span>-->
<!--            </v-col>-->
<!--            <v-col cols="10">-->
<!--                <span v-html="company.description">-->
<!--                </span>-->

<!--            </v-col>-->

<!--        </v-row>-->
<!--        <v-stepper v-model="step" class="elevation-0">-->
<!--            <v-stepper-items>-->
<!--                <v-stepper-content step="1">-->

<!--                    <v-row justify="center" class="pa-1">-->

<!--                        <v-col cols="12">-->

<!--                            <v-card-title>-->
<!--                                مجامع-->
<!--                            </v-card-title>-->
<!--                            <v-divider></v-divider>-->
<!--                        </v-col>-->


<!--                        &lt;!&ndash;<v-card-text>&ndash;&gt;-->
<!--                        &lt;!&ndash;&ndash;&gt;-->
<!--                        &lt;!&ndash;</v-card-text>&ndash;&gt;-->

<!--                        <v-col v-for="(assembly,index) in company.votes" :key="index" cols="6" sm="4" class="my-4">-->
<!--                            <v-card class="elevation-1">-->
<!--                                <v-img :src="'http://betabours.ir:8080'+company.avatar"-->
<!--                                       max-height="150"-->
<!--                                >-->
<!--                                </v-img>-->
<!--                                <v-card-title>-->
<!--                                    {{assembly.title | excerpt}}-->
<!--                                </v-card-title>-->
<!--                            </v-card>-->
<!--                            <v-card-actions>-->
<!--                                <v-btn class="primary" text @click="selectAssembly(assembly)">ارائه حق رای-->
<!--                                </v-btn>-->
<!--                                <v-spacer></v-spacer>-->
<!--                                <v-btn class="primary" text-->
<!--                                       @click="requestVoteAssembly(assembly)">اخذ وکالت-->
<!--                                </v-btn>-->

<!--                            </v-card-actions>-->

<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-stepper-content>-->
<!--                <v-stepper-content step="2">-->
<!--                    <v-btn @click="step=1" class="ma-2 primary&#45;&#45;text" text>-->
<!--                        <v-icon small>fas fa-long-arrow-alt-right</v-icon>-->
<!--                        <span>بازگشت به مجامع</span>-->
<!--                    </v-btn>-->

<!--                    <UserVote v-if="selectedAssembly!=null" :assembly="selectedAssembly"></UserVote>-->

<!--                </v-stepper-content>-->
<!--            </v-stepper-items>-->
<!--        </v-stepper>-->
<!--    </v-container>-->
<!--</template>-->

<!--<script>-->
<!--    import UserVote from '@/components/UserVote.vue';-->
<!--    import VoteRequest from '@/components/Trader/VoteRequest.vue';-->
<!--    import {EventBus} from "../event-bus";-->
<!--    import {mapGetters} from "vuex";-->

<!--    export default {-->
<!--        components: {-->
<!--            UserVote,-->
<!--            VoteRequest-->
<!--        },-->
<!--        data() {-->
<!--            return {-->
<!--                canRequestVote: false,-->
<!--                voteRequestAssembly: null,-->
<!--                requestingVote: false,-->
<!--                selectedAssembly: null,-->
<!--                step: 1,-->
<!--                company: {-->
<!--                    id: null,-->
<!--                    name: null,-->
<!--                    slug: null,-->
<!--                    avatar: null,-->
<!--                    phoneNumber: null,-->
<!--                    address: null,-->
<!--                    description: null,-->
<!--                    votes: [],-->
<!--                }-->
<!--            }-->
<!--        },-->
<!--        methods: {-->
<!--            finishVoteRequest() {-->
<!--                this.voteRequestAssembly = null;-->
<!--                this.requestingVote = null;-->
<!--            },-->

<!--            selectAssembly(assembly) {-->
<!--                if (!this.loggedIn) {-->
<!--                    let message = 'لطفا ابتدا وارد شوید.';-->
<!--                    EventBus.$emit('notif', 'red', undefined, message);-->
<!--                    return;-->
<!--                }-->
<!--                if (!this.$store.getters.isSigned) {-->

<!--                    let message = 'لطفا ابتدا پروفایل خود را تکمیل نمایید.';-->
<!--                    EventBus.$emit('notif', 'red', undefined, message);-->
<!--                    return;-->
<!--                }-->

<!--                this.selectedAssembly = assembly;-->
<!--                this.step++;-->
<!--            },-->
<!--            requestVoteAssembly(assembly) {-->
<!--                if (!this.loggedIn) {-->
<!--                    let message = 'لطفا ابتدا وارد شوید.';-->
<!--                    EventBus.$emit('notif', 'red', undefined, message);-->
<!--                    return;-->
<!--                }-->

<!--                if (!this.$store.getters.userHasRole('trader')) {-->
<!--                    let message = 'لطفا ابتدا به عنوان یک متخصص ثبت نام نمایید.';-->
<!--                    EventBus.$emit('notif', 'red', undefined, message);-->
<!--                    return;-->
<!--                }-->

<!--                this.voteRequestAssembly = assembly;-->
<!--                this.requestingVote = true;-->
<!--            }-->
<!--        },-->

<!--        mounted() {-->
<!--            console.log(this.$route.params.code);-->
<!--            this.canRequestVote = this.$store.getters.userHasRole('trader');-->
<!--            this.$store.dispatch('getCompany',this.$route.params.code).then((resp) => {-->
<!--                this.company = resp.result;-->
<!--                console.log(resp);-->
<!--            })-->
<!--        },-->
<!--        filters: {-->
<!--            excerpt: (val) => {-->
<!--                if (val.length > 36) {-->
<!--                    return val.split(" ", 5).join(' ') + '...';-->
<!--                }-->
<!--                return val;-->

<!--            }-->
<!--        },-->
<!--        computed: {-->
<!--            ...mapGetters({-->
<!--                loggedIn: 'userIsLoggedIn'-->
<!--            })-->
<!--        }-->

<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->


