<template>
    <v-container>
        <v-card outlined class="elevation-0 transparent text-center">
            <v-card-title>
                <h3 class="font-weight-regular text-center">{{$t('blogPostsTitle')}}</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-container>

                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="post in posts" :key="post.id">

                            <a :href="post.link" class="blog-link">
                                <v-card
                                        class="mx-auto single-blog-post"
                                        max-width="344"
                                        min-height="400"

                                >
                                    <template v-if="post._embedded['wp:featuredmedia'] != undefined">
                                        <v-img
                                                :src="post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url"
                                                height="200px"
                                        ></v-img>
                                    </template>
                                    <template v-else>
                                        <v-img
                                                src="http://193.176.240.111:8080/images/uploads/baseInfo/img_1581277339856_3106983274055610050.jpg"
                                                height="200px"
                                        ></v-img>
                                    </template>

                                    <v-card-title>
                                        {{post.title.rendered}}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <p v-html="post.excerpt.rendered"></p>
                                    </v-card-text>

                                    <!--<v-card-subtitle>-->
                                    <!--1,000 miles of wonder-->
                                    <!--</v-card-subtitle>-->

                                    <!--<v-card-actions>-->
                                    <!--<v-btn text>Share</v-btn>-->

                                    <!--<v-btn-->
                                    <!--color="purple"-->
                                    <!--text-->
                                    <!--&gt;-->
                                    <!--Explore-->
                                    <!--</v-btn>-->

                                    <!--<v-spacer></v-spacer>-->

                                    <!--<v-btn-->
                                    <!--icon-->
                                    <!--@click="show = !show"-->
                                    <!--&gt;-->
                                    <!--<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
                                    <!--</v-btn>-->
                                    <!--</v-card-actions>-->

                                    <!--<v-expand-transition>-->
                                    <!--<div v-show="show">-->
                                    <!--<v-divider></v-divider>-->

                                    <!--<v-card-text>-->
                                    <!--&lt;!&ndash;<p v-html="post.excerpt.rendered"></p>&ndash;&gt;-->
                                    <!--</v-card-text>-->
                                    <!--</div>-->
                                    <!--</v-expand-transition>-->
                                </v-card>
                            </a>


                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>
    export default {
        data() {
            return {
                posts: [],
                show: false
            }
        },
        mounted() {
            this.$store.dispatch('getBlogPosts', '&per_page=3').then((resp) => {
                this.posts = resp;
            });
        }

    }
</script>

<style scoped>
    .blog-link {
        text-decoration: none;
    }

    .single-blog-post {
        overflow: hidden;
    }
</style>