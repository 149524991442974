<template>
    <v-container fluid class="pa-0">
        <login-form></login-form>
    </v-container>
</template>

<script>

    // @ is an alias to /src
    import LoginForm from '@/components/LoginForm';


    export default {
        name: 'home',
        components: {
            LoginForm
        },
        mounted() {
            // window.scrollTo(0, 0);
        }
    }
</script>
