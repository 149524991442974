<template>
    <v-card
            class="my-12 py-12"
            color=""
            flat
    >
        <v-window v-model="onboarding" :next-icon="'mdi-chevron-right'" show-arrows-on-hover
                  :prev-icon="'mdi-chevron-left'">
            <v-window-item
                    v-for="(testimony,n) in testimonies"
                    :key="`card-${n}`"

            >
                <v-card
                        class="elevation-0"

                >
                    <v-row
                            class="fill-height secondary--text"
                            align="center"
                            justify="center"

                    >
                        <v-col class="text-center" cols="12">
                            <v-avatar size="100" class="elevation-1">
                                <img
                                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                                        alt="John"
                                >
                            </v-avatar>
                            <div class="mt-2 subtitle-2">
                                <span class="primary--text">{{testimony.name}}</span>
                            </div>

                        </v-col>
                        <v-col class="pa-1 text-center" cols="12" lg="8">
                            <div class="">
                                <span class="display-1 primary--text font-weight-bold">&ldquo;</span>
                                <!--<span class="title primary white&#45;&#45;text px-2 py-1 ma-3">-->
                                <v-chip dark label class="primary ma-3 title elevation-0">
                                    {{testimony.title}}
                                </v-chip>
                                <!--</span>-->
                                <span class="display-1 primary--text font-weight-bold">&rdquo;</span>
                            </div>

                            <div class="mt-3 ">{{testimony.comment}}</div>

                        </v-col>

                    </v-row>
                </v-card>
            </v-window-item>
        </v-window>

        <v-card-actions class="justify-center ">

            <v-item-group
                    v-model="onboarding"
                    class="text-center "
                    mandatory
            >
                <v-item
                        v-for="(item,index) in testimonies"
                        :key="`btn-${index}`"
                        v-slot:default="{ active, toggle }"
                        class="accent--text"
                >
                    <v-btn
                            :input-value="active"
                            icon
                            @click="toggle"
                    >
                        <v-icon>mdi-record</v-icon>
                    </v-btn>
                </v-item>
            </v-item-group>

        </v-card-actions>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                length: 3,
                onboarding: 0,
                testimonies: [
                    {
                        avatar: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                        position: 'User',
                        name: 'مهربان مهربانی',
                        company: '',
                        title: "خیلی خوب شده",
                        comment: 'خب این بهتر شد، قبلا برای دادن حق رای یا گرفتن وکالت یه دردسر اساسی داشتیم، خب اینجوری که الان هست هم راحت تره هم شفاف تر. ممنونم.'

                    }
                ],
                items: [
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                    },
                ],
            }
        },
        methods: {
            next() {
                this.onboarding = this.onboarding + 1 === this.length
                    ? 0
                    : this.onboarding + 1
            },
            prev() {
                this.onboarding = this.onboarding - 1 < 0
                    ? this.length - 1
                    : this.onboarding - 1
            },
        },
    }
</script>

<style scoped>

</style>
