<template>
    <v-dialog v-model="assemblyDialog" max-width="800" @click:outside="$emit('closeDialog')">
        <v-card>
            <v-card-title>
                {{assemblyData.title ? assemblyData.title : 'ایجاد مجمع جدید'}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row dense class="mt-5">
                    <v-col cols="12" sm="6">
                        <v-text-field
                                dense outlined
                                label="عنوان"
                                v-model="assemblyData.title">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <div class="d-flex justify-space-around">
                            <div class="mt-2">وضعیت:</div>
                            <v-radio-group v-model="state" class="my-0">
                                <v-radio
                                        v-for="n in 2"
                                        :key="n"
                                        class="mb-0 px-5"
                                        :label="n==1 ? 'فعال' : 'غیرفعال'"
                                        :value="n"
                                ></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
<!--                    <v-col cols="12" sm="12" md="12" class="py-0">-->
<!--                        <v-col md="6" class="pa-0">-->
<!--                            <v-dialog-->
<!--                                    ref="startTimeClock"-->
<!--                                    v-model="startTimeModal"-->
<!--                                    :return-value.sync="assemblyData.startTime"-->
<!--                                    width="290px"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on }">-->
<!--                                    <v-text-field-->
<!--                                            v-model="assemblyData.startTime"-->
<!--                                            label="ساعت شروع"-->
<!--                                            prepend-inner-icon="access_time"-->
<!--                                            dense outlined-->
<!--                                            v-on="on"-->
<!--                                    ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-time-picker-->
<!--                                        v-if="startTimeModal"-->
<!--                                        use-seconds-->
<!--                                        v-model="assemblyData.startTime"-->
<!--                                        full-width-->
<!--                                >-->
<!--                                    <v-spacer></v-spacer>-->
<!--                                    <v-btn text color="primary" @click="startTimeModal = false">Cancel</v-btn>-->
<!--                                    <v-btn text color="primary" @click="$refs.startTimeClock.save(assemblyData.startTime)">OK</v-btn>-->
<!--                                </v-time-picker>-->
<!--                            </v-dialog>-->
<!--                        </v-col>-->
<!--                        <v-spacer />-->
<!--                    </v-col>-->
                    <v-col cols="12" sm="6">
                        <v-text-field type="number" dense outlined v-model="assemblyData.realUserPersonLimit"
                                      label="محدودیت اشخاص حقیقی">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field type="number" dense outlined v-model="assemblyData.legalUserPersonLimit"
                                      label="محدودیت اشخاص حقوقی">
                        </v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-dialog
                                ref="startTimeClock"
                                v-model="startTimeModal"
                                :return-value.sync="assemblyData.startTime"
                                width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="assemblyData.startTime"
                                        label="ساعت شروع"
                                        prepend-inner-icon="access_time"
                                        dense outlined
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                    v-if="startTimeModal"
                                    use-seconds
                                    v-model="assemblyData.startTime"
                                    full-width
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="startTimeModal = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.startTimeClock.save(assemblyData.startTime)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>
                    <v-col md="6">
                        <v-dialog
                                ref="endTimeClock"
                                v-model="endTimeModal"
                                :return-value.sync="assemblyData.endTime"
                                width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="assemblyData.endTime"
                                        label="ساعت پایان"
                                        prepend-inner-icon="access_time"
                                        dense outlined
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                    v-if="endTimeModal"
                                    use-seconds
                                    v-model="assemblyData.endTime"
                                    full-width
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="endTimeModal = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.endTimeClock.save(assemblyData.endTime)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="mb-5">
                        <div class="mb-3">تاریخ تقریبی جلسه:</div>
                        <DatePicker
                                placeholder="انتخاب تاریخ"
                                inputFormat="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                displayFormat="jYYYY-jMM-jDD"
                                color="#4caf50"
                                v-model="assemblyData.approximateDate">
                        </DatePicker>
                    </v-col>
                    <v-col cols="12" sm="4" class="mb-5">
                        <div class="mb-3">تاریخ شروع:</div>
                        <DatePicker
                                placeholder="انتخاب تاریخ"
                                inputFormat="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                displayFormat="jYYYY-jMM-jDD"
                                color="#4caf50"
                                v-model="assemblyData.startDate">
                        </DatePicker>
                    </v-col>
                    <v-col cols="12" sm="4" class="mb-5">
                        <div class="mb-3">تاریخ پایان:</div>
                        <DatePicker
                                placeholder="انتخاب تاریخ"
                                inputFormat="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                displayFormat="jYYYY-jMM-jDD"
                                color="#4caf50"
                                v-model="assemblyData.endDate">
                        </DatePicker>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mb-5">
                        <v-divider class="mb-5"/>
                        <v-checkbox
                                class="mt-0"
                                v-model="assemblyData.hasOnlineMeeting"
                                label="این مجمع جلسه آنلاین دارد"
                                color="primary"
                                :value="assemblyData.hasOnlineMeeting"
                                hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="assemblyData.hasOnlineMeeting">
                        <v-dialog
                                ref="liveMeetingTimeClock"
                                v-model="liveMeetingTimeModal"
                                :return-value.sync="assemblyData.liveMeetingTime"
                                width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="assemblyData.liveMeetingTime"
                                        label="ساعت شروع"
                                        prepend-inner-icon="access_time"
                                        dense outlined
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                    v-if="liveMeetingTimeModal"
                                    use-seconds
                                    v-model="assemblyData.liveMeetingTime"
                                    full-width
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="liveMeetingTimeModal = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.liveMeetingTimeClock.save(assemblyData.liveMeetingTime)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="assemblyData.hasOnlineMeeting">
                        <DatePicker
                                color="#4caf50"
                                placeholder="انتخاب تاریخ"
                                inputFormat="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                displayFormat="jYYYY-jMM-jDD"
                                v-model="assemblyData.liveMeetingDate">
                        </DatePicker>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="red"
                        text
                        class="elevation-0"
                        @click="$emit('closeDialog')"
                >
                    بستن
                </v-btn>

                <v-btn
                        color="primary"
                        class="elevation-0"
                        @click="$emit('onAssembly')"
                >
                    ثبت
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import DatePicker from 'vue-persian-datetime-picker';
    export default {
        props: ['assemblyData', 'assemblyDialog', 'type'],
        components: {
            DatePicker
        },
        data: () => ({
            liveMeetingTimeModal: false,
            startTimeModal: false,
            endTimeModal: false
        }),
        computed: {
            state() {
               return this.type === 'edit' ? this.assemblyData.state.value : this.assemblyData.state
            }
        }
    }
</script>
