import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        dark: false,
        themes: {
            light: {
                // primary: '#4caf50',
                primary: '#bd051b',
                // secondary: '#03a9f4',
                secondary: '#074974',
                // accent: '#607d8b',
                accent: '#B3BBBD',
                error: '#f44336',
                warning: '#ffc107',
                info: '#2196f3',
                success: '#8bc34a'
            },
            dark: {
                primary: '#555',
                secondary: '#009688',
                accent: '#4caf50',
                error: '#f44336',
                warning: '#ffc107',
                info: '#2196f3',
                success: '#8bc34a'
            }
        }
    }
});
