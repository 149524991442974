<template>
    <div>
        <v-container fluid class="text-center mt-10">
            <v-row align="center" justify="center">
                <v-col cols="12" lg="6">

                    <h1>
                        {{$t('siteTitle')}}
                    </h1>
                    <p>
                        {{$t('appPresentationDesc')}}
                    </p>

                    <v-row class="text-center justify-center">

                        <v-col cols="12">
                            <div style="display:inline-block;">
                                <div>{{$t('availableStore')}}</div>

                                <v-chip
                                        class="ma-2 pa-3"
                                        @click="chipClicked"
                                >
                                    Google Play
                                    <v-icon class="pa-2 green--text text--lighten-1">fab fa-android</v-icon>
                                </v-chip>

                            </div>
                            <div style="display:inline-block;">
                                <div>{{$t('availableStore')}}</div>
                                <v-chip
                                        class="ma-2 pa-3"
                                        @click="chipClicked"
                                >
                                    Play Store
                                    <v-icon class="pa-2">fab fa-apple</v-icon>
                                </v-chip>
                            </div>
                        </v-col>

                        <v-col col="6">

                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" lg="6" class="justify-center text-center ">
                    <v-img :src="imgsrc" max-width="400px" class="ma-auto"></v-img>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                imgsrc: require('../assets/Picture3.png')
            }
        },
        methods: {
            chipClicked() {
                console.log('chipClicked');
            }
        },
    }
</script>
<style>

</style>