/*eslint-disable*/
import {LS, ajaxPost, ajaxGet} from '../../utilities.js';
import {EventBus} from "../../event-bus";

export default {
    // namespaced: true,
    state: {
        // baseURL: 'http://193.176.240.111/blog/wp-json/wp/v2/',
    },
    mutations: {},
    actions: {
        getBlogPosts({commit, rootState}, data = '') {
            return ajaxGet(rootState.blogBaseURL + 'posts?_embed' + data);
        },
    },
    getters: {}

};