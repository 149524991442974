<template>
    <div>
        <v-card class="mb-8">
            <v-card-actions class="pa-5 grey lighten-4">
                <v-icon small class="pl-2">fas fa-ellipsis-v</v-icon>
                <v-icon small>far fa-clock</v-icon>
                <v-spacer></v-spacer>
                <v-icon small>fas fa-thumbtack</v-icon>
            </v-card-actions>
            <v-list-item three-line>
                <v-list-item-avatar
                        tile
                        size="50"
                        style="border-radius: 4px !important;"
                        color="grey"
                >
                    <v-img :src="user.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold blue--text">{{ user.username }}@</v-list-item-title>
                    <v-list-item-subtitle class="mt-n6">{{ user.name }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-card-text style="line-height: 2.5rem">
                ماشین حساب محاسبه نرخ بازده تا سررسید اوراق بدهی (YTM) به منظور تسهیل تصمیمات سرمایه گذاران و قابلیت مقایسه اوراق با یکدیگر طراحی شده است و هیچگونه توصیه یا پیشنهاد سرمایه گذاری ارائه نمی کند. همچنین هرگونه تفسیر از اطلاعات نمایش داده شده در این بخش که امکان دارد منجر به تصمیمات سرمایه گذاری شود، بر عهده سرمایه گذار بوده و مسئولیتی در خصوص ضررهای احتمالی متوجه بورس اوراق بهادار تهران نمی باشد.
                <br>
                برای الگوریتم های محاسباتی پیاده شده در ماشین حساب مذکور، از منابع دقیق و قابل اتکایی است
                <br>
                ماشین حساب محاسبه نرخ بازده تا سررسید اوراق بدهی (YTM) به منظور تسهیل تصمیمات سرمایه گذاران و قابلیت مقایسه اوراق با یکدیگر طراحی شده است و هیچگونه توصیه یا پیشنهاد سرمایه گذاری ارائه نمی کند. همچنین هرگونه تفسیر از اطلاعات نمایش داده شده در این بخش که امکان دارد منجر به تصمیمات سرمایه گذاری شود، بر عهده سرمایه گذا
            </v-card-text>
            <v-card-actions class="pa-5 justify-end">
                <v-icon small>far fa-heart</v-icon>
                <v-icon small class="px-8">far fa-comment</v-icon>
                <v-icon small>fas fa-share</v-icon>
            </v-card-actions>
            <v-divider></v-divider>
            <v-list class="grey lighten-5 py-0">
                <v-card-actions class="grey lighten-5 py-4">
                    <v-btn text small class="pl-2">مشاهده نظرات بیشتر</v-btn>
                    <v-spacer></v-spacer>
                    <v-card-subtitle class="py-0">2 ز 59</v-card-subtitle>
                </v-card-actions>
                <v-divider></v-divider>
                <v-list-item three-line>
                    <v-list-item-avatar
                            tile
                            size="40"
                            style="border-radius: 4px !important;"
                            color="grey"
                    >
                        <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold blue--text">Shayan</v-list-item-title>
                        <v-list-item-subtitle style="flex: 0 1 80%" class="mt-2">
                            <span style="line-height: 25px">خوب این بهتر شد؛ قبلا برای دادن حق رای یا گرفتن وکالت یه درد سر اساسی داشتیم. خوب اینجوری که الان هست هم راحت تره هم شفاف تر</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text>
                        <v-icon small class="pl-2">fas fa-ellipsis-v</v-icon>
                    </v-list-item-action-text>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-avatar
                            tile
                            size="40"
                            style="border-radius: 4px !important;"
                            color="grey"
                            class="mb-5"
                    >
                        <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-text-field class="mt-4" solo></v-text-field>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>
    export default {
       data() {
           return {
               user1: {
                   avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
                   username: "Shayan",
                   name: "علی شایان"
               },
               user2: {
                   avatar: require('@/assets/trader.jpg'),
                   username: "Goodgoing",
                   name: "حسن معجونی"
               }
           }
       },
        computed: {
            user() {
                let person
                if(this.$route.path.includes('علی')){
                    person = this.user1
                }else {
                    person = this.user2
                }
                return person
            }
        }
    }
</script>
