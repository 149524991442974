<template>
    <v-container class="charts">
        <v-row>
            <v-col md="12">
                <v-card width="100%" class="mt-3 pa-3" outlined>
                    <v-card-title class="justify-space-between">
                        <v-card-subtitle>عملکرد</v-card-subtitle>
                        <v-btn text fab>
                            <v-icon color="blue" large>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                    </v-card-title>
                    <vue-highcharts :highcharts="Highcharts" :options="coloredData" ref="drilldownChart"></vue-highcharts>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8" sm="12">
                <v-card class="fill-height" width="100%" outlined>
                    <v-card-title class="justify-space-between">
                        <div class="d-flex align-center">
                            <div
                                    style="border: 2px solid orange; border-radius: 4px"
                                    class="px-3 orange--text font-weight-medium">5</div>
                            <v-card-subtitle><small>متوسط ریسک<br>در 7 روزاخیر</small></v-card-subtitle>
                        </div>
                        <v-btn text fab>
                            <v-icon color="blue" large>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                    </v-card-title>
                        <vue-highcharts class="pa-3" :highcharts="Highcharts" :options="coloredData" ref="drilldownChart"></vue-highcharts>
                    <div class="grey lighten-5 mb-2">
                        <v-divider />
                        <v-card-subtitle>محدوده AUM</v-card-subtitle>
                        <v-row>
                            <v-col cols="12" md="4" sm="12" style="border-left: 1px solid #ccc">
                                <v-card-subtitle>کپی کنندگان<br><h2 class="mt-1 font-weight-medium">12581</h2></v-card-subtitle>
                            </v-col>
<!--                            <v-divider vertical></v-divider>-->
                            <v-col cols="12" md="4" sm="12" style="border-left: 1px solid #ccc">
                                <v-card-subtitle>کپی کنندگان<br><h2 class="mt-1 font-weight-medium">12581</h2></v-card-subtitle>
                            </v-col>
<!--                            <v-divider vertical></v-divider>-->
                            <v-col cols="12" md="4" sm="12">
                                <v-card-subtitle>کپی کنندگان<br><h2 class="mt-1 font-weight-medium">12581</h2></v-card-subtitle>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <v-card class="fill-height" width="100%" outlined>
                    <v-card-title class="justify-space-between">
                        <v-card-subtitle>کپی کنندگان<br><h2 class="mt-1 font-weight-medium">12581</h2></v-card-subtitle>
                        <v-btn text fab>
                            <v-icon color="blue" large>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                    </v-card-title>
                    <vue-highcharts class="pa-3" :options="areaOptions" ref="areaCharts"></vue-highcharts>
                    <div class="grey lighten-5">
                        <v-divider />
                        <v-card-subtitle>ماکزیمم کاهش</v-card-subtitle>
                        <v-card-title class="font-weight-medium grey--text">+$5M</v-card-title>
                        <v-card-subtitle>کپی دارایی های تحت مدیریت</v-card-subtitle>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12>">
                <v-card>
                    <v-card-subtitle>آمارها</v-card-subtitle>
                    <v-row>
                        <v-col class="pa-8 d-flex align-center flex-column">
                            <v-icon x-large>fas fa-calendar</v-icon>
                            <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-light black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pa-8 d-flex align-center flex-column">
                            <v-icon x-large>fas fa-user-check</v-icon>
                            <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-light black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pa-8 d-flex align-center flex-column">
                            <v-icon x-large>fas fa-clock</v-icon>
                            <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-light black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pa-8 d-flex align-center flex-column">
                            <v-icon x-large>fas fa-download</v-icon>
                            <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-light black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import VueHighcharts from "vue2-highcharts";
    import Drilldown from "highcharts/modules/drilldown.js";
    import * as data from "@/assets/data.js";
    import Highcharts from "highcharts";

    Drilldown(Highcharts);
    Highcharts.setOptions({
        lang: {
            drillUpText: "back"
        }
    });
    export default {
        components: {
            VueHighcharts
        },
        data() {
            return {
                Highcharts: Highcharts,
                drilldownOptions: data.DrilldownData,
                areaOptions: data.AreaData
            };
        },
        computed: {
            coloredData() {
                this.drilldownOptions.series[0].data.forEach(item => {
                    item.y > 0 ? item.color = 'LightGreen' : item.color = 'Crimson'
                })

                return this.drilldownOptions
            }
        }
    };
</script>
