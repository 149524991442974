<template>
    <v-card class="elevation-0">

        <v-card-title>
            <span class="title">
                <span>درخواست نمایندگی</span>
            </span>
        </v-card-title>
        <v-card-text>

            <v-autocomplete
                    :items="companiesList"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="phoneNumber"
                    :label="$t('enterCompanyNameToAddLabel')"
                    :search-input.sync="searchInput"
                    :loading="loadingList"
                    solo
                    flat
                    class="elevation-1"
                    loader-height="2"
                    prepend-inner-icon="search"
                    v-model="companyToRequest"
                    return-object
            >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            بدون نتایج
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>

        </v-card-text>
        <v-fade-transition>
            <v-card-text v-if="companyToRequest != null">
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-col>مشخصات شرکت</v-col>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>

                        <v-row>
                            <v-col sm="3" cols="12">
                                <v-avatar large>
                                    <img
                                            :src="staticBaseURL + companyToRequest.avatar"
                                            alt="Company-logo"
                                    >
                                </v-avatar>
                            </v-col>
                            <v-col sm="3" cols="12">
                                <span>نام :</span>
                                {{companyToRequest.name}}

                            </v-col>
                            <v-col sm="3" cols="12">
                                <span>شماره تماس:</span>
                                {{companyToRequest.phoneNumber}}
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="sendRequest" class="primary elevation-0">ارسال درخواست</v-btn>
                                <v-btn @click="companyToRequest = null" class="elevation-0 mx-1" color="red" text>بستن
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-card-text>
        </v-fade-transition>

    </v-card>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import {EventBus} from "../event-bus";

    export default {
        data: () => ({
            companiesList: [],
            searchInput: "",
            searchHandler: null,
            loadingList: false,
            companyToRequest: null,
            percentOfCompany: ""
        }),
        methods: {
            sendRequest() {
                this.$store.dispatch('addAgency', this.companyToRequest.id).then((resp) => {
                    console.log(resp);
                    this.$emit('requestSent');
                    EventBus.$emit('notif', 'green', undefined, 'درخواست با موفقیت به ثبت رسید');
                    this.companyToRequest = null;

                });
            },
            ...mapActions({
                searchCompanies: 'searchCompanies'
            }),
            search() {
                clearTimeout(this.searchHandler);
                this.percentOfCompany = 0;

                if (this.searchInput != null && this.searchInput.length >= 3) {
                    let data = {
                        "name": this.searchInput,
                        "pageNumber": 1,
                        "pageSize": 20
                    };
                    this.loadingList = true;
                    this.searchHandler = setTimeout(() => {

                        this.searchCompanies(data).then((resp) => {
                            this.companiesList = [];
                            this.companiesList = resp.result.data;
                        }).finally(() => {
                            this.loadingList = false;
                        });
                    }, 1000);

                } else {
                    this.loadingList = false;
                }
            }
        },
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            })
        },
        watch: {
            searchInput() {
                if (this.companyToRequest != null) {
                    if (this.companyToRequest.name != this.searchInput) {
                        this.search();
                    }
                } else {
                    this.search();
                }
            }
        }
    }
</script>

<style scoped>

</style>