<template>
    <div>
        <v-img height="200px" src="./../../../src/assets/company_header.jpg" style="border-radius: 8px" class="d-flex align-center">
            <div
                    class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text"
                    style="height: 100%;"
            ></div>
<!--            <v-col-->
<!--                    cols="auto"-->
<!--                    class="text-center"-->
<!--            >-->
<!--                <v-row-->
<!--                        class="ma-0 fill-height"-->
<!--                        :class="!$vuetify.breakpoint.smAndDown ? ['flex-column', 'justify-space-between'] : ''">-->
<!--                    <v-btn dark class="py-5" icon>-->
<!--                        <v-icon>mdi-heart-outline</v-icon>-->
<!--                    </v-btn>-->
<!--                    <v-btn dark class="py-5" icon>-->
<!--                        <v-icon>mdi-bookmark-outline</v-icon>-->
<!--                    </v-btn>-->
<!--                    <v-btn dark class="py-5" icon>-->
<!--                        <v-icon>mdi-share-variant</v-icon>-->
<!--                    </v-btn>-->
<!--                </v-row>-->
<!--            </v-col>-->
        </v-img>
        <v-container>
            <v-row justify="center" style="margin-top: -100px">
                <v-col md="11">
                    <v-expansion-panels class="px-3 py-3" width="100%">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''">
                                    <v-col cols="auto">
                                        <v-avatar tile class="elevation-2" style="border-radius: 4px" height="100" width="100">
                                            <v-img
                                                    contain
                                                    :src="$store.getters.getStaticBaseURL+company.avatar"
                                            ></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col>
                                        <v-card-title class="font-weight-bold grey--text text--darken-1 py-0" v-text="company.name"></v-card-title>
                                        <v-card-text class="pa-0">
                                            <v-card-subtitle class="font-weight-bold grey--text text--lighten-1 pb-2"
                                                             v-text="'آدرس: ' + (company.address != null ? company.address : 'در دسترس نیست')"></v-card-subtitle>
                                            <v-card-text class="font-weight-bold grey--text text--lighten-1 pb-0"
                                                         v-text="'تلفن: ' + (company.address != null ? company.phoneNumber : 'در دسترس نیست')"></v-card-text>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-card-actions class="pa-0" :class="$vuetify.breakpoint.smAndDown ? 'justify-start' : 'justify-end'">
                                            <v-btn color="primary" dark>
                                                جزییات بیشتر
                                            </v-btn>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col v-html="company.description"></v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        props: ['company']
    }
</script>

<style>
.v-expansion-panel-header__icon {
    margin-top: auto;
}
</style>
