<template>
    <div>
        <v-overlay :value="overlay" z-index="10" opacity="1" color="white">
            <v-progress-circular color="blue" indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <Navbar></Navbar>
        <v-content>
            <v-progress-linear
                    fixed
                    indeterminate
                    :query="$store.getters.isRTL"
                    :active="$store.getters.isPageLoading"
                    color="primary"
            ></v-progress-linear>
            <!--<locale-select v-if="this.$store.getters.hasNoDefaultCountryAndLanguage"></locale-select>-->

            <v-btn fab small class="back-to-top" v-if="scrollFabIsVisible" @click="$vuetify.goTo(0)">
                <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>
            <router-view></router-view>
            <myfooter></myfooter>
        </v-content>

        <v-snackbar

                v-model="snackbar.visibility"
                :color="snackbar.color"
                :bottom="snackbar.position === 'bottom'"
                :timeout="snackbar.timeout"
                :top="snackbar.position === 'top'"
        >
            {{snackbar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn
                        v-bind="attrs"
                        color="white"
                        small
                        @click="snackbar.visibility = false"
                        fab
                >
                    <v-icon small :color="snackbar.color">{{ snackbar.icon }}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Navbar from '@/components/Navbar';
    import Myfooter from '@/components/Myfooter'
    // import LocaleSelect from '@/components/LocaleSelect'
    import {EventBus} from '@/../src/event-bus.js';

    // Listen for the i-got-clicked event and its payload.

    /*eslint-disable*/
    export default {
        name: 'mainlayout',
        components: {
            Navbar,
            Myfooter,
            // LocaleSelect
        },
        created() {
            // var self = this;
            window.addEventListener('scroll', this.handleScroll);
            EventBus.$on('all-loaded', () => {
                this.overlay = false;
            });

            EventBus.$on('loading', () => {
                this.overlay = true;
            });


        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            EventBus.$off('notif');
        },
        mounted() {
            EventBus.$on('notify', (data) => {
                this.snackbar = data;
            });

            EventBus.$on('notif', (color='success', timeout = 3000, message='عملیات با موفقیت انجام شد.', icon='fas fa-times') => {

                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;
                this.snackbar.icon = icon
            });

        },
        data() {
            return {
                lang: "",
                path: this.$route.name,
                isRTL: this.$store.getters.isRTL,
                drawer: true,
                // pageIsScrolled: false,
                scrollFabIsVisible: false,
                overlay: false,
                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 3000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                    icon: 'fas fa-times'
                },

            };
        },
        computed: {
            languageIsChanged() {
                return this.$store.getters.isRTL
            }
        },
        watch: {
            languageIsChanged: function () {
                this.isRTL = this.$store.getters.isRTL;
                this.$vuetify.rtl = this.isRTL;
            },
        },
        methods: {
            handleScroll() {
                if (window.scrollY > 100 && !this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = true;

                } else if (window.scrollY <= 100 && this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = false;

                }

            }
        }
    }
</script>

<style>
    .back-to-top {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 2;
    }
</style>
