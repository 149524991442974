/*eslint-disable*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import routes from './routes.js';
import i18n from '../i18n';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    const lang = to.params.lang;

    if (!store.state.localization.languageCodes.includes(lang)) {

        next(store.getters.getLocal);

    } else if (store.state.localization.languageCodes.includes(lang)) {
        if (store.getters.getLocal != lang) {
            store.dispatch('changeLocal', lang);
            i18n.locale = lang;
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {

        //AUTHENTICATION IS REQUIRED
        if (!store.getters.userIsLoggedIn) {
            next({name: 'login'});
            return;
        }

        if (store.getters.userHasRoles(to.meta.requiredRoles)) {
            next();
        } else {
            next({name: 'home'});
        }

    } else if (to.matched.some(record => record.meta.requiresGuest)) {

        // REQUIRES GUEST
        if (store.getters.userIsLoggedIn) {
            next({
                name: 'home',
                params: {
                    lang,
                }
            });
        } else {
            next();
        }

    } else {
        //REQUIREMENT IS NOT STATED, THUS, WE WILL HAVE NO INTERFERENCE
        next();
    }


});

export default router
