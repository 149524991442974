<template>
    <div>
        <UserNav></UserNav>
        <v-app-bar
                app
                class="elevation-0 "
                :height="$vuetify.breakpoint.mdAndUp ? '60' : '60'"
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                :clipped-right="$vuetify.breakpoint.lgAndUp"
        >
            <v-app-bar-nav-icon color="primary" @click.stop="showDrawer" v-if="showNavIcon"></v-app-bar-nav-icon>
            <template v-if="showTopMenu">

                <v-toolbar-items class="mx-5">
                    <router-link :to="{name:'home'}">
                        <v-toolbar-title class="mx-1 my-auto">
                            <v-img :src="require('../assets/BetaBours.png')" max-width="60"></v-img>
                        </v-toolbar-title>
                    </router-link>
                    <v-btn class="elevation-0 mx-2" @click="goToCompany">
                        اخذ وکالت
                    </v-btn>
                    <v-btn class="elevation-0 mx-2" @click="goToCompany">
                        ارائه حق رای
                    </v-btn>
                    <v-dialog
                            v-model="dialog"
                            width="500"
                    >
                        <v-card class="pa-5">
                            <!--                <v-card-title-->
                            <!--                        class="headline grey lighten-2"-->
                            <!--                        primary-title-->
                            <!--                >-->
                            <!--                    Privacy Policy-->
                            <!--                </v-card-title>-->

                            <v-card-title class="justify-center">
                                <div>برای ارایه حق رای یا اخذ وکالت باید وارد سایت شوید.</div>
                            </v-card-title>

                            <v-card-actions class="justify-space-between" v-if="$route.name != 'login'">
                                <v-btn
                                        color="primary"
                                        text
                                        tile
                                        @click="dialog = false"
                                >
                                    به صفحه ورود هدایت می شوید
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-btn @click="stayHereFn" dark color="red" class="mt-3">
                                    لغو
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar-items>

                <v-spacer></v-spacer>
                <v-toolbar-items class="mx-2">
                    <!--<v-btn small color="primary" text @click="emitBus('navCategoriesClicked')">{{$t('navServices')}}-->
                    <!--</v-btn>-->
                    <!--<v-btn small color="primary" text @click="emitBus('navHowWeWorkClicked')">{{$t('navHowWeWork')}}-->
                    <!--</v-btn>-->

                    <!--<v-btn small color="primary" text class="elevation-0" @click="gotoProSignup"-->
                    <!--v-if="!$store.getters.userIsProfessional">-->
                    <!--{{$t('navBecomeADoctor')}}-->
                    <!--</v-btn>-->
                </v-toolbar-items>

                <LanguageSelect></LanguageSelect>
                <template v-if="$store.getters.userIsLoggedIn">


                    <!--<v-btn class="primary&#45;&#45;text elevation-0 py-0 mx-1" small :to="{name:'userWallet'}">-->
                    <!--{{this.profile.credit}}-->
                    <!--<v-icon small>fas fa-euro-sign</v-icon>-->
                    <!--</v-btn>-->

                    <div class="text-center" v-if="profile!=null">
                        <v-menu
                                :nudge-width="200"
                                offset-x
                                offset-y

                        >
                            <template v-slot:activator="{ on }">
                                <v-btn class="elevation-1 transparent mx-5" fab>
                                    <v-avatar>
                                        <v-img
                                                :src="'http://betabours.ir:8080'+profile.avatar"
                                                v-on="on">
                                        </v-img>
                                    </v-avatar>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-list>
                                    <v-list-item>

                                        <v-list-item-content>

                                            <v-list-item-title v-if="$store.getters.userIs('real')">
                                                {{profile.firstName+' '+ profile.lastName}}
                                            </v-list-item-title>
                                            <v-list-item-title v-if="$store.getters.userIs('legal')">
                                                {{profile.companyName}}
                                            </v-list-item-title>

                                        </v-list-item-content>

                                    </v-list-item>
                                    <v-divider></v-divider>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn small text :to="{name:'userInfo'}">{{$t('profile')}}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn small text @click="logOut">{{$t('signout')}}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                        </v-menu>
                    </div>
                </template>

                <div v-else>
                    <v-btn text class="mx-2" small :to="{name:'login'}" dark>
                        <span class="primary--text">{{$t('loginText')}} | {{$t('signUpText')}}</span>
                    </v-btn>
                </div>


                <!--<v-toolbar-items>-->


                <!--<v-btn text v-if="!this.$store.getters.userIsLoggedIn">-->

                <!--<span>{{$t('message.logout.title')}}</span>-->

                <!--<v-icon right>fas fa-sign-out-alt</v-icon>-->
                <!--</v-btn>-->
                <!--<v-btn text v-else link :to="{name:'login'}" dark>-->
                <!--<span class="black&#45;&#45;text">{{$t('message.login.title')}}</span>-->
                <!--<v-icon class="black&#45;&#45;text" right>fas fa-sign-in-alt</v-icon>-->
                <!--</v-btn>-->
                <!--</v-toolbar-items>-->
            </template>
            <template v-else>
                <v-toolbar-items class="mt-3">
                    <router-link :to="{name:'home'}">
                        <v-toolbar-title :class="{'my-2':$vuetify.breakpoint.mdAndUp}">
                            <v-img :src="require('../assets/stock.png')" max-width="50"></v-img>
                        </v-toolbar-title>
                    </router-link>
                </v-toolbar-items>
            </template>
        </v-app-bar>

        <!--<v-navigation-drawer-->
        <!--v-if="false"-->
        <!--v-model="drawer"-->
        <!--:right="isRTL"-->
        <!--temporary-->
        <!--app-->
        <!--&gt;-->
        <!--<v-list>-->
        <!--<v-list-item-group>-->

        <!--<template v-if="!this.$store.getters.userIsLoggedIn && profile!=null">-->


        <!--<v-list-item>-->
        <!--<v-list-item-content>-->
        <!--<v-btn text class="mx-3" small :to="{name:'login'}" dark>-->
        <!--<span class="black&#45;&#45;text">{{$t('loginText')}} | {{$t('signUpText')}}</span>-->
        <!--</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-divider></v-divider>-->
        <!--</template>-->

        <!--<template v-if="this.$store.getters.userIsLoggedIn && profile!=null">-->
        <!--<v-list-item-avatar>-->
        <!--<v-img :src="'http://193.176.240.111:8080'+profile.avatar"></v-img>-->
        <!--</v-list-item-avatar>-->
        <!--<template v-if="$store.getters.userIsReal">-->
        <!--<v-list-item>-->
        <!--<v-list-item-content>-->
        <!--<v-list-item-title>{{profile.firstName+' '+ profile.lastName}}</v-list-item-title>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->

        <!--</template>-->
        <!--<template v-else>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content>-->
        <!--<v-list-item-title>{{profile.companyName}}</v-list-item-title>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--</template>-->
        <!--<v-divider></v-divider>-->

        <!--</template>-->

        <!--<template v-if="this.$store.getters.userIsLoggedIn && profile!=null">-->
        <!--<v-list-item>-->
        <!--<v-list-item-icon>-->
        <!--<v-icon>exit_to_app</v-icon>-->
        <!--</v-list-item-icon>-->
        <!--<v-list-item-content>-->
        <!--<v-list-item-title @click="logOut">Log Out</v-list-item-title>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--</template>-->

        <!--<v-list-item>-->
        <!--<v-list-item-icon>-->
        <!--<v-icon>fas fa-sort-down</v-icon>-->
        <!--</v-list-item-icon>-->
        <!--<v-list-item-content>-->
        <!--<LanguageSelect isDrawer="true"></LanguageSelect>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->

        <!--</v-list-item-group>-->
        <!--</v-list>-->

        <!--<v-divider></v-divider>-->

        <!--</v-navigation-drawer>-->
    </div>
</template>
<script>
    import LanguageSelect from '@/components/LanguageSelect'
    import {EventBus} from '@/../src/event-bus.js';
    import UserNav from '@/components/UserNav.vue'
    import {mapGetters} from 'vuex';
    export default {
        components: {
            LanguageSelect,
            UserNav
        },
        created() {
            EventBus.$on('loggedOut', this.removeInfo);
            EventBus.$on('profileUpdated', this.updateUserInfo);
            EventBus.$on('profileUpdated', this.updateUserInfo);
        },
        mounted() {
            this.updateUserInfo();
            this.isRTL = this.$store.getters.isRTL;
            this.$vuetify.rtl = this.isRTL;
        },
        beforeDestroy() {
            EventBus.$off('profileUpdated', this.updateUserInfo);
            EventBus.$off('loggedOut', this.removeInfo);
            EventBus.$off('profileUpdated', this.updateUserInfo);
        },
        data() {
            return {
                userIsLoggedIn: this.$store.getters.userIsLoggedIn,
                path: this.$route.name,
                isRTL: this.$store.getters.isRTL,
                drawer: false,
                dialog: false,
                stayHere: false,
                // languages: [
                //     {
                //         title: 'Fa', img: require('../assets/flags/ir.png')
                //     },
                //     {
                //         title: 'En', img: require('../assets/flags/en.jpg')
                //     },
                //     {
                //         title: 'Ar', img: require('../assets/flags/Ar.jpg')
                //     }
                // ],
                activeLanguage: "",
                // profile: null

            };
        },
        computed: {
            ...mapGetters({
                profile: 'getUserProfile',
                loggedIn: 'userIsLoggedIn'
            }),
            // userIsLoggedIn() {
            //     // console.log("----------------------------------------");
            //     // console.log(this.$store.getters.userIsLoggedIn);
            //     // console.log('profile', this.$store.getters.getUserProfile);
            //     // console.log('profile', this.profile);
            //     // console.log("----------------------------------------");
            //
            //     return this.$store.getters.userIsLoggedIn;
            // },
            userIsReal() {
                return this.$store.getters.userIsReal;
            },
            languageIsChanged() {
                return this.$store.getters.isRTL
            },
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.smAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            },

        },
        watch: {
            prof() {
                // this.profile = this.$store.getter.getUserProfile;
            },
            languageIsChanged: function () {
                this.isRTL = this.$store.getters.isRTL;
                this.$vuetify.rtl = this.isRTL;
            }
        },
        methods: {
            goToCompany() {
                if(this.loggedIn) {
                    this.$router.push({name:'companies'});
                } else {
                    this.stayHere = false
                    this.dialog = true
                    setTimeout(() => {
                        this.dialog = false
                        if(!this.stayHere) {
                            this.$router.push({name:'login'});
                        }
                    }, 3000)

                }
            },
            stayHereFn() {
                this.stayHere = true
                this.dialog = false
            },
            removeInfo() {
                // this.profile = null;
            },
            changeLanguage(item) {
                this.activeLanguage = item;
            },
            logOut() {
                this.$store.dispatch('signOut').then(() => {
                    this.$router.push({name: 'home'});
                });
            },
            emitBus(ev) {
                if (this.$route.name != 'home') {
                    this.$router.push({name: 'home'}).then(() => {
                        this.$nextTick(() => {
                            EventBus.$emit(ev);
                        });
                    });
                } else {
                    EventBus.$emit(ev);
                }

            },
            gotoProSignup() {
                if (this.$store.getters.userIsLoggedIn) {
                    this.$router.push({name: 'customerExpertSignup'});
                } else {
                    this.$router.push({name: 'professionalsignup'});
                }

                // console.log('here');
                // this.$router.push({name: 'userHome'});
            },
            updateUserInfo() {
                // this.profile = this.$store.getters.getUserProfile;

            },
            showDrawer() {
                EventBus.$emit('openNavigationDrawer');
            }
        }
    }
</script>
<style>

    .underlined-text {
        text-decoration: underline;
    }

    .diagonal-shadow {
        -webkit-box-shadow: 10px 8px 10px -4px rgba(0, 0, 0, .4);
        -moz-box-shadow: 10px 8px 10px -4px rgba(0, 0, 0, .4);
        box-shadow: 10px 8px 10px -4px rgba(0, 0, 0, .4);
    }
</style>
