<template>

    <v-parallax
            dark
            :src="require('@/assets/professional1.jpg')"
            class="pa-0 ma-0"
            :height="pageHeight"

    >
        <v-container fluid>
            <v-row class="mt-12">

                <v-col cols="12" lg="4" md="6">
                    <v-card class="pa-3">
                        <LoginComponent :redirect="true" title="Sign up as a partner"></LoginComponent>
                    </v-card>

                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="12" lg="5" md="6" sm="12" xs="12">
                    <h1 class="font-weight-bold pro-signup-text grey darken-2 pa-3">
                        {{$t('proSignUpBigText')}}
                    </h1>
                    <hr class="ma-3"/>
                    <p class="headline font-weight-light ma-2 grey darken-2 pa-3">
                        {{$t('proSignUpSmallText')}}
                    </p>

                </v-col>
            </v-row>
        </v-container>
    </v-parallax>
</template>
<script>
    import LoginComponent from '@/components/LoginComponent'

    export default {
        components: {
            LoginComponent
        },
        data() {
            return {
                showPassword: false,
                email: "",
                emailRules: [],
                password: "",
                errorMessages: '',
                name: null,
                address: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                formHasErrors: false,
                rules: {
                    required: value => !!value || 'Required.    ',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => ('The email and password you entered don\'t match'),
                },
                pageHeight: 'auto'
            }
        },
        computed: {
            form() {
                return {
                    // name: this.name,
                    // address: this.address,
                    // city: this.city,
                    // state: this.state,
                    // zip: this.zip,
                    // country: this.country,
                    email: this.email
                }
            },
        },
        watch: {
            name() {
                this.errorMessages = ''
            },
            pageHeight() {

            }
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);

        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },

        methods: {
            addressCheck() {
                this.errorMessages = this.address && !this.name
                    ? 'Hey! I\'m required'
                    : ''

                return true
            },
            resetForm() {
                this.errorMessages = []
                this.formHasErrors = false

                Object.keys(this.form).forEach(f => {
                    this.$refs[f].reset()
                })
            },
            submit() {
                // this.formHasErrors = false
                //
                // Object.keys(this.form).forEach(f => {
                //     if (!this.form[f]) this.formHasErrors = true
                //
                //     this.$refs[f].validate(true)
                // })
            },
            myEventHandler() {
                // your code for handling resize...
                // console.log(e);

                this.pageHeight = window.innerHeight - 100;

            }
        },
        mounted() {
            // window.scrollTo(0, 0);
            this.pageHeight = window.innerHeight - 100;
        }
    }
</script>
<style>
    .pro-signup-text {
        font-size: 2em;
        line-height: 40px;
    }

    @media screen and (min-width: 768px) {
        .pro-signup-text {
            font-size: 3em;
            line-height: 70px;
        }
    }

</style>