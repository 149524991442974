<template>
    <!--<v-container>-->
    <!--<v-row>-->
    <!--<v-col cols="12">-->
    <!--<span>{{remaining.minutes}}</span> <span>:</span> <span>{{remaining.seconds}}</span>-->
    <!--</v-col>-->
    <!--</v-row>-->
    <!--</v-container>-->
    <div></div>
</template>
<script>


    export default {
        props: ['initialMinutes', 'initialSeconds'],
        data() {
            return {
                timerHandler: null,
                // period: 1,
                remaining: {
                    minutes: this.initialMinutes,
                    seconds: this.initialSeconds
                }
            };
        },
        methods: {
            countDown() {

                if (this.remaining.seconds > 0) {
                    this.remaining.seconds--;
                    this.$emit('countDown', this.remaining);
                } else if (this.remaining.seconds == 0) {
                    if (this.remaining.minutes > 0) {
                        this.remaining.minutes--;
                        this.remaining.seconds = 59;
                    } else if (this.remaining.minutes == 0) {
                        this.$emit('counterStopped');
                        clearInterval(this.timerHandler);
                    }
                }

            },
            restart() {
                this.remaining.minutes = 0;
                this.remaining.seconds = 0;
                this.start();
            },
            start() {
                var self = this;

                // this.remaining.minutes = this.period;

                this.timerHandler = setInterval(function () {
                    self.countDown();
                }, 1000);


            },
            reset(data) {
                this.remaining.minutes = data.minutes;
                this.remaining.seconds = data.seconds;
                clearInterval(this.timerHandler);
                this.start();
            }

        },
        mounted() {
            this.start();
        }

    }
</script>
<style scoped>
    span {
        color: black
    }
</style>