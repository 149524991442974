<template>
    <div>

        <v-container fluid>
            <v-card class="elevation-0">
                <HomeParallax></HomeParallax>
            </v-card>
        </v-container>
        <v-container fluid>
            <assemblies></assemblies>

            <!--<howitworks></howitworks>-->
            <Testimonials></Testimonials>
            <app-presentation></app-presentation>
            <blog-posts></blog-posts>

        </v-container>
    </div>
</template>

<script>

    // @ is an alias to /src


    import Assemblies from '@/components/Assemblies'
    import BlogPosts from '@/components/BlogPosts'
    // import Howitworks from '@/components/Howitworks'
    import AppPresentation from '@/components/AppPresentation.vue'
    import HomeParallax from '@/components/HomeParallax'
    import Testimonials from '@/components/Testimonials';

    export default {
        name: 'home',

        components: {
            Testimonials,
            // Howitworks,
            AppPresentation,
            HomeParallax,
            Assemblies,
            BlogPosts
        },
        mounted() {

        }
    }
</script>
