<template>
    <v-card elevation="0">
        <div class="d-flex flex-no-wrap" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''">
            <v-avatar
                    :class="$vuetify.breakpoint.smAndDown ? ['d-none'] : ''"
                    class="elevation-1"
                    size="150"
                    tile
            >
                <v-img
                        contain
                        class="pa-2"
                        :src="$store.getters.getStaticBaseURL+company.avatar">
                    <v-chip label small dark :color="item.state.value == 1 ? 'green' : 'red'" v-text="item.state.title"></v-chip>
                </v-img>
            </v-avatar>
            <v-card
                    class="d-flex flex-grow-1 mr-2 justify-space-between elevation-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''">
                <div class="d-flex flex-column justify-space-between">
                    <v-card-title><h5 v-text="item.title"></h5></v-card-title>
                    <div class="d-flex">
                        <v-card-subtitle v-text="'تاریخ: ' + date"></v-card-subtitle>
                        <v-card-subtitle v-text="'زمان: ' + time"></v-card-subtitle>
                    </div>
                </div>
                <v-card-actions class="align-self-end">
                    <v-btn :large="!$vuetify.breakpoint.smAndDown" color="secondary" dark @click="$emit('requestVoteAssembly')">
                        اراِیه حق رای
                        <v-icon v-if="!$vuetify.breakpoint.smAndDown" class="pa-2">fas fa-vote-yea</v-icon>
                    </v-btn>
                    <v-btn :large="!$vuetify.breakpoint.smAndDown" color="secondary" @click="$emit('selectAssembly')" outlined>
                        اخذ وکالت
                        <v-icon v-if="!$vuetify.breakpoint.smAndDown" class="pa-2">fas fa-gavel</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-card>
</template>

<script>
    import moment from 'moment-jalali'
    export default {
        props: ['item', 'company'],
        computed: {
            time() {
                return moment(this.item.endDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm')
            },
            date() {
                return moment(this.item.endDateTime, 'YYYY-MM-DD HH:mm').format('jYYYY/jMM/jDD')
            }
        }
    }
</script>
