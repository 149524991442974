<template>
    <v-row>
        <v-dialog
                v-model="contractOverlay"
                max-width="70%"

        >

            <v-card v-if="currentVote!=null" @click="contractOverlay = false">
                <v-card-text>
                    <v-img :src="$store.getters.getStaticBaseURL+currentVote.contract">
                    </v-img>
                </v-card-text>
            </v-card>

        </v-dialog>
        <v-dialog v-model="voteDialog" max-width="600" persistent>
            <v-window v-model="windowItem">
                <v-window-item :value="1">
                    <v-card :loading="isSaving">
                        <v-card-title
                                class=""
                                primary-title
                        >
                            وارد کردن درصد سهام
                        </v-card-title>

                        <v-card-text>
                            <v-row justify="center" class="align-content-center align-center align-content-center">
                                <v-col cols="12" lg="6" v-if="selectedTrader!=null">

                                    <v-hover v-slot:default="{  }">

                                        <v-card class="cursor-pointer"

                                        >

                                            <v-img
                                                    height="100%"
                                                    src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                                                    class="gradient-image"
                                            >
                                                <v-row
                                                        align="end"
                                                        class="fill-height"
                                                >

                                                    <v-col
                                                            align-self="start"
                                                            class="pa-0"
                                                            cols="12"
                                                    >
                                                        <v-avatar
                                                                class="profile ma-5 mx-10 mt-5 mb-0 elevation-10"
                                                                color="grey"
                                                                size="80"
                                                        >
                                                            <v-img
                                                                    :src="$store.getters.getStaticBaseURL+selectedTrader.avatar"
                                                                    lazy-src="https://speido.com:8443/images/defaults/user/default-user.png"
                                                            ></v-img>
                                                        </v-avatar>

                                                    </v-col>

                                                    <v-col>
                                                        <v-list-item
                                                                color="rgba(0, 0, 0, .4)"
                                                                class="py-0"
                                                                dark
                                                        >
                                                            <v-list-item-content class="py-0">
                                                                <v-list-item-title class="title">
                                                                    <span>{{selectedTrader.profileInformation.firstName}} {{selectedTrader.profileInformation.lastName}}</span>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>


                                                </v-row>
                                            </v-img>
                                        </v-card>
                                    </v-hover>
                                    {{selectedTrader.lastName}}
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                            v-model="percentage"
                                            label="درصد سهام"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>

                            <SaveWithCode @proceed="vote" :saving="isSaving"></SaveWithCode>
                            <v-btn
                                    color="red"
                                    class="elevation-0"
                                    text
                                    @click="voteDialog = false"
                            >
                                رد
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-window-item>
                <v-window-item :value="2">

                    <v-card v-if="savedVote!=null">

                        <v-card-title>بررسی نهایی</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>رای شما به ثبت رسید، لطفا در صورت صحت اطلاعات، تایید نمایید</v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="title">
                                    <span>مشخصات رای گیرنده</span>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col>
                                    <!--<span>نام </span> : <span>{{savedVote.voteCollectorUser.profileInformation.firstName}}</span>-->
                                    <v-text-field label="نام" readonly
                                                  :value="savedVote.voteCollectorUser.profileInformation.firstName"></v-text-field>
                                    <!--<span>نام </span> : <span>{{savedVote.voteCollectorUser.profileInformation.firstName}}</span>-->
                                </v-col>
                                <v-col>
                                    <v-text-field label=" نام خانوادگی" readonly
                                                  :value="savedVote.voteCollectorUser.profileInformation.lastName"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field label="کد ملی" readonly
                                                  :value="savedVote.voteCollectorUser.profileInformation.nationalCode"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field label="جنسیت" readonly
                                                  :value="savedVote.voteCollectorUser.profileInformation.sex.title"></v-text-field>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col>
                                    میزان درصد :
                                    <v-chip class="px-5">{{savedVote.percentage}}</v-chip>
                                    <v-btn text class="primary--text" small
                                           :href="$store.getters.getStaticBaseURL + savedVote.voteCollectorUser.resume"
                                    >
                                        دریافت فایل استراتژی
                                        <v-icon small class="mx-2">fas fa-download</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <!--<v-card class="elevation-0">-->
                                <!--<v-card-text>عکس قرارداد</v-card-text>-->
                                <!--<v-img-->
                                        <!--max-height="100"-->
                                        <!--:src="$store.getters.getStaticBaseURL + savedVote.contract"-->
                                <!--&gt;</v-img>-->

                            <!--</v-card>-->
                            <v-hover v-slot:default="{hover}">
                                <v-card>
                                    <v-card-title class="pa-3">
                                        قرارداد
                                    </v-card-title>
                                    <v-img :src="$store.getters.getStaticBaseURL+savedVote.contract" alt="contract"
                                           height="115">
                                        <v-overlay
                                                absolute
                                                :value="hover"
                                                style="cursor: pointer;"

                                        >
                                            <v-btn fab small @click="showContract">
                                                <v-icon small>fas fa-search-plus</v-icon>
                                            </v-btn>
                                        </v-overlay>

                                    </v-img>
                                </v-card>
                            </v-hover>


                        </v-card-text>

                        <v-card-actions>
                            <v-btn @click="reconfirmVote">تایید</v-btn>
                            <v-btn class="red--text red lighten-4" text>رد</v-btn>
                        </v-card-actions>

                    </v-card>
                </v-window-item>
            </v-window>


        </v-dialog>
        <v-col>
            <v-card-title>
                انتخاب متخصص
            </v-card-title>

            <v-divider></v-divider>
            <v-card
                    class="mb-12 elevation-0 transparent"
            >
                <v-container fluid>
                    <v-row justify="center" v-if="traders.length">
                        <v-col cols="12" lg="3" md="6" sm="6"
                               v-for="(trader) in traders"

                               :key="trader.id">

                            <!--@click="selectTrader(trader)"-->
                            <v-hover v-slot:default="{ hover }">

                                <v-card class="cursor-pointer"
                                        :elevation="hover ? 3 : 0"
                                        @click="selectTrader(trader)"
                                >

                                    <v-img
                                            height="100%"
                                            src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                                            class="gradient-image"
                                    >
                                        <v-row
                                                align="end"
                                                class="fill-height"
                                        >

                                            <v-col
                                                    align-self="start"
                                                    class="pa-0"
                                                    cols="12"
                                            >
                                                <v-avatar
                                                        class="profile ma-5 mx-10 mt-5 mb-0 elevation-10"
                                                        color="grey"
                                                        size="80"
                                                >
                                                    <v-img
                                                            :src="$store.getters.getStaticBaseURL+trader.avatar"
                                                            lazy-src="https://speido.com:8443/images/defaults/user/default-user.png"
                                                    ></v-img>
                                                </v-avatar>

                                            </v-col>

                                            <v-col>
                                                <v-list-item
                                                        color="rgba(0, 0, 0, .4)"
                                                        class="py-0"
                                                        dark
                                                >
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title class="title">
                                                            <span>{{trader.profileInformation.firstName}} {{trader.profileInformation.lastName}}</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>


                                        </v-row>
                                    </v-img>
                                </v-card>
                            </v-hover>
                            {{trader.lastName}}
                        </v-col>
                        <v-pagination
                                v-model="workersPage"
                                :length="workersPageCount"
                        ></v-pagination>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            <div class="text-center pa-10 ma-10">
                                {{$t('noSpecialistsAvailable')}}
                            </div>
                        </v-col>
                    </v-row>


                </v-container>


            </v-card>

            <!--<v-btn-->
            <!--color="primary"-->
            <!--@click="setWorker"-->
            <!--&gt;-->
            <!--{{$t('nextButton')}}-->
            <!--</v-btn>-->

            <!--<v-btn text @click="previousStep">{{$t('previousButton')}}</v-btn>-->

        </v-col>
        <!--<v-col cols="12" class="text-center">-->
        <!---->
        <!--</v-col>-->
    </v-row>
</template>

<script>
    import SaveWithCode from '@/components/SaveWithCode.vue';
    import {EventBus} from "../event-bus";

    export default {
        props: ['assembly'],
        components: {
            SaveWithCode
        },
        created() {
            this.$store.dispatch('getTraders', {
                "pageNumber": 1,
                "pageSize": 10,
                "voteId": this.assembly.id
            }).then((resp) => {
                this.traders = resp.result.data;
            })
        },
        methods: {
            showContract() {
                this.currentVote = this.savedVote;
                this.contractOverlay = true;
            },
            reconfirmVote() {

                // this.isSaving = true;
                //
                this.$store.dispatch('confirmVoteAssembly', this.savedVote.id).then(() => {
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت به ثبت رسید'
                    });
                    this.$emit('finished');
                    this.voteDialog = false;
                    this.windowItem = 1;
                }).finally(() => {
                    this.isSaving = false;
                });

            },
            selectTrader(trader) {
                this.selectedTrader = trader;
                this.percentage = 0;
                this.voteDialog = true;
            },
            vote(verification) {

                let payload = {
                    assemblyId: this.assembly.id,
                    data: {
                        percentage: this.percentage,
                        userId: this.selectedTrader.id
                    }

                };

                payload.data.code = verification.code;

                if (this.$store.getters.requiresPassword) {
                    payload.data.staticPassword = verification.password;
                }

                this.isSaving = true;

                this.$store.dispatch('voteAssembly', payload).then((resp) => {
                    this.savedVote = resp.result;

                    this.windowItem = 2;
                }).catch((err) => {

                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'red',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: err.response.data.message[0].error,
                        icon: 'fas fa-times'
                    });

                }).finally(() => {
                    this.isSaving = false;
                });


            }
        },
        data() {
            return {
                currentVote:null,
                contractOverlay:false,
                savedVote: null,
                windowItem: 1,
                fullscreen: false,
                isSaving: false,
                traders: [],
                workersPage: 1,
                workersPageCount: 0,
                selectedTrader: null,
                voteDialog: false,
                percentage: 0,
                reconfirm: false,
                result: {
                    "error": 0,
                    "message": "رای شما با موفقیت ثبت شد. در صورت صحت اطلاعات، آن را تایید کنید.",
                    "result": {
                        "id": 11,
                        "percentage": 2.0,
                        "approximateShare": 0,
                        "contract": "/images/uploads/users/9/contracts/contracts_1587394479417_-4825961172915966026.png",
                        "voteGiverUser": {
                            "id": 9,
                            "stockCode": "123",
                            "profileInformation": {
                                "firstName": "محمد",
                                "lastName": "تست",
                                "nationalCode": "123",
                                "sex": {"value": 1, "title": "مرد"}
                            }
                        },
                        "voteCollectorUser": {
                            "id": 9,
                            "stockCode": "123",
                            "avatar": "/images/defaults/user/default-user.png",
                            "resume": "/files/uploads/users/9/trader_1585920651043_2426114651368888548.docx",
                            "profileInformation": {
                                "firstName": "محمد",
                                "lastName": "تست",
                                "nationalCode": "123",
                                "sex": {"value": 1, "title": "مرد"}
                            }
                        }
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
