<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-card-title>
            ثبت نام به عنوان متخصص
            <v-spacer></v-spacer>
        </v-card-title>
        <hr>

        <v-card-text>
            <v-container>
                <v-row class="">
                    <v-col cols="6">
                        <v-file-input show-size label="رزومه"
                                      accept="file/docs,xlsx,xsl"
                                      outlined
                                      @change="onFileChange"
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-btn class="primary elevation-0" @click="sendRequest">ثبت</v-btn>

        </v-card-actions>
    </v-card>
</template>
<script>
    /*eslint-disable*/

    import {encodeImageFileAsURL} from '../../utilities';
    import {EventBus} from "../../event-bus";

    import {mapActions} from 'vuex';

    export default {

        data() {
            return {
                extension: null,
                resume: null
            }
        },
        created() {

        },
        methods: {
            sendRequest() {
                if (!this.$store.getters.isSigned) {
                    let message = 'لطفا ابتدا پروفایل خود را تکمیل نمایید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }
                if (this.resume == null || this.extension == null) {
                    let message = 'فایل رزومه خود را انتخاب نمایید.';
                    EventBus.$emit('notif', 'red', undefined, message);
                    return;
                }

                this.convertToTrader({
                    resume: this.resume.substr(this.resume.indexOf('base64,') + 7),
                    extension: this.extension
                }).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notif');
                    this.$store.dispatch('getProfile').then(() => {
                        this.$router.push({name: 'userInfo'});
                    });

                })

            },
            onFileChange(file) {

                if (!file || file == undefined) return;
                this.extension = file.name.substr(file.name.lastIndexOf('.') + 1);

                encodeImageFileAsURL(file).then(resp => {
                    this.resume = resp;
                });
            },

            ...mapActions(['convertToTrader'])
        }
    }
</script>

<style>

</style>