<template>
    <v-container>
        <v-row>
            <v-col class="title">
                <span>مجامع پیش رو</span>
                <v-divider class="my-3"></v-divider>
            </v-col>

        </v-row>

        <v-row>

            <v-col v-for="(assembly,index) in assemblies" :key="index" cols="12" sm="6" md="4" lg="4" xl="3">
<!--                <v-card class="elevation-1 fill-height d-flex flex-column justify-space-between">-->
<!--                    <v-img-->
<!--                            contain-->
<!--                           max-height="100"-->
<!--                           :src="'http://betabours.ir:8080'+assembly.companyBaseInfo.avatar">-->
<!--                    </v-img>-->
<!--                    <v-divider />-->
<!--                    <v-card-title>-->
<!--                        <router-link-->
<!--                                style="textDecoration: none"-->
<!--                                :to="{name:'company',params:{code:assembly.companyBaseInfo.id,name:assembly.companyBaseInfo.slug}}">-->
<!--                            {{assembly.companyBaseInfo.name}}-->
<!--                        </router-link>-->
<!--                    </v-card-title>-->
<!--                    <v-card-subtitle>-->
<!--                        <small class="mt-3 grey&#45;&#45;text text-lighten-4">زمان: {{ assembly.startDateTime | toJalali }}</small>-->
<!--                    </v-card-subtitle>-->
<!--                    <v-card-text>-->
<!--                        {{assembly.title | excerpt}}-->
<!--                    </v-card-text>-->
<!--                    <v-card-actions class="justify-end">-->
<!--                        <v-btn small :to="{name:'company',params:{code:assembly.companyBaseInfo.id,name:assembly.companyBaseInfo.slug}}" outlined color="primary">-->
<!--                            <v-icon x-small left>fas fa-info</v-icon> جزییات-->
<!--                        </v-btn>-->
<!--                        <template v-if="$store.getters.userIsLoggedIn">-->
<!--                            <v-btn @click="joinSession(assembly)"-->
<!--                                   v-if="assembly.hasOnlineMeeting && !assembly.meetingTimeExpired" small-->
<!--                                   class="primary"-->
<!--                                   text>ورود به جلسه-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                    </v-card-actions>-->
<!--                </v-card>-->
                <v-card
                        class="mx-auto"
                        max-width="344"
                >
                    <v-list-item three-line>
                        <v-list-item-avatar
                                size="65"
                                color="grey"
                                class="elevation-1"
                        >
                            <v-img class="white" contain :src="'http://betabours.ir:8080'+assembly.companyBaseInfo.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="headline font-weight-bold mb-1">
                                <router-link
                                        style="textDecoration: none; color: grey;"
                                        :to="{name:'company',params:{code:assembly.companyBaseInfo.id,name:assembly.companyBaseInfo.slug}}">
                                    {{assembly.companyBaseInfo.name}}
                                </router-link>
                            </v-list-item-title>
<!--                            <v-list-item-subtitle>{{ assembly.startDateTime | toJalali }}</v-list-item-subtitle>-->
                            <small class="grey--text text-lighten-4">زمان: {{ assembly.startDateTime | toJalali }}</small>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-subtitle class="pt-0">{{assembly.title | excerpt}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-card-actions class="justify-end">
                        <v-btn small :to="{name:'company',params:{code:assembly.companyBaseInfo.id,name:assembly.companyBaseInfo.slug}}" class="ma-2" outlined color="primary">
                            <v-icon x-small left>fas fa-info</v-icon> جزییات
                        </v-btn>
                        <template v-if="$store.getters.userIsLoggedIn">
                            <v-btn small color="primary" dark v-if="assembly.hasOnlineMeeting && !assembly.meetingTimeExpired" @click="joinSession(assembly)">ورود به جلسه</v-btn>
                        </template>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment-jalaali'
    export default {
        data() {
            return {
                assemblies: []
            }
        },
        created() {
            let payload = {
                "pageNumber": 1,
                "pageSize": 6
            };

            this.$store.dispatch('getAssemblies', payload).then(resp => {
                this.assemblies = resp.result.data;
            });

        },
        mounted() {

        },
        methods: {
            joinSession(assembly) {
                console.log(assembly);
                this.$store.dispatch('joinMeeting', assembly.id).then((resp) => {
                    console.log(resp);
                    window.location = resp.result.url;
                });
            }
        },
        filters: {
            excerpt: (val) => {
                if (val.length > 36) {
                    return val.split(" ", 8).join(' ') + '...';
                }
                return val;
            },
            toJalali(input) {
                return moment(input).format('HH:MM - jYYYY/jMM/jDD');
            }
        }
    }
</script>

<style scoped>

</style>
