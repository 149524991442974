<template>

    <v-card class="elevation-4 mb-5 elevation-2" flat>
        Dashboard
    </v-card>

</template>
<script>


    export default {
        components: {},
        data: () => ({}),

        computed: {},

        watch: {},

        created() {
            // this.initialize()
        },
        mounted() {
        },

        methods: {}
    }
</script>

<style>

</style>