<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="date"
                    label="تاریخ تقریبی جلسه"
                    dense
                    v-on="on"
                    outlined
            ></v-text-field>
        </template>

        <v-date-picker scrollable
                       class="elevation-0"
                       v-model="date"
                       show-current
        >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
                Cancel
            </v-btn>
            <v-btn text color="primary"
                   @click="$refs.dialog.save(date)">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
    export default {
        props: ['date'],
        data() {
            return {
                modal: false
            }
        }

    }
</script>

<style scoped>

</style>
