<template>
    <v-card>
        <div class="d-flex justify-space-between align-center py-5 px-4">
            <div class="font-weight-light font-weight-bold">
                علی شایان
                <v-card-subtitle class="pa-0">
                    <small>ایران</small>
                </v-card-subtitle>
            </div>
            <div class="d-flex flex-column align-center">
                <div
                        style="border: 2px solid orange; border-radius: 4px"
                        class="px-3 py-1 orange--text font-weight-medium">6</div>
                <small>ریسک</small>
            </div>
        </div>
        <v-card-text class="py-0">
            <div class="mb-3">درباره من</div>
            <ul class="mr-4">
                <li> I focus on my interests</li>
                <li> I focus on my interests</li>
                <li> I focus on my interests</li>
                <li> I focus on my interests</li>
                <li> I focus on my interests</li>
            </ul>
        </v-card-text>
        <v-card-actions>
            <v-btn small text color="blue">
                مشاهده بیشتر
            </v-btn>
        </v-card-actions>
        <vue-highcharts :highcharts="Highcharts" :options="coloredData" ref="drilldownChart"></vue-highcharts>
    </v-card>
</template>

<script>
    import VueHighcharts from "vue2-highcharts";
    import * as data from "@/assets/data.js";

    export default {
        components: {
            VueHighcharts
        },
        data() {
            return {
                drilldownOptions: data.DrilldownData,
            };
        },
        computed: {
            coloredData() {
                this.drilldownOptions.series[0].data.forEach(item => {
                    item.color = 'LightGreen'
                })

                return this.drilldownOptions
            }
        }
    };
</script>
