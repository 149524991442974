<template>

    <v-window v-model="step">
        <v-window-item :value="1" class="text-center">
            <v-btn class="green white--text ma-2 elevation-0" @click="nextStep" :loading="requesting"
                   :disabled="clockIsWorking">
                {{getRequestLabel()}}
            </v-btn>
        </v-window-item>

        <v-window-item :value="2">

            <v-row>
                <v-col>
                    <v-text-field
                            dense
                            hide-details
                            outlined
                            label="کد تایید"
                            ref="code"
                            length="6"
                            :rules="[rules.required,rules.length]"
                            v-if="codeRequested"
                            v-model="code"
                    ></v-text-field>
                </v-col>

                <v-col>
                    <v-btn class="primary" @click="nextStep">ثبت</v-btn>
                </v-col>
            </v-row>
        </v-window-item>
        <v-window-item :value="3" v-if="$store.getters.requiresPassword">
            <v-row>
                <v-col>
                    <v-text-field
                            dense
                            hide-details
                            outlined
                            label="کلمه عبور"
                            ref="password"

                            v-if="codeRequested"
                            v-model="password"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn class="primary" @click="nextStep">ثبت</v-btn>
                </v-col>
            </v-row>

        </v-window-item>

        <!--<v-window-item>-->
        <!--<v-fade-transition>-->
        <!--<v-btn class="primary" @click="proceed" :loading="saving" v-if="codeRequested">ذخیره</v-btn>-->
        <!--</v-fade-transition>-->
        <!--</v-window-item>-->
        <stop-watch v-if="codeRequested" :initialMinutes="counter.initialMinutes" ref="counter"
                    :initialSeconds="counter.initialSeconds" @countDown="changeCounter"
                    @counterStopped="timeIsUp"/>
    </v-window>


</template>

<script>
    import StopWatch from '@/components/StopWatch.vue'

    export default {
        props: ['saving', 'title'],
        components: {
            StopWatch,
        },
        data() {
            return {
                rules: {
                    required: value => !!value || 'اجباری',
                    length: value => (value || "").length == 6 || 'کد باید 6 رقم باشد',


                },
                requesting: false,
                step: 1,
                password: null,
                code: null,
                clockIsWorking: false,
                codeRequested: false,
                isSaving: false,
                canResend: false,
                resetCounter: {
                    minutes: 0,
                    seconds: 10,
                },
                counter: {
                    initialMinutes: 0,
                    initialSeconds: 10,

                    minutes: 0,
                    seconds: 10
                },
            }
        },
        methods: {
            nextStep() {
                switch (this.step) {
                    case 1:
                        this.requestVerification();
                        break;
                    case 2:
                        if (this.$refs.code.validate(true))
                            if (this.$store.getters.requiresPassword) {
                                this.step++;
                            } else {
                                this.proceed();
                                this.step = 1;
                            }
                        break;
                    case 3:
                        this.proceed();
                        this.step = 1;
                        break;
                }
            },
            proceed() {
                this.$emit('proceed', {
                    code: this.code,
                    password: this.password
                });
                this.code = null;
                this.password = null;
            },
            changeCounter(data) {
                this.counter = data;
            },
            timeIsUp() {
                this.canResend = true;
                this.clockIsWorking = false;
            },
            getRequestLabel() {
                if (this.clockIsWorking) {
                    return `${this.counter.minutes} ${this.counter.seconds}`;
                }

                return this.title || 'تایید';
            },
            requestVerification() {
                this.codeRequested = true;

                this.requesting = true;
                this.$store.dispatch('getVerificationCode').then(() => {
                    this.step = 2;
                    this.clockIsWorking = true;
                    this.restartCounter();
                }).finally(() => {
                    this.requesting = false;
                });

            },
            restartCounter() {
                this.canResend = false;
                this.clockIsWorking = true;
                this.$refs['counter'].reset(this.resetCounter);
            },
        },


    }
</script>

<style scoped>

</style>
