<template>
    <v-card color="grey lighten-5">
        <v-app-bar
                   color="white"
                   flat
                   shrink-on-scroll
                   prominent
                   scroll-target="#scrolling-techniques" height="auto">
            <v-list-item three-line>
                <v-list-item-avatar
                        v-if="!$vuetify.breakpoint.smAndDown"
                        tile
                        :size="$vuetify.breakpoint.smAndDown ? 50 : 80"
                        style="border-radius: 4px !important;"
                        color="grey"
                >
                    <v-img :src="user.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ user.username }}</v-list-item-title>
                    <v-list-item-subtitle class="mt-n6">{{ user.name }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn :small="$vuetify.breakpoint.smAndDown" color="secondary" min-height="50" class="ml-2 px-8">
                        کپی
                    </v-btn>
                    <v-btn min-width="40" min-height="50">
                        <v-icon :large="!$vuetify.breakpoint.smAndDown" color="accent">mdi-playlist-plus</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>

            <template v-slot:extension>
                <v-tabs
                        color="secondary"
                        v-model="model"
                        centered
                        slider-color="secondary"
                >
                    <v-tab
                            :class="'v-tab--active'"
                            v-for="(tab, $tabIndex) in tabs"
                            :key="$tabIndex"
                            :to="{ name: tab.to }"
                    >
                        {{ tab.name }}
                        <v-icon small color="secondary lighten-1" class="px-2">{{ tab.icon }}</v-icon>
                    </v-tab>
                </v-tabs>
            </template>
        </v-app-bar>

        <v-divider />

        <router-view></router-view>

<!--        <v-tabs-items v-model="model">-->
<!--            <v-tab-item-->
<!--                    v-for="(tab, $tabItemIndex) in tabs"-->
<!--                    :key="$tabItemIndex"-->
<!--                    :value="`tab-${$tabItemIndex}`"-->
<!--            >-->
<!--                <v-card flat>-->
<!--                    <router-view></router-view>-->
<!--                </v-card>-->
<!--            </v-tab-item>-->
<!--        </v-tabs-items>-->
    </v-card>
</template>

<script>
    export default {
        data () {
            return {
                model: '',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                tabs: [
                    {
                        name: 'پست ها',
                        icon: 'fas fa-folder-open',
                        to: 'traderFeed'
                    },
                    {
                        name: 'نمودار',
                        icon: 'fas fa-chart-bar',
                        to: 'traderStats'
                    },
                    {
                        name: 'سهام',
                        icon: 'fas fa-chart-pie',
                        to: 'traderPortfolio'
                    },
                    {
                        name: 'چارت',
                        icon: 'fas fa-chart-line',
                        to: 'traderChart'
                    }
                ],
                user1: {
                    avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
                    username: "Shayan",
                    name: "علی شایان"
                },
                user2: {
                    avatar: require('@/assets/trader.jpg'),
                    username: "Goodgoing",
                    name: "حسن معجونی"
                }
            }
        },
        computed: {
            user() {
                let person
                if(this.$route.path.includes('علی')){
                    person = this.user1
                }else {
                    person = this.user2
                }
                return person
            }
        }
    }
</script>
