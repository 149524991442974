<template>
    <v-navigation-drawer
            class="elevation-0"
            v-model="drawer"
            :class="{'mt-2':$vuetify.breakpoint.lgAndUp}"
            app
            :clipped="$vuetify.breakpoint.lgAndUp"
            :right="isRTL"
            :temporary="isTemp"
    >
        <v-list dense class="pt-0">
            <template v-if="!userIsLoggedIn">

                <v-list-item-group>

                    <v-list-item
                            link
                            :class="'home' === $route.name ? 'highlighted' : ''"
                            :to="{name:'home'}"
                    >

                        <v-list-item-content>

                            <v-list-item-title>
                                <v-row class="pa-0 ma-0" dense>
                                    <v-col cols="2">
                                        <v-icon min-width="150">mdi-home</v-icon>
                                    </v-col>
                                    <v-col class="mt-1">Home</v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name:'login'}" link
                                 :class="'login' === $route.name ? 'highlighted' : ''"
                    >
                        <v-list-item-content>

                            <v-list-item-title>
                                <v-row class="pa-0 ma-0" dense>
                                    <v-col cols="2">
                                        <v-icon min-width="150">mdi-login</v-icon>
                                    </v-col>
                                    <v-col class="mt-1">{{$t('loginText')}} | {{$t('signUpText')}}</v-col>
                                </v-row>
                            </v-list-item-title>

                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name:'professionalsignup'}" link
                                 :class="'customerExpertSignup' === $route.name ? 'highlighted' : ''"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row class="pa-0 ma-0" dense>
                                    <v-col cols="2">
                                        <v-icon min-width="150">fas fa-hands-helping</v-icon>
                                    </v-col>
                                    <v-col class="mt-1">{{$t('navBecomeAProfessional')}}</v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                </v-list-item-group>
            </template>
            <template v-else>
                <v-list-item two-line link :to="{name:'userInfo'}" v-if="profile!=null">
                    <v-list-item-avatar class="elevation-1">
                        <v-img :src="'http://betabours.ir:8080'+profile.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-if="$store.getters.userIs('real')">
                            {{profile.firstName + ' ' + profile.lastName}}
                        </v-list-item-title>
                        <v-list-item-title v-if="$store.getters.userIs('legal')">
                            {{profile.companyName}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <template v-for="item in items">
                    <template
                            v-if="$store.getters.userHasRoles(item.requiredRoles,item.requiredTypes)">
                        <v-list-group
                                class="mx-1"
                                v-if="item.children"
                                :key="item.text"
                                v-model="item.model"
                                :prepend-icon="'fas fa-inbox'"
                                :append-icon="item.icon"

                        >
                            <template v-slot:activator>
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t(item.text) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list-item
                                    v-for="(child, i) in item.children"
                                    :key="i"
                                    link
                                    class="mx-5"
                                    @click="gotoPage(child)"
                                    :class="child.route === $route.name ? 'highlighted' : ''"
                            >
                                <v-list-item-action v-if="child.icon">
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ child.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                                v-else
                                :key="item.text"
                                link
                                @click="gotoPage(item)"
                                :class="item.route === $route.name ? 'highlighted' : ''"

                        >

                            <v-list-item-content>

                                <v-list-item-title>
                                    <v-row class="pa-0 ma-0" dense>
                                        <v-col cols="2">
                                            <v-icon min-width="150">{{ item.icon }}</v-icon>
                                        </v-col>
                                        <v-col class="mt-1">{{$t(item.text) }}</v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </template>
            </template>
            <v-list-item v-if="this.$store.getters.getSupportedLanguages.length>1">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row class="pa-0 ma-0" dense>
                            <v-col cols="2">
                                <v-icon min-width="150">fas fa-globe</v-icon>
                            </v-col>
                            <v-col class="pt-0 mt-0">
                                <LanguageSelect isDrawer="true"></LanguageSelect>
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {EventBus} from "../event-bus";
    import LanguageSelect from '@/components/LanguageSelect'

    export default {

        components: {
            LanguageSelect
        },
        created() {
            EventBus.$on('profileUpdated', this.updateUserInfo);
            EventBus.$on('openNavigationDrawer', this.navToggle);
            this.updateUserInfo();
        },
        beforeDestroy() {
            EventBus.$off('profileUpdated', this.updateUserInfo);
        },
        mounted() {
            this.checkIfDrawerIsTemporary();
            this.profile = this.$store.getters.getUserProfile;
        },
        data() {
            return {
                profile: null,
                items: [

                    {
                        icon: 'home',
                        text: 'Home',
                        route: 'home',
                    },
                    {
                        icon: 'fas fa-user',
                        text: 'profileMenuUserInfo',
                        route: 'userInfo',
                        requiredRoles: ['client', 'trader']
                    },
                    {
                        icon: 'fas fa-building',
                        text: 'شرکت ها',
                        route: 'userCompanies',
                        requiredRoles: ['client', 'trader'],


                    },
                    {
                        icon: 'fas fa-th',
                        text: 'نمایندگی ها',
                        route: 'userAgencies',
                        requiredRoles: ['client', 'trader'],


                    },
                    {
                        icon: 'fas fa-layer-group',
                        text: 'مجامع من',
                        route: 'userAssemblies',
                        requiredRoles: ['client', 'trader'],
                    },
                    {
                        icon: 'fas fa-star',
                        text: 'معاملات رونویس',
                        route: 'userDiscoverPeople',
                        requiredRoles: ['client', 'trader'],
                    },
                    {
                        icon: 'fas fa-building',
                        text: 'سهامداران',
                        route: 'userShareHolders',
                        requiredRoles: ['client', 'trader'],
                        requiredTypes: ['legalClient', 'legalTrader']
                    },
                    {
                        icon: 'fas fa-hands-helping',
                        text: 'userBecomeATrader',
                        route: 'customerTraderSignUp',
                        requiredRoles: ['client']
                    },
                    {
                        icon: 'fas fa-vote-yea',
                        text: 'userGivenVotes',
                        route: 'userGivenVotes',
                        requiredRoles: ['client','trader']
                    },
                    {
                        icon: 'fas fa-poll-h',
                        text: 'userVoteRequest',
                        route: 'userVoteRequests',
                        requiredRoles: ['trader']
                    },

                    // {
                    //     icon: 'dashboard',
                    //     text: 'profileMenuUserDashboard',
                    //     route: 'userHome',
                    //     requiredRoles: ['client', 'trader']
                    // },

                    // {
                    //     icon: 'fas fa-list',
                    //     text: 'profileMenuUserOrders',
                    //     route: 'userOrders',
                    //     requiredRoles: ['client', 'trader']
                    // },
                    // {
                    //     icon: 'mdi-chevron-up',
                    //     'icon-alt': 'mdi-chevron-down',
                    //     text: 'profileMenuOrderRequests',
                    //     model: false,
                    //     requiredRoles: ['trader'],
                    //     children: [
                    //         {text: 'Accepted', route: 'acceptedRequests', icon: 'fas fa-check'},
                    //         {text: 'New', route: 'newRequests', icon: 'fas fa-clipboard-list'},
                    //         {text: 'Started', route: 'startedRequests', icon: 'fas fa-tasks'},
                    //     ],
                    // },
                    // {
                    //     icon: 'fas fa-random',
                    //     text: 'profileMenuOrderRequests',
                    //     route: 'requests',
                    //     requiredRoles: ['trader']
                    // },
                    // {
                    //     icon: 'fas fa-toolbox',
                    //     text: 'profileMenuAcceptedRequests',
                    //     route: 'acceptedRequests',
                    //     requiredRoles: ['trader']
                    // },
                    // {
                    //     icon: 'fas fa-credit-card',
                    //     text: 'profileMenuUserWallet',
                    //     route: 'userWallet',
                    //     requiredRoles: ['client', 'trader']
                    // },
                    // {
                    //     icon: 'mdi-tools',
                    //     text: 'profileMenuUserProfessions',
                    //     route: 'workerSkills',
                    //     requiredRoles: ['trader']
                    // },
                    // {
                    //     icon: 'mdi-map',
                    //     text: 'profileMenuUserAddresses',
                    //     route: 'userAddresses',
                    //     requiredRoles: ['client', 'trader']
                    // },

                    // {
                    //     icon: 'mdi-settings',
                    //     text: 'Settings',
                    //     route: 'settings',
                    //     requiredRoles: ['client']
                    // },
                    // {icon: 'mdi-message', text: 'Send feedback'},
                    // {icon: 'mdi-help-circle', text: 'Help'},
                    // {icon: 'mdi-cellphone-link', text: 'Download App'},
                    {
                        icon: 'mdi-logout',
                        text: 'signout',
                        route: 'signout'
                    },
                ],
                drawer: false,
                isRTL: this.$store.getters.isRTL,
                isTemp: false
            }
        },
        methods: {
            gotoPage(item) {
                if (item.route != undefined && item.route != 'signout') {
                    this.$router.push({name: item.route});
                } else if (item.route == 'signout') {
                    this.$store.dispatch('signOut').then(() => {
                        this.$router.push({name: 'home'});
                    });
                }
            },
            navToggle() {
                this.drawer = !this.drawer;
            },
            checkIfDrawerIsTemporary() {
                if (this.$route.path.includes('profile')) {
                    if (this.$vuetify.breakpoint.lgAndUp) {
                        this.drawer = true;
                    }

                    this.isTemp = false;
                } else {
                    this.isTemp = true;
                }
            },
            updateUserInfo() {
                this.profile = this.$store.getters.getUserProfile;

            },

        },
        computed: {
            userIsLoggedIn() {
                return this.$store.getters.userIsLoggedIn;
            },
            userIsReal() {
                return this.$store.getters.userIsReal;
            },
            languageIsChanged() {
                return this.$store.getters.isRTL
            }
        },
        watch: {
            languageIsChanged: function () {
                this.isRTL = this.$store.getters.isRTL;
            },
            '$route'() {
                this.checkIfDrawerIsTemporary();
            }
        }
    }
</script>

<style scoped>

    .highlighted {
        background-color: rgba(0, 0, 200, .1);
    }

</style>
