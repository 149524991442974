/*eslint-disable*/
import {ajaxPost, ajaxGet, LS} from '../../utilities.js';
import {EventBus} from "../../event-bus";

export default {
    // namespaced: true,
    state: {
        existingLanguages: [],
        messages: {},
        isRTL: false,
        default: 'fa',
        activeLanguageId: LS.get('activeLanguageId'),

        languages: [
            // {
            //     title: 'english',
            //     shortCode: 'en',
            //     direction: 'ltr'
            // },
            {
                title: 'فارسی',
                shortCode: 'fa',
                direction: 'rtl'
            }
        ],
        languageCodes: [
            'fa',
            // 'en'
        ],
        locale: 'fa',
        direction: localStorage.getItem('direction') || 'rtl'
    },
    mutations: {
        changeLocal(state, lang) {
            state.locale = lang;
            state.languages.forEach((item, index) => {
                if (item.shortCode == lang.toLowerCase()) {
                    state.direction = item.direction;
                    LS.set('direction', item.direction)
                }
            });
            LS.set('local', lang);
            EventBus.$emit('languageIsChanged');
        },


    },
    actions: {
        changeLocal({commit}, lang) {
            return new Promise((resolve) => {
                commit('changeLocal', lang);
                resolve(true);
            });
        },
    },
    getters: {
        getLocal: state => state.locale,
        isRTL: state => state.direction == 'rtl',
        getSupportedLanguages: state => state.languages,
        getActiveLanguageId: state => state.activeLanguageId,
    }
};

