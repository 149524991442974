import Axios from "axios";
import store from './store';
import {EventBus} from "./event-bus";

export function ajaxPost(url, payload = {}) {
    return new Promise((resolve, reject) => {

        Axios.post(url, payload, {
            headers: {
                Authorization: store.getters.getAccessToken
            }
        }).then(resp => {
            resolve(resp.data);
        }).catch((err) => {
            EventBus.$emit('notify', {
                visibility: true,
                color: 'red',
                position: 'bottom',
                timeout: 3000,
                mode: 'vertical',
                message: err.response.data.message[0].error,
                icon: 'fas fa-times'
            });
            reject(err);

        });
    });
}

export function ajaxGet(url) {
    store.commit('toggleLoading', true);

    return new Promise((resolve, reject) => {
        Axios.get(url, {
            headers: {
                Authorization: store.getters.getAccessToken
            }
        }).then(resp => {
            resolve(resp.data);
        }).catch((err) => {

            EventBus.$emit('notify', {
                visibility: true,
                color: 'red',
                position: 'bottom',
                timeout: 3000,
                mode: 'vertical',
                message: err.response.data.message[0].error,
                icon: 'fas fa-times'
            });
            reject(err);
        }).finally(() => {
            store.commit('toggleLoading', false);
        })
    });
}

// export const localizationSettings = {
//     defaultLanguage: localStorage.getItem('local') || 'fa'
// }


class LocalStorageAPI {
    get(key) {
        let item = localStorage.getItem(key);

        if (typeof item == "object")
            return JSON.parse(item);
        return item;
    }

    set(key, value) {
        let val = typeof value == 'string' ? value : JSON.stringify(value);
        localStorage.setItem(key, val);
    }

    check(key) {
        return localStorage.getItem(key) != null ? true : false;
    }

    getProp(obj, key) {
        if (localStorage.getItem(obj) != null) {
            return (localStorage.getItem(obj) != null) ? (JSON.parse(localStorage.getItem(obj))[key]) : '';
        } else {
            return null;
        }

    }

    remove(keys) {
        if (Array.isArray(keys)) {
            keys.forEach((item) => {
                localStorage.removeItem(item);
            })
        }

    }
}


export const LS = new LocalStorageAPI();


export function encodeImageFileAsURL(file) {
    return new Promise((resolve) => {
        var reader = new FileReader();
        reader.onloadend = function () {
            // return ;
            resolve(reader.result);
        };
        reader.readAsDataURL(file);
    });

}
