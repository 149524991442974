<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-fade-transition>
            <v-dialog
                    v-model="removeDialog"
                    v-if="itemToRemove !=null"
                    max-width="500"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا از حذف این آیتم اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>

                        <v-btn
                                color="secondary"
                                text
                                @click="removeDialog = false"
                        >
                            خیر
                        </v-btn>

                        <!--<v-btn-->
                        <!--color="primary"-->
                        <!--text-->
                        <!--@click="removeItem"-->
                        <!--&gt;-->
                        <!--بله-->
                        <!--</v-btn>-->
                        <SaveWithCode :title="'تایید'" @proceed="deleteShareHolder" :saving="isSaving"></SaveWithCode>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>

        <v-fade-transition>
            <v-dialog v-model="editingMode" v-if="itemToEdit != null" max-width="500">
                <v-card>
                    <v-card-title>
                    <span class="headline">
                        <span>ویرایش سهام دار</span>
                    </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="auto">
                                    <v-text-field
                                            v-model="itemToEdit.firstName"
                                            outlined
                                            dense
                                            label="نام"
                                    >

                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="auto">
                                    <v-text-field
                                            v-model="itemToEdit.lastName"
                                            outlined
                                            dense
                                            hide-details
                                            label="نام خانوادگی"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="red darken-1" text @click="cancelEditing">بستن</v-btn>
                        <SaveWithCode :title="'تایید'" @proceed="finishEditing" :saving="isSaving"></SaveWithCode>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>


        <v-card-title>
            سهامداران
            <v-spacer></v-spacer>

        </v-card-title>

        <hr>
        <v-container>

            <v-row>
                <v-col>
                    <v-expansion-panels
                            v-model="addPanel"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <v-icon small>mdi-plus</v-icon>
                                        افزودن سهامدار جدید
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="auto">
                                        <v-text-field
                                                v-model="shareHolderFirstName"
                                                outlined
                                                dense
                                                label="نام"
                                                ref="shareHolderFirstName"
                                        >

                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="auto">
                                        <v-text-field
                                                v-model="shareHolderLastName"
                                                ref="shareHolderLastName"
                                                outlined
                                                dense
                                                hide-details
                                                label="نام خانوادگی"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-card-actions>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="auto" lg="6" md="4">
                                            <!--<v-btn class="primary elevation-0" @click="addShareHolder">افزودن</v-btn>-->
                                            <SaveWithCode @proceed="addShareHolder" :title="'افزودن'"
                                                          :saving="isSaving"></SaveWithCode>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>


            <v-row>

                <v-col>
                    <v-data-table
                            :loading="loadingShares"
                            :headers="headers"
                            hide-default-footer
                            :items="shareHolders"

                    >
                        <!--<template v-slot:item.company.avatar="{item}">-->

                        <!--<v-avatar large class="elevation-0">-->
                        <!--<v-img-->
                        <!--:src="staticBaseURL + item.company.avatar"-->
                        <!--alt="Company-logo"-->
                        <!--contain-->
                        <!--&gt;-->
                        <!--</v-img>-->
                        <!--</v-avatar>-->

                        <!--</template>-->
                        <template v-slot:item.shareAmount="{item}">
                            <v-fade-transition mode="out-in">
                                <!--<v-col cols="auto" >-->
                                <!--<v-text-field class="pa-0 ma-0 text-center" v-model="editingItemShare"-->
                                <!--hide-details dense-->
                                <!--outlined></v-text-field>-->
                                <!--</v-col>-->
                                <span>{{item.shareAmount}}</span>
                            </v-fade-transition>

                        </template>

                        <template v-slot:item.actions="{item}">


                            <v-btn small @click="showRemoveDialog(item)" text>
                                <v-icon small color="red darken-1">fas fa-trash</v-icon>
                            </v-btn>
                            <v-btn small @click="editShareHolder(item)" text>
                                <v-icon small color="blue darken-1">fas fa-edit</v-icon>
                            </v-btn>

                            <!--<v-fade-transition mode="out-in">-->
                            <!---->
                            <!--<span v-else>-->
                            <!--<v-btn small v-if="editingItem" @click="finishEditing(item)" text>-->
                            <!--<v-icon small color="blue darken-1">fas fa-check</v-icon>-->
                            <!--</v-btn>-->
                            <!--<v-btn small v-if="editingMode" @click="cancelEditing" text>-->
                            <!--<v-icon small color="red darken-1">fas fa-times</v-icon>-->
                            <!--</v-btn>-->
                            <!--</span>-->

                            <!--</v-fade-transition>-->


                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <!--<v-pagination-->
                    <!--v-model="page"-->
                    <!--:length="length"-->
                    <!--:total-visible="totalVisible"-->
                    <!--&gt;</v-pagination>-->
                </v-col>
            </v-row>

        </v-container>
    </v-card>
</template>
<script>
    /*eslint-disable*/
    import {mapActions, mapGetters} from 'vuex';
    import SaveWithCode from '@/components/SaveWithCode.vue';
    import {EventBus} from "../../event-bus";


    export default {
        components: {
            SaveWithCode,
        },
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            }),

            headers() {
                return [
                    {
                        text: this.$t('userShareHoldersNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'firstName'
                    },
                    {
                        text: this.$t('userShareHoldersLastNameColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'lastName'
                    },

                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},


                ]
            }
        },
        data: () => ({
            loadingShares: false,
            editingMode: false,
            itemToEdit: null,
            removeDialog: false,
            itemToRemove: null,
            rules: {
                required: value => !!value || 'الزامی',
                email: value => {

                    if (value != undefined && value != '') {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'ایمیل نامعتبر'
                    } else {
                        return true;
                    }
                }
            },
            isSaving: false,
            shareHolders: [],
            ShareholderDialog: false,
            editingShareHolder: null,
            addPanel: null,
            shareHolderFirstName: "",
            shareHolderLastName: "",

            date: null,
            trip: {
                name: '',
                location: null,
                start: null,
                end: null,
            },
            locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Equador', 'France'],
        }),

        created() {
            this.getShares();
        },
        methods: {
            showRemoveDialog(item) {
                this.removeDialog = true;
                this.itemToRemove = item;
            },
            deleteShareHolder(verification) {
                let payload = {
                    deleteIdList: [
                        this.itemToRemove.id
                    ]
                };

                if (this.$store.getters.requiresPassword) {
                    payload.staticPassword = verification.password;
                }
                payload.code = verification.code;

                this.updateShareholders(payload).then(() => {
                    this.itemToRemove = null;
                    this.getShares();

                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت انجام شد',
                    });

                }).finally(() => {
                    this.isSaving = false;
                });

            },
            addShareHolder(verification) {
                // console.log("----------------------START-SELECTION----------------------");
                // console.log(this.$refs.shareHolderFirstName.validate(true));
                // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-SELECTION+-+-+-+-+-+-+-+-+-+-+-");
                // return;
                let payload = {
                    addList: [
                        {
                            firstName: this.shareHolderFirstName,
                            lastName: this.shareHolderLastName
                        }
                    ]
                };

                if (this.$store.getters.requiresPassword) {
                    payload.staticPassword = verification.password;
                }
                payload.code = verification.code;


                this.isSaving = true;

                this.updateShareholders(payload).then(() => {
                    this.shareHolderFirstName = null;
                    this.shareHolderLastName = null;
                    this.getShares();
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت انجام شد',
                    });
                }).finally(() => {
                    this.isSaving = false;
                });
            },
            finishEditing(verification) {
                // console.log("----------------------START-SELECTION----------------------");
                // console.log(this.$refs.shareHolderFirstName.validate(true));
                // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-SELECTION+-+-+-+-+-+-+-+-+-+-+-");
                // console.log("--------------------this.itemToEdit--------------------");
                // console.log(this.itemToEdit);
                // console.log("--------------------this.itemToEdit--------------------");
                // return;
                let payload = {
                    editList: [
                        {
                            id: this.itemToEdit.id,
                            firstName: this.itemToEdit.firstName,
                            lastName: this.itemToEdit.lastName
                        }
                    ]
                };

                if (this.$store.getters.requiresPassword) {
                    payload.staticPassword = verification.password;
                }
                payload.code = verification.code;


                this.isSaving = true;

                this.updateShareholders(payload).then(() => {
                    this.editingMode = false;
                    this.itemToEdit = null;
                    this.getShares();
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت انجام شد',
                    });
                }).finally(() => {
                    this.isSaving = false;
                });
            },
            cancelEditing() {
                this.editingMode = false;
                this.itemToEdit = null;
            },
            editShareHolder(item) {

                this.itemToEdit = item;
                this.editingMode = true;
            },
            getShares() {
                this.loadingShares = true;
                this.getShareHolders().then((resp) => {
                    this.shareHolders = resp.result;
                    console.log(resp);

                }).finally(() => {
                    this.loadingShares = false;
                });
            },

            ...mapActions({
                getShareHolders: 'getShareHolders',
                updateShareholders: 'updateShareholders'
            })

        },
        watch: {}
    }

</script>

<style>

</style>