<template>
    <v-menu
            :close-on-content-click="false"
            bottom
            left
            min-width="300"
            max-width="300"
            nudge-left="12"
            offset-y
            transition="slide-y-transition"
    >
        <template v-slot:activator="{ attrs, on }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="elevation-0"
                    color="grey"
                    dark
                    fab
                    small
            >
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-container grid-list-xl>
                <v-layout>
                    <v-row class="px-5">
                        <v-col cols="12" md="12" sm="12">
                            <v-switch :input-value="selectPassword" :value="selectPassword" @change="$emit('changeVerificationType', selectPassword)" class="text-right">
                                <template v-slot:label>
                                    <small>استفده از رمز عبور و رمز یک بار مصرف</small>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" md="12" align="center" v-if="selectPassword">
                            <v-text-field
                                    v-model="profile.password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    label="رمز عبور فعلی"
                                    class="input-group--focused"
                                    @click:append="showPassword = !showPassword"
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="profile.staticPassword"
                                    :append-icon="showStaticPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showStaticPassword ? 'text' : 'password'"
                                    label="رمز عبور جدید"
                                    class="input-group--focused"
                                    @click:append="showStaticPassword = !showStaticPassword"
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="profile.confirmStaticPassword"
                                    :append-icon="showConfirmStaticPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showConfirmStaticPassword ? 'text' : 'password'"
                                    label="تکرار رمز عبور جدید"
                                    class="input-group--focused"
                                    @click:append="showConfirmStaticPassword = !showConfirmStaticPassword"
                                    outlined
                            ></v-text-field>
                            <v-card-text v-if="warning" class="red--text">رمز عبور با تکرار آن همخوانی ندارد</v-card-text>
                        </v-col>
                        <v-col md="12" sm="12" align="center">
                            <SaveWithCode @proceed="setPassword" :saving="isSaving"></SaveWithCode>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>
        </v-card>
    </v-menu>
</template>

<script>
    import SaveWithCode from "./SaveWithCode";
    export default {
        props: ['profile', 'selectPassword'],
        components: {
            SaveWithCode
        },
        data: () => ({
            showStaticPassword: false,
            showPassword: false,
            showConfirmStaticPassword: false,
            isSaving: false,
            warning: false
        }),

        computed: {},

        methods: {
            setPassword(verification) {
                if(this.selectPassword) {
                    if(this.profile.confirmStaticPassword === this.profile.staticPassword) {
                        this.$emit('setPassword', verification)
                        this.warning = false
                    }else {
                        this.warning = true
                    }
                } else {
                    this.$emit('setPassword', verification)
                }
            }
        }
    }
</script>

<style lang="scss"></style>
