<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>

    export default {
        name: 'App',
        components: {},
        data: () => ({}),
    };
</script>
<style>
    @font-face {
        font-family: 'WeblogmaYekan';
        src: url('../src/assets/fonts/WeblogmaYekan.eot');
        src: url('../src/assets/fonts/WeblogmaYekan.eot?#iefix') format('embedded-opentype'),
        url('../src/assets/fonts/WeblogmaYekan.woff2') format('woff2'),
        url('../src/assets/fonts/WeblogmaYekan.woff') format('woff'),
        url('../src/assets/fonts/WeblogmaYekan.ttf') format('truetype'),
        url('../src/assets/fonts/WeblogmaYekan.svg#WeblogmaYekan') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'WeblogmaYekan';
        src: url('../src/assets/fonts/WeblogmaYekan_1.eot');
        src: url('../src/assets/fonts/WeblogmaYekan_1.eot?#iefix') format('embedded-opentype'),
        url('../src/assets/fonts/WeblogmaYekan_1.woff2') format('woff2'),
        url('../src/assets/fonts/WeblogmaYekan_1.woff') format('woff'),
        url('../src/assets/fonts/WeblogmaYekan_1.ttf') format('truetype'),
        url('../src/assets/fonts/WeblogmaYekan_1.svg#WeblogmaYekan') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'YekanWeb';
        src: url('../src/assets/fonts/YekanWeb-Regular.eot');
        src: url('../src/assets/fonts/YekanWeb-Regular.eot?#iefix') format('embedded-opentype'),
        url('../src/assets/fonts/YekanWeb-Regular.woff2') format('woff2'),
        url('../src/assets/fonts/YekanWeb-Regular.woff') format('woff'),
        url('../src/assets/fonts/YekanWeb-Regular.ttf') format('truetype'),
        url('../src/assets/fonts/YekanWeb-Regular.svg#YekanWeb-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    * {
        font-family: YekanWeb, "Roboto", sans-serif;

    }
</style>