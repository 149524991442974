<template>
    <div v-if="languages.length>1">
        <template v-if="!isDrawer">
            <!--:open-on-hover="!isDrawer"-->
            <v-menu offset-y v-model="value">
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            text
                            class="pa-0 mx-0"
                            small
                            color="primary"
                    >
                        <!--<img :src="activeLanguage.img" alt="" style="width:40px">-->
                        <!--<span v-if="isDrawer" color="primary">{{activeLanguage.title}}</span>-->
                        <span>{{activeLanguage.shortCode}}</span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            v-for="(language, index) in languages"
                            :key="index"
                            @click="changeLanguage(language)">
                        <v-list-item-title class="primary--text">{{ language.title }}</v-list-item-title>

                    </v-list-item>
                </v-list>

            </v-menu>
        </template>
        <template v-else>
            <v-select
                    class="pt-0 mt-0"
                    :items="languages"
                    item-text="title"
                    @change="changeLanguage(selectedLanguage)"
                    return-object
                    v-model="selectedLanguage"
                    dense
            ></v-select>

        </template>
    </div>
</template>
<script>
    export default {
        props: ['isDrawer'],
        data() {
            return {
                value: false,
                path: this.$route.name,
                languages: [],
                activeLanguage: "",
                selectedLanguage: null
            };
        },
        computed: {
            languageIsChanged() {
                return this.$store.getters.getActiveLanguageId
            }
        },
        watch: {
            languageIsChanged: function () {
                this.setDefaultLanguage();
            }
        },
        methods: {
            changeLanguage(item) {
                console.log('changing');
                this.value = false;
                this.activeLanguage = item;
                this.selectedLanguage = this.activeLanguage;
                // this.$store.dispatch('changeLocal', item.shortCode);
                this.$router.push({
                    name: this.$route.name, params: {lang: item.shortCode}
                })
            },
            setDefaultLanguage() {
                this.languages.filter((language) => {
                    if (language.shortCode == this.$store.getters.getLocal) {
                        this.activeLanguage = language;
                        this.selectedLanguage = language;
                    }
                });
            }
        },
        mounted() {

            this.setDefaultLanguage();
        },
        created() {

            this.languages = this.$store.getters.getSupportedLanguages;
        }

    }
</script>
<style>

</style>