import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import i18n from './i18n'

Vue.config.productionTip = false

store.dispatch('getNewToken').finally(() => {
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app')

});
