<template>

    <v-card
            width="100%"
            class="elevation-2"
    >

        <v-window v-model="currentWindow">
            <v-window-item :value="1">
                <v-card-title>
                    مجامع شرکت های من
                    <v-spacer></v-spacer>
                    <!--<v-btn small @click="showAddCompany = true" class="primary elevation-0">-->
                    <!--<v-icon small>mdi-plus</v-icon>-->
                    <!--افزودن شرکت جدید-->
                    <!--</v-btn>-->
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <!--<v-row>-->
                    <!--<v-col>-->
                    <!--<v-fade-transition>-->
                    <!--<AddCompany @companyAdded="getCompanies"></AddCompany>-->
                    <!--</v-fade-transition>-->

                    <!--</v-col>-->
                    <!--</v-row>-->

                    <v-row>
                        <v-col>
                            <v-data-table
                                    :headers="headers"
                                    hide-default-footer
                                    :items="userAssemblies"
                            >
                                <template v-slot:item.company.avatar="{item}">

                                    <v-avatar large class="elevation-0">
                                        <v-img :src="'http://betabours.ir:8080'+item.companyBaseInfo.avatar"
                                               contain
                                        >
                                        </v-img>
                                    </v-avatar>

                                </template>

                                <template v-slot:item.startDateTime="{item}">
                                    {{item.startDateTime | toJalali}}
                                </template>
                                <template v-slot:item.endDateTime="{item}">
                                    {{item.endDateTime | toJalali}}
                                </template>


                                <template v-slot:item.actions="{item}">


                                    <v-btn small text @click="voteForAssembly(item)">
                                        <v-icon color="primary">fas fa-vote-yea</v-icon>
                                    </v-btn>


                                    <!--<v-fade-transition mode="out-in">-->
                                    <!---->
                                    <!--<span v-else>-->
                                    <!--<v-btn small v-if="editingItem" @click="finishEditing(item)" text>-->
                                    <!--<v-icon small color="blue darken-1">fas fa-check</v-icon>-->
                                    <!--</v-btn>-->
                                    <!--<v-btn small v-if="editingMode" @click="cancelEditing" text>-->
                                    <!--<v-icon small color="red darken-1">fas fa-times</v-icon>-->
                                    <!--</v-btn>-->
                                    <!--</span>-->

                                    <!--</v-fade-transition>-->


                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <!--<v-pagination-->
                            <!--v-model="page"-->
                            <!--:length="length"-->
                            <!--:total-visible="totalVisible"-->
                            <!--&gt;</v-pagination>-->
                        </v-col>
                    </v-row>

                </v-container>
            </v-window-item>
            <v-window-item :value="2">
                <v-card>
                    <v-card-title>
                        <v-btn text class="primary" @click="currentWindow = 1">
                            <v-icon small class="mx-1">fas fa-long-arrow-alt-right</v-icon>
                            بازگشت
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>

                        <UserVote v-if="selectedAssembly!=null" :assembly="selectedAssembly"
                                  @finished="currentWindow = 1"></UserVote>
                    </v-card-text>
                </v-card>
            </v-window-item>
        </v-window>


        <v-fade-transition>
            <v-dialog
                    v-model="removeItemDialog"
                    v-if="itemToRemove!=null"
                    max-width="400"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا از حذف این آیتم اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="secondary"
                                text
                                @click="removeItemDialog = false"
                        >
                            خیر
                        </v-btn>

                        <v-btn
                                color="primary"
                                text
                                @click="removeItem"
                        >
                            بله
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>
        <v-fade-transition>
            <v-dialog v-model="editingMode" v-if="editingItem != null" max-width="600px">
                <v-card>
                    <v-card-title>
                    <span class="headline">
                        <span>ویرایش سهام</span>
                    </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" class="pa-0 ma-0 text-center">
                                    <v-avatar size="80" class="elevation-1">
                                        <v-img

                                                :src="staticBaseURL + editingItem.company.avatar"
                                                alt="Company-logo"
                                                contain
                                        >
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    نام شرکت : {{editingItem.company.name}}
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span>میزان سهام: </span>
                                    <span>{{editingItem.shareAmount}}</span>

                                </v-col>
                                <v-col cols="6" class="text-center">
                                    <v-text-field v-model="editingItemShare" outlined
                                                  label="میزان سهام جدید"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="finishEditing">ذخیره</v-btn>
                        <v-btn color="red darken-1" text @click="cancelEditing">بستن</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>


    </v-card>
</template>
<script>
    /*eslint-disable*/
    import {mapActions, mapGetters} from 'vuex';
    import moment from 'moment-jalali';
    import AddCompany from '@/components/AddCompany'
    import UserVote from '@/components/UserVote.vue';


    export default {
        components: {AddCompany, UserVote},
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            }),

            headers() {
                return [
                    {
                        text: this.$t('userAssembliesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'companyBaseInfo.name'
                    },
                    {
                        text: this.$t('userAssembliesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'company.avatar'
                    },
                    {
                        text: this.$t('userAssemblesTableAssemblyNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'title'
                    },
                    {
                        text: this.$t('userAssemblesTableStartDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'startDateTime'
                    },
                    {
                        text: this.$t('userAssemblesTableEndDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'endDateTime'
                    },
                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},


                ]
            }
        },
        data: () => ({
            currentWindow: 1,
            selectedAssembly: null,
            showAssemblyVoteDialog: false,
            userAssemblies: [],
            showAddCompany: false,
            page: null,
            length: null,
            totalVisible: 7,
            editingItemShare: "",
            editingMode: false,
            editingItem: null,
            removeItemDialog: false,
            itemToRemove: null,
            moment: null

        }),
        created() {

            this.getAssemblies();
            this.moment = moment;
        },
        methods: {
            voteForAssembly(item) {
                this.selectedAssembly = item;
                this.showAssemblyVoteDialog = true;
                this.currentWindow = 2;

                // this.$store.dispatch('getTraders', {voteId: item.id}).then((resp) => {
                //     console.log(resp);
                //
                // })
            },
            removeItem() {

                this.removeUserCompany({id: this.itemToRemove.company.id}).then(() => {
                    this.itemToRemove = null;
                    this.getAssemblies();
                }).finally(() => {
                    this.removeItemDialog = false;
                })
            },
            getAssemblies() {
                this.getUserAssemblies().then((resp) => {
                    this.userAssemblies = resp.result;
                    console.log("--------------------resp--------------------");
                    console.log(resp);
                    console.log("--------------------resp--------------------");
                });
            },
            cancelEditing() {
                this.editingMode = false;
                this.editingItem = null;
            },
            removeCompany(item) {
                this.itemToRemove = item;
                this.removeItemDialog = true;

            },
            finishEditing() {

                this.addCompanyShare({
                    id: this.editingItem.company.id,
                    shareAmount: this.editingItemShare
                }).then(() => {
                    this.editingItem.shareAmount = this.editingItemShare;
                }).finally(() => {
                    this.cancelEditing();
                });
            },
            editCompanyShare(item) {
                console.log("--------------------item--------------------");
                console.log(item);
                console.log("--------------------item--------------------");
                this.editingItem = item;
                this.editingItemShare = item.shareAmount;
                this.editingMode = true;

            },
            ...mapActions({
                getUserCompanies: 'getUserCompanies',
                getCompaniesList: 'searchCompanies',
                addCompanyShare: 'addCompanyShare',
                removeUserCompany: 'removeUserCompany',
                getUserAssemblies: 'getUserAssemblies'
            }),

            search() {
                clearTimeout(this.searchHandler);

                if (this.searchInput != null && this.searchInput.length >= 3) {
                    let data = {
                        "name": this.searchInput,
                        "pageNumber": 1,
                        "pageSize": 20
                    };
                    this.loadingList = true;
                    this.searchHandler = setTimeout(() => {

                        this.getCompaniesList(data).then((resp) => {
                            this.companiesList = [];
                            this.companiesList = resp.result.data;
                        }).finally(() => {
                            this.loadingList = false;
                        });
                    }, 1000);

                } else {
                    this.loadingList = false;
                }
            }
        },
        watch: {
            searchInput() {
                this.search();
            }
        },
        filters: {
            toJalali(input) {
                return moment(input).format('HH:MM - jYYYY/jMM/jDD');
            }
        }
    }

</script>

<style>

</style>