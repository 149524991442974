<template>
    <div>

        <!--        تماس با ما-->
        <!--        اطلاعات قانونی-->
        <!--        بیانیه حریم خصوصی داده ها-->
        <!--        شرایط و ضوابط-->
        <!--        سلب مسئولیت محتوا-->
        <!--        اطلاعات بیمه-->
        <!--        بیمه حفاظت از نصب-->
        <!--        درباره ما-->
        <!--        درباره اسپیدو-->
        <!--        تبدیل به شریک خدمات شوید-->
        <!--        فرصت های شغلی-->
        <!--        کمک-->
        <!--        سوالات متداول-->
        <!--        چگونگی عضویت-->
        <!--        چرا باید عضو شوم؟-->

        <v-card
                flat
                tile
                dark
                class=""
        >
            <v-container>
                <v-row>
                    <v-col cols="12" lg="3" md="3" sm="6">

                        <v-card-title class="white--text font-weight-light">{{$t('footerContact')}}</v-card-title>
                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                            <div class="mt-3">
                                <v-icon class="mx-1">phone</v-icon>
                                <span>09038474311</span>
                            </div>
                            <div class="mt-3">
                                <v-icon class="mx-1">location_city</v-icon>
                                <span>Sat. - Thu. 8-18</span>
                            </div>
                            <div class="mt-3">
                                <v-icon class="mx-1">email</v-icon>
                                <span>info@betabours.ir</span>
                            </div>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" lg="3" md="3" sm="6">
                        <v-card-title class="white--text font-weight-light">{{$t('footerLegalNotice')}}</v-card-title>
                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                            <div class="mt-3">
                                <div class="mt-3">{{$t('footerContentDataPrivacyStatement')}}</div>
                                <div class="mt-3">{{$t('footerContentTermsAndConditions')}}</div>
                                <div class="mt-3">{{$t('footerContentDisclaimer')}}</div>
                                <div class="mt-3">{{$t('footerContentInsuranceInformation')}}</div>
                                <div class="mt-3">{{$t('footerContentInstallationProtectionInsurance')}}</div>
                            </div>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" lg="3" md="3" sm="6">
                        <v-card-title class="white--text font-weight-light">{{$t('footerAboutUs')}}</v-card-title>
                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                            <div class="mt-3">
                                <div class="mt-3">{{$t('footerContentAboutStockExchange')}}</div>
<!--                                <div class="mt-3">{{$t('footerContentBecomeAServicePartner')}}</div>-->
                                <div class="mt-3">{{$t('footerContentJobs')}}</div>
                                <div class="mt-3">{{$t('footerContentHelp')}}</div>
                            </div>
                        </v-card-text>
                    </v-col>

                    <v-col cols="12" lg="3" md="3" sm="6">
                        <v-card-title class="white--text font-weight-light">{{$t('footerQuestions')}}</v-card-title>
                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                            <div class="mt-3">
                                <div class="mt-3">چگونگی عضویت</div>
                                <div class="mt-3">چرا باید عضو شویم؟</div>
<!--                                <div class="mt-3">More...</div>-->
                            </div>
                        </v-card-text>
                    </v-col>

                </v-row>
            </v-container>

            <v-card-text class="text-center">
                <v-btn
                        v-for="icon in icons"
                        :key="icon"
                        class="mx-4 white--text text-center justify-center"
                        icon
                >
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </v-card-text>


            <v-divider></v-divider>

            <v-card-text class="white--text text-center">
                {{ new Date().getFullYear() }}
                <span class="mx-1">—</span>
                <strong class="mx-1">BetaBours</strong>
            <v-icon small>far fa-copyright</v-icon>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    export default {
        data: () => ({
            icons: [
                'fab fa-facebook',
                'fab fa-twitter',
                'fab fa-google-plus',
                'fab fa-linkedin',
                'fab fa-instagram',
            ],
        }),
    }
</script>
<style>

</style>
