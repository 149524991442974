/*eslint-disable*/
import {ajaxPost, ajaxGet, LS} from '../../utilities.js';
import {EventBus} from "../../event-bus";

export default {
    // namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getTraders({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + 'traders', payload);
        }

    },
    getters: {}
};