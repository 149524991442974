<template>
    <v-card class="elevation-0 transparent"
    >
        <v-window v-model="step">
            <v-window-item :value="1">
                <v-card-title class="title font-weight-regular justify-space-between primary--text">
                    <span>
                        {{title}}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form"
                            v-on:submit.prevent="loginProgress"

                    >
                        <v-container class="pa-0 ma-0">
                            <v-row>
                                <v-col cols="4" class="ma-0 pa-1">
                                    <v-combobox
                                            v-model="country"
                                            :items="countries"
                                            item-text="title"
                                            :label="$t('loginComponentCodeTextFieldLabel')"
                                            :filter="phoneFilter"
                                    >
                                        <template v-slot:selection="{item}">
                                            <span class="ma-0 ">{{ item.shortCode }}</span>
                                            <span class="ma-1 ">{{ item.code }}</span>
                                        </template>
                                        <template v-slot:item="{item}">
                                            <span class="mx-1 caption ">{{ item.code }}</span>
                                            <span class="mx-1 caption ">{{item.title | minify}}</span>
                                        </template>
                                    </v-combobox>
                                    <!--<v-select-->
                                    <!--v-model="country"-->
                                    <!--:items="countries"-->
                                    <!--label="Code"-->
                                    <!--item-text="title"-->
                                    <!--item-value="id"-->
                                    <!--return-object-->
                                    <!--&gt;-->

                                    <!--<template v-slot:item="{item,index}">-->
                                    <!--&lt;!&ndash;<v-img class="mx-1" :src="'https://www.speido.com:8443'+item.icon"&ndash;&gt;-->
                                    <!--&lt;!&ndash;max-width="30" width="30" max-height="30"></v-img>&ndash;&gt;-->
                                    <!--<span class="mx-2" width="30">{{item.code}}</span>-->
                                    <!--<span>{{item.title}}</span>-->
                                    <!--</template>-->
                                    <!--<template v-slot:selection="{item,index}">-->
                                    <!--&lt;!&ndash;<v-img class="mx-1" :src="'https://www.speido.com:8443'+item.icon"&ndash;&gt;-->
                                    <!--&lt;!&ndash;max-width="30" max-height="30"></v-img>&ndash;&gt;-->
                                    <!--&lt;!&ndash;<v-chip class="pa-1 mx-0">{{item.shortCode}}</v-chip>&ndash;&gt;-->
                                    <!--<span>{{item.code}}</span>-->
                                    <!--</template>-->

                                    <!--</v-select>-->

                                </v-col>
                                <v-col cols="8" class="primary--text ma-0 pa-1">
                                    <v-text-field
                                            :label="$t('loginComponentPhoneTextFieldLabel')"
                                            type="mobile"
                                            v-model="mobileNumber"
                                            :rules="rules"
                                            color="primary"

                                    >
                                    </v-text-field>

                                </v-col>
                            </v-row>
                        </v-container>


                    </v-form>
                    <!--<span class="caption grey&#45;&#45;text text&#45;&#45;darken-1">-->
                    <!--This Is Your Phone Number-->
                    <!--</span>-->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            depressed
                            @click.prevent="loginProgress"
                            :loading="btnLoading"
                            ref="submitCode"
                    >{{buttonText}}

                        <v-icon v-if="loginIsSuccessfull" class="green--text text--lighten-1 px-1 px1-shadow">fas
                            fa-check-circle
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-window-item>

            <v-window-item :value="2">
                <v-card-title class="title font-weight-regular justify-space-between">
                    کد ارسالی را وارد نمایید
                    <v-card-text class="normal-break py-0 pt-1">
                        {{$t('loginComponentSmsVerifyText')}}
                        <span v-if="this.country!=null">{{this.country.code}}</span>{{this.mobileNumber}}.
                    </v-card-text>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="loginProgress">

                        <v-row class="justify-center px-md-1 mx-md-1 text-center px-sm-1 mx-sm-1 ltr">

                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part1)" solo
                                              @focus="code.part1.value=null" ref="part1"
                                              v-model="code.part1.value"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part2)" solo
                                              @focus="code.part2.value=null" ref="part2"
                                              v-model="code.part2.value"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part3)" solo
                                              @focus="code.part3.value=null" ref="part3"
                                              v-model="code.part3.value"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part4)" solo
                                              @focus="code.part4.value=null" ref="part4"
                                              v-model="code.part4.value"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part5)" solo
                                              @focus="code.part5.value=null" ref="part5"
                                              v-model="code.part5.value"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-1 pa-sm-1 py-0 ma-0">
                                <v-text-field class="centered-input" @input="counIt(code.part6)" solo
                                              @focus="code.part6.value=null" ref="part6"
                                              v-model="code.part6.value"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    depressed
                                    @click.prevent="loginProgress"
                                    :loading="btnLoading"
                                    type="submit"
                                    ref="submitCode"
                            >{{buttonText}}

                                <v-icon v-if="loginIsSuccessfull" class="green--text text--lighten-1 px-1 px1-shadow">
                                    fas
                                    fa-check-circle
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                    <v-row class="justify-center">
                        <v-col class="text-center pa-0 ma-0">

                            <stop-watch :initialMinutes="counter.initialMinutes" ref="counter"
                                        :initialSeconds="counter.initialSeconds" @countDown="changeCounter"
                                        @counterStopped="timeIsUp"></stop-watch>

                            <span v-if="clockIsWorking">
                                {{counter.minutes}} : {{counter.seconds}}
                            </span>

                        </v-col>
                    </v-row>
                    <v-row class="justify-center" v-if="canResend">

                        <v-btn
                                :disabled="step === 1"
                                text
                                @click="step--"
                                small
                        >
                            <v-icon class="mx-1" small>
                                fas fa-long-arrow-alt-left
                            </v-icon>
                            change number
                        </v-btn>


                        <v-btn text small @click.prevent="restartCounter">
                            <v-icon class="mx-1" small>
                                refresh
                            </v-icon>
                            resend sms code
                        </v-btn>
                    </v-row>

                </v-card-text>

            </v-window-item>

            <!--<v-window-item :value="3">-->
            <!--<div class="pa-4 text-center">-->
            <!--<v-img-->
            <!--class="mb-4"-->
            <!--contain-->
            <!--height="128"-->
            <!--src="https://cdn.vuetifyjs.com/images/logos/v.svg"-->
            <!--&gt;</v-img>-->
            <!--<h3 class="title font-weight-light mb-2">Welcome to Vuetify</h3>-->
            <!--<span class="caption grey&#45;&#45;text">Thanks for signing up!</span>-->
            <!--</div>-->
            <!--</v-window-item>-->
        </v-window>

        <!--<v-divider></v-divider>-->

        <v-card-actions>


            <v-spacer></v-spacer>


        </v-card-actions>
    </v-card>
</template>
<script>
    import StopWatch from '@/components/StopWatch.vue'
    import {EventBus} from '@/../src/event-bus.js';
    // import {countriesCodes} from '@/../src/statics/countriesCodes.js';


    export default {
        props: ['title', 'redirect'],
        components: {
            StopWatch
        },
        data() {
            return {
                canResend: false,
                clockIsWorking: true,
                resetCounter: {
                    minutes: 0,
                    seconds: 10,
                },
                counter: {
                    initialMinutes: 0,
                    initialSeconds: 10,

                    minutes: 0,
                    seconds: 10
                },
                mobileNumber: null,
                btnLoading: false,
                step: 1,
                code: {
                    part1: {
                        value: null,
                        id: 1
                    },
                    part2: {
                        value: null,
                        id: 2
                    },
                    part3: {
                        value: null,
                        id: 3
                    },
                    part4: {
                        value: null,
                        id: 4
                    },
                    part5: {
                        value: null,
                        id: 5
                    },
                    part6: {
                        value: null,
                        id: 6
                    },
                },
                btnText: "login",
                loginIsSuccessfull: false,
                rules: [
                    value => !!value || 'Required.',
                    value => (value || '').length <= 20 || 'Max 20 characters',
                ],
                countries: [
                    // {
                    //     id: 1,
                    //     title: "Germany",
                    //     icon: "/images/uploads/countries/country_1575407719947_7393762050462672762.png",
                    //     code: "+49",
                    //     shortCode: 'DE'
                    //
                    // },
                    // {
                    //     id: 2,
                    //     title: "England",
                    //     icon: "/images/uploads/countries/country_1575407523097_-3398607184804789926.png",
                    //     code: "+44",
                    //     shortCode: 'UK'
                    //
                    // },
                    // {
                    //     id: 3,
                    //     title: "United States",
                    //     icon: "/images/uploads/countries/country_1575407306794_2778586798516925805.png",
                    //     code: "+1",
                    //     shortCode: 'US'
                    // },
                    // {
                    //     id: 4,
                    //     title: "Iran",
                    //     icon: "/images/uploads/countries/country_1575407306794_2778586798516925805.png",
                    //     code: "+98",
                    //     shortCode: 'IR'
                    // }
                ],
                country: {shortCode: 'IR',title: 'IRAN, ISLAMIC REPUBLIC OF',code: '+98'}
                // items: ['foo', 'bar', 'fizz', 'buzz', 'fizzbuzz', 'foobar'],
                // selectval: ['foo', 'bar', 'fizz'],

            };
        },
        created() {

            // this.countries = countriesCodes;
            this.countries = [{shortCode: 'IR',title: 'IRAN, ISLAMIC REPUBLIC OF',code: '+98'}];
            // console.log("----------------------START-this.$store.getters.getDefaultCountry----------------------");
            // let defaultCountry = this.$store.getters.getDefaultCountry;
            // if (defaultCountry != null) {
            //     this.countries.map((country) => {
            //         if (country.id == defaultCountry.id) {
            //             this.country = country;
            //         }
            //     });
            // }
            // console.log(this.countries);
            // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-this.$store.getters.getDefaultCountry+-+-+-+-+-+-+-+-+-+-+-");
            // this.$store.dispatch('getCountries').then((resp) => {
            //     console.log(resp);
            //     this.countries = resp;
            // });
        },

        computed: {
            currentTitle() {
                switch (this.step) {
                    case 1:
                        return 'Log in'
                    case 2: {
                        return 'Enter SMS Code'
                    }
                    default:
                        return 'Account created'
                }
            },
            buttonText() {

                let x;
                if (this.step == 1) {
                    x = this.$t('loginText');
                } else if (this.step == 2) {
                    x = this.$t('loginComponentSubmitSmsCodeBtn');
                }

                return x;
            }
        },
        methods: {
            counIt(item) {
                if (item.value.length == 1) {

                    if (item.id != 6) {
                        let num = (item.id) + 1;
                        let next = 'part' + String(num)
                        this.$nextTick(() => this.$refs[next].focus())
                    }
                }
            },
            loginProgress() {

                if (this.step == 1) {

                    if (this.mobileNumber != null && this.mobileNumber != "" && this.country != null) {
                        this.btnLoading = true;

                        let payload = {
                            mobile: this.country.code + this.mobileNumber,
                            userType: 1,
                            cookieMode: false
                        };

                        this.$store.dispatch('requestLoginCode', payload).then(resp => {
                            console.log(resp);
                            this.btnLoading = false;

                            this.step++;
                        }).catch(err => {
                            console.log(err);
                            this.btnLoading = false;
                        });

                    } else {
                        EventBus.$emit('notif', 'red', undefined, 'لطفا اطلاعات لازم را وارد نمایید');
                    }


                } else if (this.step == 2) {

                    let allGood = true;
                    let code = "";
                    for (let part in this.code) {

                        if (this.code[part].value == "" || isNaN(this.code[part].value)) {
                            allGood = false;
                        } else {
                            code += String(this.code[part].value);
                        }
                    }

                    if (allGood) {
                        this.btnLoading = true;

                        let payload = {
                            mobile: this.country.code + this.mobileNumber,
                            code
                        };

                        this.$store.dispatch('sendSmsCode', payload).then(() => {



                            // let data = {
                            //     languageId: this.$store.getters.getActiveLanguageId,
                            //     countryId: this.$store.getters.getDefaultCountry.id
                            // };
                            // this.$store.dispatch('saveLocationInfo', data).then((resp) => {
                            //     console.log(resp);
                            // });

                            this.$store.dispatch('getProfile').then((resp) => {
                                console.log(resp);
                                EventBus.$emit('loggedInSuccessfully');
                                this.btnLoading = false;
                                this.loginIsSuccessfull = true;
                                this.btnText = "Successful";

                                if (this.redirect) {
                                    // this.$router.push({name: 'userHome'});
                                    this.$router.push({name: 'userInfo'});
                                }

                            });
                            // if (this.redirect) {
                            //     this.$router.push({name: 'userHome'});
                            // }


                        }).catch((err) => {
                            console.log(err);
                            this.btnLoading = false;
                        });
                    } else {
                        EventBus.$emit('notif', 'red', undefined, 'لطفا اطلاعات لازم را وارد نمایید');
                    }

                }

            },
            changeCounter(data) {
                this.counter = data;
            },
            timeIsUp() {
                this.canResend = true;
                this.clockIsWorking = false;
            },
            restartCounter() {
                this.canResend = false;
                this.clockIsWorking = true;
                this.$refs['counter'].reset(this.resetCounter);
            },
            phoneFilter(item, queryText, itemText) {


                if (item.header) {
                    return false;
                }

                const hasValue = val => val != null ? val : '';

                const text = hasValue(itemText);
                const code = hasValue(item.code);
                const query = hasValue(queryText);

                return text.toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
            },
        },
        watch: {
            step() {
                if (this.step == 1) {
                    this.btnText = 'Login';
                }
                if (this.step == 2) {

                    this.btnText = 'Submit Code';

                    for (let part in this.code) {
                        this.code[part].value = "";
                    }

                    this.$nextTick(function () {
                        if (typeof this.$refs.counter != 'undefined') {
                            this.restartCounter();
                        }
                    });
                }
            }
        },
        filters: {
            minify(value) {
                if (value.length > 20) {
                    return value.substr(0, 20) + '...';
                }
                else {
                    return value;
                }
            }
        }
    }
</script>
<style>
    .centered-input input {
        text-align: center
    }

    .normal-break {
        word-break: normal;
    }

    .px1-shadow {
        text-shadow: 0 0 5px rgba(0, 0, 0, .3);
    }

    .ltr {
        direction: ltr;
    }
</style>
