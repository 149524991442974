<template>
    <v-card flat class="charts pt-12">
        <vue-highcharts class="flex-grow-1" :options="areaOptions" ref="areaCharts"></vue-highcharts>
    </v-card>
</template>
<script>
    import VueHighcharts from "vue2-highcharts";
    import * as data from "@/assets/data.js";

    export default {
        components: {
            VueHighcharts
        },
        data() {
            return {
                areaOptions: data.AreaData
            };
        },
        mounted() {},
        methods: {}
    };
</script>

<style>
    .charts {
        min-height: 65vh;
    }
</style>
