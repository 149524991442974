<template>
    <v-card>
        <v-card-actions>
            <v-card-title>تنظیمات</v-card-title>
            <v-spacer></v-spacer>
        </v-card-actions>
        <hr>
        <v-container class="pa-6">
            <v-row>
                <v-col cols="12">


                </v-col>
            </v-row>
            <v-row>

            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";

    export default {
        data: () => ({
            profile: null,
        }),
        created() {
            this.profile = this.$store.getters.getUserProfile;
            console.log(this.profile);
        },
        watch: {},
        methods: {},
    }
</script>

<style scoped>

</style>