<template>
    <v-card flat fluid class="px-0">
        <v-row class="px-2" align="center" justify="space-between">
            <v-card-title>برترین ها</v-card-title>
        </v-row>
        <PeopleSlider />
        <v-row class="px-2" align="center" justify="space-between">
            <v-card-title>دارای بیشترین کپی کننده</v-card-title>
            <v-btn text color="blue">مشاهده همه</v-btn>
        </v-row>
        <v-divider class="ma-3" />
        <v-container  v-if="!$vuetify.breakpoint.smAndDown">
            <v-row>
                <v-col cols="auto" v-for="i in itemsNum" :key="i" @click="$router.push({ path: '/fa/profile/people/علی_شایان' })" style="cursor: pointer">
                    <IntroTraderCard />
                </v-col>
            </v-row>
        </v-container>
        <v-row class="px-0" v-else>
            <v-col class="pa-0" sm="12" v-for="i in 4" :key="i" @click="$router.push({ path: '/fa/profile/people/علی_شایان' })">
                <IntroTraderList />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import PeopleSlider from "@/components/CopyPeople/PeopleSlider"
import IntroTraderCard from "@/components/CopyPeople/IntroTraderCard"
import IntroTraderList from "@/components/CopyPeople/IntroTraderList"
export default {
    components: {
        PeopleSlider,
        IntroTraderCard,
        IntroTraderList
    },
    computed: {
        itemsNum() {
            return this.$vuetify.breakpoint.lgOnly ? 4 : 3
        }
    }
}
</script>

<style>

</style>
