<template>
    <v-parallax
            dark
            :height="imageViewHeight"
            style="background-color: #074974"
    >
        <!--<v-overlay absolute z-index="-10" opacity=".35"></v-overlay>-->

        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <h1 class="display-1 text-center font-weight-bold mb-4">
                        <span>
                            {{$t('siteTitle')}}
                        </span>
                    </h1>
                    <h4 class="subheading text-center">{{$t('siteDesc')}}</h4>
                </v-col>
            </v-row>
            <v-row class="justify-center">
                <v-col cols="12" lg="6">
                    <v-card class=" white--text transparent elevation-0">
                        <v-row class="justify-center">
                            <v-col cols="12" lg="10">
                                <v-autocomplete
                                        :items="items"
                                        :loading="isLoading"
                                        clearable
                                        hide-details
                                        hide-selected
                                        item-text="name"
                                        item-value="phoneNumber"
                                        :label="$t('homeSearchPlaceholderText')"
                                        :search-input.sync="search"
                                        solo
                                        flat
                                        class="elevation-3"
                                        loader-height="2"
                                        prepend-inner-icon="search"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-title>
                                                بدون نتایج
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                        <v-list-item-content @click="gotoCompanyPage(item)">

                                            <v-list-item-title v-text="item.name">

                                            </v-list-item-title>


                                        </v-list-item-content>
                                    </template>
                                    <!--<template v-slot:item="{ item }">-->

                                    <!--<v-list-item-content @click="gotoCompanyPage(item)">-->
                                    <!--<v-list-item-title v-text="item.valueTranslated"></v-list-item-title>-->
                                    <!--<v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>-->
                                    <!--</v-list-item-content>-->
                                    <!--<v-list-item-action>-->
                                    <!--<v-icon>view</v-icon>-->
                                    <!--</v-list-item-action>-->
                                    <!--</template>-->
                                </v-autocomplete>
                            </v-col>
                            <!---->
                            <!--<v-col cols="12" lg="4">-->
                            <!--<v-menu offset-y class="pa-0">-->
                            <!--<template v-slot:activator="{ on }">-->
                            <!--<v-btn-->
                            <!--color="text&#45;&#45;white"-->

                            <!--v-on="on"-->
                            <!--class="pt-4 pb-8"-->
                            <!--&gt;-->
                            <!--GERMANY-->
                            <!--</v-btn>-->
                            <!--</template>-->
                            <!--<v-list>-->
                            <!--<v-list-item-->
                            <!--v-for="(item, index) in countries"-->
                            <!--:key="index"-->

                            <!--&gt;-->
                            <!--<v-list-item-title>{{ item.title }}</v-list-item-title>-->
                            <!--</v-list-item>-->
                            <!--</v-list>-->
                            <!--</v-menu>-->
                            <!--</v-col>-->

                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

    </v-parallax>
</template>
<script>
    import {EventBus} from "../event-bus";

    export default {
        data() {
            return {
                isLoading: false,
                items: [],
                model: null,
                search: null,
                tab: null,
                countries: [
                    {title: 'Germany'},
                    {title: 'France'},
                    {title: 'United States'},
                ],
                imageViewHeight: window.innerHeight - 380,
                searchTimer: null
            }
        },

        watch: {
            model(val) {
                if (val != null) this.tab = 0
                else this.tab = null
            },
            search() {

                // Items have already been loaded
                // if (this.items.length > 0) return;

                if (this.search.length >= 3) {
                    clearTimeout(this.searchTimer);

                    let data = {
                        "name": this.search,
                        "pageNumber": 1,
                        "pageSize": 20
                    };

                    this.searchTimer = setTimeout(() => {

                        this.isLoading = true;
                        this.$store.dispatch('searchCompanies', data).then(resp => {
                            console.log(resp.result.data);
                            console.log('filling');

                            this.$nextTick(() => {
                                this.items = resp.result.data;
                            });

                        }).finally(() => {
                            this.isLoading = false;
                        });
                    }, 500)

                }


                // Lazily load input items
                // console.log(val);
                // fetch('https://api.coinmarketcap.com/v2/listings/')
                //     .then(res => res.json())
                //     .then(res => {
                //         this.items = res.data;
                //         console.log(res);
                //
                //         setTimeout(function () {
                //             console.log(res);
                //         }, 3000);
                //
                //     })
                //     .catch(err => {
                //         console.log(err)
                //     })
                //     .finally(() => (this.isLoading = false))
            },
        },
        methods: {
            gotoCompanyPage(item) {

                console.log(item);
                this.$router.push({
                    name: 'company',
                    params: {code: item.id, name: item.slug}
                });
            },
            myEventHandler() {
                let x = window.innerHeight - 380;
                if (x < 350) {
                    this.imageViewHeight = 350;
                }
                else {
                    this.imageViewHeight = x;
                }

            },
            getAllServices() {
                // this.$store.dispatch('getAllServices').then(resp => {
                //     this.items = resp;
                // });
            }
        },
        created() {

            this.getAllServices();
            EventBus.$on('localeIsChanged', this.getAllServices);

            window.addEventListener("resize", this.myEventHandler);

        },

        beforeDestroy() {
            window.removeEventListener("resize", this.myEventHandler);
            EventBus.$off('localeIsChanged', this.getAllServices);
        }

    }
</script>
<style>
    .home-parallax {
        -webkit-overflow-scrolling: touch;
        -webkit-overflow: touch;
        touch-action: auto;
    }
</style>
