<template>
    <v-hover v-slot:default="{  }">
        <v-card class="cursor-pointer">
            <v-img
                    height="100%"
                    src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                    class="gradient-image"
            >
                <v-row
                        align="end"
                        class="fill-height"
                >
                    <v-col
                            align-self="start"
                            class="pa-0"
                            cols="12"
                    >
                        <v-avatar
                                class="profile ma-5 mx-10 mt-5 mb-0 elevation-10"
                                color="grey"
                                size="80"
                        >
                            <v-img
                                    :src="$store.getters.getStaticBaseURL+trader.avatar"
                                    lazy-src="https://speido.com:8443/images/defaults/user/default-user.png"
                            ></v-img>
                        </v-avatar>

                    </v-col>

                    <v-col>
                        <v-list-item
                                color="rgba(0, 0, 0, .4)"
                                class="py-0"
                                dark
                        >
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="title">
                                    <span>{{trader.profileInformation.firstName}} {{trader.profileInformation.lastName}}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>


                </v-row>
            </v-img>
        </v-card>
    </v-hover>
</template>

<script>
    export default {
        props: ['trader'],
    }
</script>

<style scoped>

</style>