<template>
    <v-card class="elevation-0">

        <v-card-title>
            <span class="title">
                <span>افزودن شرکت</span>
            </span>
        </v-card-title>
        <v-card-text>

            <v-autocomplete
                    :items="companiesList"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="phoneNumber"
                    :label="$t('enterCompanyNameToAddLabel')"
                    :search-input.sync="searchInput"
                    :loading="loadingList"
                    solo
                    flat
                    class="elevation-1"
                    loader-height="2"
                    prepend-inner-icon="search"
                    v-model="companyToAdd"
                    return-object
            >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            بدون نتایج
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>

        </v-card-text>
        <v-fade-transition>
            <v-card-text v-if="companyToAdd != null">
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-col>مشخصات شرکت</v-col>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>

                        <v-row>
                            <v-col sm="3" cols="12">
                                <v-avatar large>
                                    <img
                                            :src="staticBaseURL + companyToAdd.avatar"
                                            alt="Company-logo"
                                    >
                                </v-avatar>
                            </v-col>
                            <v-col sm="3" cols="12">
                                <span>نام :</span>
                                {{companyToAdd.name}}

                            </v-col>
                            <v-col sm="3" cols="12">
                                <span>شماره تماس:</span>
                                {{companyToAdd.phoneNumber}}
                            </v-col>

                            <v-col sm="3" cols="12">
                                <v-text-field
                                        v-model="percentOfCompany"
                                        label="میزان سهم شما"
                                        outlined
                                        dense
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="addCompany" class="primary elevation-0">ذخیره</v-btn>
                                <v-btn @click="companyToAdd = null" class="elevation-0 mx-1" color="red" text>بستن
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-card-text>
        </v-fade-transition>

    </v-card>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        data: () => ({
            companiesList: [],
            searchInput: "",
            searchHandler: null,
            loadingList: false,
            companyToAdd: null,
            percentOfCompany: ""
        }),
        methods: {
            ...mapActions({
                searchCompanies: 'searchCompanies',
                addCompanyShare: 'addCompanyShare'

            }),
            search() {
                clearTimeout(this.searchHandler);
                this.percentOfCompany = 0;

                if (this.searchInput != null && this.searchInput.length >= 3) {
                    let data = {
                        "name": this.searchInput,
                        "pageNumber": 1,
                        "pageSize": 20
                    };
                    this.loadingList = true;
                    this.searchHandler = setTimeout(() => {

                        this.searchCompanies(data).then((resp) => {
                            this.companiesList = [];
                            this.companiesList = resp.result.data;
                        }).finally(() => {
                            this.loadingList = false;
                        });
                    }, 1000);

                } else {
                    this.loadingList = false;
                }
            },
            addCompany() {
                if (this.companyToAdd != null) {

                    let payload = {
                        id: this.companyToAdd.id,
                        "shareAmount": this.percentOfCompany
                    };

                    this.addCompanyShare(payload).then((resp) => {
                        console.log(resp);
                    }).then(() => {
                        this.companyToAdd = null;
                        this.$emit('companyAdded');
                    });

                }


            }
        },
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            })
        },
        watch: {
            searchInput() {
                if (this.companyToAdd != null) {
                    if (this.companyToAdd.name != this.searchInput) {
                        this.search();
                    }
                } else {
                    this.search();
                }
            }
        }
    }
</script>

<style scoped>

</style>