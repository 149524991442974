<template>
    <v-container>
       <v-row>
           <v-col cols="12" md="8" sm="12">
               <TraderPost></TraderPost>
               <TraderPost></TraderPost>
           </v-col>
           <v-col md="4">
                <TraderProfileCard></TraderProfileCard>
               <TopTraded class="mt-8"></TopTraded>

               <v-card class="mt-8">
                   <v-row>
                       <v-col class="pa-8 d-flex align-center flex-column">
                           <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-bold black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                       </v-col>
                       <v-col class="pa-8 d-flex align-center flex-column">
                           <v-card-subtitle class="py-0"><br><h2 class="mb-2 font-weight-bold black--text">12581</h2>کپی کنندگان</v-card-subtitle>
                       </v-col>
                   </v-row>
                   <v-card-subtitle class="d-flex justify-center">
                       <v-icon small color="success">fas fa-arrow-up</v-icon>
                        <span class="success--text ml-2 mr-1 font-weight-bold">
                            1245 کپی کننده
                        </span>
                        <span>
                            در 7 روز اخیر
                        </span>
                   </v-card-subtitle>
               </v-card>

               <SimilarTraders class="mt-8"></SimilarTraders>
           </v-col>
       </v-row>
    </v-container>
</template>

<script>
    import TraderPost from '@/components/CopyPeople/TraderPost'
    import TraderProfileCard from '@/components/CopyPeople/TraderProfileCard'
    import TopTraded from '@/components/CopyPeople/TopTraded'
    import SimilarTraders from '@/components/CopyPeople/SimilarTraders'
export default {
    components: {
        TraderPost,
        TraderProfileCard,
        TopTraded,
        SimilarTraders
    }
}
</script>
