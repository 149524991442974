<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-fade-transition>
            <v-dialog
                    v-model="removeItemDialog"
                    v-if="itemToRemove!=null"
                    max-width="400"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا از حذف این آیتم اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="secondary"
                                text
                                @click="removeItemDialog = false"
                        >
                            خیر
                        </v-btn>

                        <v-btn
                                color="primary"
                                text
                                @click="removeItem"
                        >
                            بله
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>
        <v-fade-transition>
            <v-dialog v-model="editingMode" v-if="editingItem != null" max-width="600px">
                <v-card>
                    <v-card-title>
                    <span class="headline">
                        <span>ویرایش سهام</span>
                    </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" class="pa-0 ma-0 text-center">
                                    <v-avatar size="80" class="elevation-1">
                                        <v-img
                                                :src="staticBaseURL + editingItem.company.avatar"
                                                alt="Company-logo"
                                                contain
                                        ></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    نام شرکت : {{editingItem.company.name}}
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span>میزان سهام: </span>
                                    <span>{{editingItem.shareAmount}}</span>

                                </v-col>
                                <v-col cols="6" class="text-center">
                                    <v-text-field v-model="editingItemShare" outlined
                                                  label="میزان سهام جدید"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="finishEditing">ذخیره</v-btn>
                        <v-btn color="red darken-1" text @click="cancelEditing">بستن</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>

        <v-card-title>
            شرکت های من
            <v-spacer></v-spacer>
            <!--<v-btn small @click="showAddCompany = true" class="primary elevation-0">-->
            <!--<v-icon small>mdi-plus</v-icon>-->
            <!--افزودن شرکت جدید-->
            <!--</v-btn>-->
        </v-card-title>

        <v-divider></v-divider>
        <v-container>
            <v-row>
                <v-col>
                    <v-fade-transition>
                        <AddCompany @companyAdded="getCompanies"></AddCompany>
                    </v-fade-transition>

                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="userCompanies"
                    >
                        <template v-slot:item.company.avatar="{item}">

                            <v-avatar large class="elevation-0">
                                <v-img
                                        :src="staticBaseURL + item.company.avatar"
                                        alt="Company-logo"
                                        contain
                                >
                                </v-img>
                            </v-avatar>

                        </template>
                        <template v-slot:item.shareAmount="{item}">
                            <v-fade-transition mode="out-in">
                                <!--<v-col cols="auto" >-->
                                <!--<v-text-field class="pa-0 ma-0 text-center" v-model="editingItemShare"-->
                                <!--hide-details dense-->
                                <!--outlined></v-text-field>-->
                                <!--</v-col>-->
                                <span>{{item.shareAmount}}</span>
                            </v-fade-transition>

                        </template>

                        <template v-slot:item.actions="{item}">


                            <v-btn small @click="removeCompany(item)" text>
                                <v-icon small color="red darken-1">fas fa-trash</v-icon>
                            </v-btn>
                            <v-btn small @click="editCompanyShare(item)" text>
                                <v-icon small color="blue darken-1">fas fa-edit</v-icon>
                            </v-btn>

                            <!--<v-fade-transition mode="out-in">-->
                            <!---->
                            <!--<span v-else>-->
                            <!--<v-btn small v-if="editingItem" @click="finishEditing(item)" text>-->
                            <!--<v-icon small color="blue darken-1">fas fa-check</v-icon>-->
                            <!--</v-btn>-->
                            <!--<v-btn small v-if="editingMode" @click="cancelEditing" text>-->
                            <!--<v-icon small color="red darken-1">fas fa-times</v-icon>-->
                            <!--</v-btn>-->
                            <!--</span>-->

                            <!--</v-fade-transition>-->


                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <!--<v-pagination-->
                    <!--v-model="page"-->
                    <!--:length="length"-->
                    <!--:total-visible="totalVisible"-->
                    <!--&gt;</v-pagination>-->
                </v-col>
            </v-row>

        </v-container>
    </v-card>
</template>
<script>

    import {mapActions, mapGetters} from 'vuex';
    import AddCompany from '@/components/AddCompany'
    import {EventBus} from "../../event-bus";


    export default {
        components: {AddCompany},
        computed: {
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            }),

            headers() {
                return [
                    {
                        text: this.$t('userCompaniesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'company.name'
                    },
                    {
                        text: this.$t('userCompaniesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'company.avatar'
                    },
                    {
                        text: this.$t('userCompaniesTableShareColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'shareAmount'
                    },
                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},


                ]
            }
        },
        data: () => ({
            userCompanies: [],
            showAddCompany: false,
            page: null,
            length: null,
            totalVisible: 7,
            editingItemShare: "",
            editingMode: false,
            editingItem: null,
            removeItemDialog: false,
            itemToRemove: null

        }),
        created() {
            this.getCompanies();
        },
        methods: {
            removeItem() {

                this.removeUserCompany({id: this.itemToRemove.company.id}).then(() => {
                    this.itemToRemove = null;
                    this.getCompanies();
                }).finally(() => {
                    this.removeItemDialog = false;
                })
            },
            getCompanies() {
                this.getUserCompanies().then((resp) => {
                    this.userCompanies = resp.result;
                });
            },
            cancelEditing() {
                this.editingMode = false;
                this.editingItem = null;
            },
            removeCompany(item) {
                this.itemToRemove = item;
                this.removeItemDialog = true;

            },
            finishEditing() {

                this.addCompanyShare({
                    id: this.editingItem.company.id,
                    shareAmount: this.editingItemShare
                }).then(() => {
                    this.editingItem.shareAmount = this.editingItemShare;

                    EventBus.$emit('notif', 'green');
                }).catch(() => {
                    // EventBus.$emit('notif','red', undefined, 'message');
                }).finally(() => {

                    this.cancelEditing();
                });
            },
            editCompanyShare(item) {
                console.log("--------------------item--------------------");
                console.log(item);
                console.log("--------------------item--------------------");
                this.editingItem = item;
                this.editingItemShare = item.shareAmount;
                this.editingMode = true;

            },
            ...mapActions({
                getUserCompanies: 'getUserCompanies',
                getCompaniesList: 'searchCompanies',
                addCompanyShare: 'addCompanyShare',
                removeUserCompany: 'removeUserCompany'
            }),

            search() {
                clearTimeout(this.searchHandler);

                if (this.searchInput != null && this.searchInput.length >= 3) {
                    let data = {
                        "name": this.searchInput,
                        "pageNumber": 1,
                        "pageSize": 20
                    };
                    this.loadingList = true;
                    this.searchHandler = setTimeout(() => {

                        this.getCompaniesList(data).then((resp) => {
                            this.companiesList = [];
                            this.companiesList = resp.result.data;
                        }).finally(() => {
                            this.loadingList = false;
                        });
                    }, 1000);

                } else {
                    this.loadingList = false;
                }
            }
        },
        watch: {
            searchInput() {
                this.search();
            }
        }
    }

</script>

<style>

</style>