<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-card-title>
            اطلاعات کاربری
            <v-spacer></v-spacer>
            <Setting :profile="profile"
                     :select-password="selectStaticPassword"
                     @setPassword="saveProfile"
                     @changeVerificationType="selectStaticPassword = !selectStaticPassword"
            />
            <!--<v-menu offset-y>-->
            <!--<template v-slot:activator="{ on }">-->
            <!--<v-btn-->
            <!--color="primary"-->
            <!--dark-->
            <!--v-on="on"-->
            <!--class="elevation-0"-->
            <!--small-->
            <!--&gt;-->
            <!--&lt;!&ndash;invitation code: {{profile.code}}&ndash;&gt;-->
            <!--</v-btn>-->
            <!--</template>-->
            <!--<v-list>-->
            <!--<v-list-item>-->
            <!--<v-list-item-title>Send via</v-list-item-title>-->

            <!--</v-list-item>-->
            <!--<v-divider></v-divider>-->
            <!--<v-list-item-->
            <!--v-for="(item, index) in sendCode"-->
            <!--:key="index"-->
            <!--&gt;-->
            <!--<v-list-item-title>-->
            <!--<a :href="item.title=='email' ? emailLink : messageLink">-->
            <!--{{ item.title }}-->
            <!--</a>-->
            <!--</v-list-item-title>-->
            <!--</v-list-item>-->
            <!--</v-list>-->
            <!--</v-menu>-->
        </v-card-title>
        <hr>

        <v-container>

            <v-row class="justify-center center align-center text-center justify-center">
                <v-col cols="12" lg="12" class="text-center">

                    <v-fade-transition>
                        <v-avatar width="150" height="150" class="elevation-2">
                            <v-img
                                    :src="avatarFile || 'http://betabours.ir:8080'+profile.avatar"
                                    lazy-src="http://betabours.ir:8080/images/defaults/user/default-user.png"
                                    aspect-ratio="1"
                                    max-width="150"
                                    max-height="150"
                            ></v-img>
                        </v-avatar>
                    </v-fade-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="">
                    <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="انتخاب آواتار"
                            prepend-icon="mdi-camera"
                            label="آواتار"
                            outlined
                            @change="onFileChange('avatar',$event)"
                    ></v-file-input>
                </v-col>
            </v-row>
            <template v-if="profile.legalPersonality == null && !loading">
                <v-row>

                    <v-col cols="12">
                        <v-card-text class="pa-0 font-weight-bold">
                            نوع حساب
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row class="px-10">
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                                v-model="legalPersonality"
                                :items="legalPersonalities"
                                item-text="title"
                                item-value="value"
                                label="نوع حساب"
                                hint="تنها یک بار توانایی ثبت این گزینه را دارید"
                                persistent-hint
                                @change="resetForm"
                                outlined
                        ></v-select>
                    </v-col>
                </v-row>
            </template>

            <template v-if="isReal || isLegal">

                <v-fade-transition>
                    <v-row>
                        <v-col cols="12">
                            <v-card-text class="pa-0 font-weight-bold">
                                اطلاعات پایه
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-fade-transition>

                <v-row class="px-10">
                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    label="نام"
                                    ref="firstName"

                                    v-model="profile.firstName"

                            ></v-text-field>
                        </v-col>
                    </v-fade-transition>
                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    ref="lastName"
                                    label="نام خانوادگی"

                                    v-model="profile.lastName"
                            ></v-text-field>
                        </v-col>

                    </v-fade-transition>

                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-select
                                    v-model="profile.sex"
                                    :items="genders"
                                    item-text="text"
                                    item-value="value"
                                    label="جنسیت"
                                    outlined
                            ></v-select>
                        </v-col>
                    </v-fade-transition>


                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                outlined
                                ref="stockCode"
                                v-model="profile.stockCode"
                                label="کد بورسی"

                        ></v-text-field>
                    </v-col>

                    <!--<v-col cols="12" sm="6" md="4">-->
                    <!--<v-text-field-->
                    <!--outlined-->
                    <!--label="E-mail"-->
                    <!--&gt;</v-text-field>-->
                    <!--</v-col>-->


                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    ref="nationalCode"
                                    v-model="profile.nationalCode"
                                    label="کد ملی"

                            ></v-text-field>
                        </v-col>
                    </v-fade-transition>


                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    ref="nationalCode"
                                    v-model="profile.birthCertificateRegisterLocation"
                                    label="محل صدور شناسنامه"

                            ></v-text-field>
                        </v-col>
                    </v-fade-transition>


                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    ref="nationalCode"
                                    v-model="profile.birthCertificateId"
                                    label="شماره شناسنامه"

                            ></v-text-field>
                        </v-col>
                    </v-fade-transition>


                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <!--<v-date-picker-->
                            <!--outlined-->
                            <!--ref="nationalCode"-->
                            <!--v-model="profile.birthDay"-->
                            <!--label="تاریخ تولد"-->
                            <!--&gt;-->

                            <!--</v-date-picker>-->
                            <!--<DatePicker v-model="profile.birthDay" class=""></DatePicker>-->

                            <v-text-field
                                    outlined
                                    :value="jalaliBirthday"
                                    id="my-custom-input"
                                    label="تاریخ تولد"

                            >

                            </v-text-field>

                            <date-picker
                                    :max="today"
                                    v-model="profile.birthDay"
                                    format="YYYY-MM-DD"
                                    element="my-custom-input"
                                    view="year"
                                    :show="datePickerVisibility"
                            />
                        </v-col>
                    </v-fade-transition>


                    <v-fade-transition>
                        <v-col cols="12" sm="6" md="4" v-if="isReal">
                            <v-text-field
                                    outlined
                                    ref="nationalCode"
                                    v-model="profile.fatherName"
                                    label="نام پدر"

                            ></v-text-field>
                        </v-col>
                    </v-fade-transition>


                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card-text class="pa-0 font-weight-bold">
                            اطلاعات تماس
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row class="px-10">
                    <!--<v-col cols="12" sm="6" md="4">-->
                    <!--<v-text-field-->
                    <!--outlined-->
                    <!--label="تلفن همراه"-->
                    <!--v-model="profile.mobile"-->
                    <!--disabled-->

                    <!--&gt;</v-text-field>-->
                    <!--</v-col>-->


                    <v-col cols="12" sm="6" md="4" v-if="isReal || isLegal">
                        <v-text-field
                                outlined
                                ref="email"
                                label="ایمیل"
                                v-model="profile.email"
                                :rules="[rules.email]"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" v-if="isLegal">
                        <v-text-field
                                outlined
                                ref="phoneNumber"
                                label="تلفن ثابت"
                                v-model="profile.phoneNumber"

                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card-text class="pa-0 font-weight-bold">
                            اطلاعات مکانی
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="4" v-if="isReal || isLegal">
                        <v-text-field
                                outlined
                                ref="email"
                                label="کد پستی"
                                v-model="profile.postalCode"

                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                outlined
                                label="آدرس"
                                v-model="profile.address"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-fade-transition>
                    <v-row v-if="isLegal">
                        <v-col cols="12">
                            <v-card-text class="pa-0 font-weight-bold">
                                اطلاعات شرکت
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-fade-transition>
                <v-fade-transition>
                    <v-row class="px-10" v-if="isLegal">
                        <v-col cols="12" sm="6" md="4" v-if="isLegal">
                            <v-text-field
                                    outlined
                                    ref="nationalId"
                                    v-model="profile.nationalId"
                                    label="نشان ملی شرکت"

                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-if="isLegal">
                            <v-text-field
                                    outlined
                                    v-model="profile.registrationCode"
                                    ref="registrationCode"
                                    label="کد ثبتی شرکت"

                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" sm="6" md="4" v-if="isLegal">
                            <v-text-field
                                    outlined
                                    ref="tableManager"
                                    v-model="profile.tableManager"

                                    label="رئیس هیات مدیره"

                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" v-if="isLegal">
                            <v-text-field
                                    outlined
                                    ref="companyName"
                                    v-model="profile.companyName"
                                    label="نام شرکت"

                            ></v-text-field>

                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    outlined
                                    v-model="profile.ceo"
                                    ref="ceo"

                                    label="مدیرعامل"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                    outlined
                                    ref="address"
                                    v-model="profile.address"
                                    label="آدرس"

                            ></v-text-field>

                        </v-col>

                    </v-row>
                </v-fade-transition>

                <!--                <v-fade-transition>-->
                <!--                <v-row class="px-10">-->
                <!--                    <v-col cols="12" md="4" sm="12">-->
                <!--                        <v-switch v-model="selectStaticPassword">-->
                <!--                            <template v-slot:label>-->
                <!--                                جهت امنیت بیشتر یک پسورد انتخاب کنید-->
                <!--                            </template>-->
                <!--                        </v-switch>-->
                <!--                    </v-col>-->
                <!--                    <v-col cols="12" md="4" v-if="selectStaticPassword">-->
                <!--                        <v-text-field-->
                <!--                                v-model="profile.staticPassword"-->
                <!--                                :append-icon="showStaticPassword ? 'mdi-eye' : 'mdi-eye-off'"-->
                <!--                                :rules="[rules.required]"-->
                <!--                                :type="showStaticPassword ? 'text' : 'password'"-->
                <!--                                label="رمز عبور"-->
                <!--                                hint="حداقل ۸ کاراکتر"-->
                <!--                                class="input-group&#45;&#45;focused"-->
                <!--                                @click:append="showStaticPassword = !showStaticPassword"-->
                <!--                                outlined-->
                <!--                        ></v-text-field>-->
                <!--                    </v-col>-->
                <!--                    <v-col cols="12" md="4" sm="12" v-if="selectStaticPassword">-->
                <!--                        <v-text-field-->
                <!--                                v-model="profile.staticPassword"-->
                <!--                                :append-icon="showStaticPassword ? 'mdi-eye' : 'mdi-eye-off'"-->
                <!--                                :rules="[rules.required]"-->
                <!--                                :type="showStaticPassword ? 'text' : 'password'"-->
                <!--                                label="رمز عبور"-->
                <!--                                hint="حداقل ۸ کاراکتر"-->
                <!--                                class="input-group&#45;&#45;focused"-->
                <!--                                @click:append="showStaticPassword = !showStaticPassword"-->
                <!--                                outlined-->
                <!--                        ></v-text-field>-->
                <!--                    </v-col>-->
                <!--                </v-row>-->
                <!--            </v-fade-transition>-->

                <v-fade-transition>
                    <v-row class="px-10" v-if="isLegal">

                        <v-col>
                            <v-col cols="12">
                                <v-card-text class="pa-0 font-weight-bold">
                                    توضیحات
                                </v-card-text>
                            </v-col>

                            <v-card class="elevation-0">
                                <ckeditor v-model="profile.description" :config="editorConfig"
                                          value="Hello, World!"></ckeditor>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-fade-transition>
            </template>
            <v-fade-transition>

                <v-row justify="center">
                    <v-col cols="12">
                        امضا
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="8" class="text-center">
                        <v-card>
                            <v-img
                                    v-if="profile.signature != null || signatureFile!=null"
                                    :src="signatureFile || profile.signature!=null? 'http://betabours.ir:8080'+profile.signature : false"
                                    alt="signature"
                            >
                            </v-img>
                        </v-card>
                    </v-col>

                </v-row>
            </v-fade-transition>

            <v-row justify="center">

                <v-col cols="4">
                    <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            outlined
                            placeholder="انتخاب فایل امضا"
                            prepend-icon="mdi-camera"
                            label="امضا"
                            @change="onFileChange('signature',$event)"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="6" md="8" sm="10">
                    <div class="pa-5 text-center">
                        برای ثبت تغییرات ابتدا کد احراز هویت را وارد نمایید
                    </div>

                    <SaveWithCode @proceed="saveProfile" :saving="isSaving"></SaveWithCode>
                </v-col>
            </v-row>
        </v-container>


    </v-card>
</template>
<script>
    /*eslint-disable*/

    import {encodeImageFileAsURL} from '../../utilities.js'
    import {EventBus} from '@/../src/event-bus.js';
    import SaveWithCode from '@/components/SaveWithCode.vue';
    import DatePicker from 'vue-persian-datetime-picker';
    import moment from 'moment-jalaali';
    import Setting from "../../components/Setting";


    // import Vue from 'vue';
    import CKEditor from 'ckeditor4-vue';

    // Vue.use(CKEditor);

    export default {
        components: {
            SaveWithCode,
            DatePicker,
            ckeditor: CKEditor.component,
            Setting
        },
        data() {
            return {
                today: moment().format('YYYY-MM-DD'),
                datePickerVisibility: false,
                isSaving: false,
                legalPersonality: null,
                formHasErrors: false,
                rules: {
                    required: value => !!value || 'الزامی',
                    // counter: value => value <= 20 || 'حداکثر 20 کاراکتر',
                    email: value => {

                        if (value != undefined && value != '') {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return pattern.test(value) || 'ایمیل نامعتبر'
                        } else {
                            return true;
                        }
                    }
                },
                isSigned: 0,
                legalPersonalities: [
                    {
                        "value": 1,
                        "title": "حقیقی"
                    },
                    {
                        "value": 2,
                        "title": "حقوقی"
                    }
                ],
                editorConfig: {},
                editorData: '',
                avatarFile: null,
                code: '',
                genders: [
                    {text: 'مرد', value: 1},
                    {text: 'زن', value: 2}
                ],
                profile: {
                    verificationType: null,
                    nationalId: null,
                    registrationCode: null,
                    tableManager: null,
                    companyName: null,
                    ceo: null,
                    description: null,
                    email: null,
                    stockCode: null,
                    firstName: null,
                    lastName: null,
                    mobile: null,
                    // avatar: null,
                    phoneNumber: null,
                    sex: null,
                    code: '123456',
                    nationalCode: null,
                    address: null,
                    legalPersonality: null
                },
                selectStaticPassword: false,
                showStaticPassword: false,
                sendCode: [
                    {
                        title: 'email',
                        href: this.emailLink,

                        text: 'email'
                    },
                    {title: 'message', href: "sms:' phone number here ';body='body text here'", text: 'message'},
                ],

                loading: false,
                signatureFile: null,
                signature: require('@/assets/signature.jpg')
            }

        },
        computed: {
            jalaliBirthday() {

                console.log(this.profile.birthDay);
                console.log(moment(this.profile.birthDay, 'YYYY-MM-DD').format('jYYYY-jMM-jDD'));
                return moment(this.profile.birthDay, 'YYYY-MM-DD').format('jYYYY-jMM-jDD');
            },
            isLegal() {
                // return true;
                return this.legalPersonality == 2;
            },
            isReal() {
                // return true;
                return this.legalPersonality == 1;
            },

            emailLink() {
                return 'mailto:?Subject=Welcome to our site&body=Click On the Below Link To Join Stock Exchange http://localhost:8080/login?invitation=' + this.code;
            },
            messageLink() {
                return 'sms:?body=Click On the Below Link To Join Stock Exchange http://localhost:8080/login?invitation=' + this.code;
            }
        },
        created() {

            this.loading = true;

            this.$store.dispatch('getProfile').then(() => {
                this.profile = this.$store.getters.getUserProfile;

                this.profile.verificationType === 1 ? this.selectStaticPassword = false : this.selectStaticPassword = true

                console.log(this.profile)

                // if (this.profile.signature == null) {
                //     this.profile.signature = require('@/assets/signature.jpg');
                // }

                this.legalPersonality = this.profile.legalPersonality;

            }).finally(() => {
                this.loading = false;
            });


        },
        beforeDestroy() {
            EventBus.$off('profileUpdated');
        },
        mounted() {
            EventBus.$on('profileUpdated', () => {
                console.log(this.profile);
            });
        },
        methods: {
            onFileChange(target, file) {

                console.log(target, file);


                if (!file || file == undefined) {
                    switch (target) {
                        case 'avatar':

                            this.avatarFile = null;
                            break;
                        case 'signature':
                            this.signatureFile = null;
                            break;
                    }
                    return;

                }


                encodeImageFileAsURL(file).then(resp => {
                    switch (target) {
                        case 'signature':
                            this.signatureFile = resp;
                            break;
                        case 'avatar':
                            this.avatarFile = resp;
                            break;
                    }
                });
            },


            resetForm() {
                // Object.keys(this.$refs).forEach(f => {
                //     if (this.$refs[f] != undefined) {
                //         this.$refs[f].reset()
                //     }
                //
                //
                // })
            },
            saveProfile(verification) {
                this.profile.code = verification.code;

                if (this.$store.getters.requiresPassword) {
                    this.profile.password = verification.password;
                }

                console.log(this.selectStaticPassword)

                if(this.selectStaticPassword) {
                    this.profile.verificationType = 2
                }else {
                    console.log('Type: 1')
                    this.profile.verificationType = 1
                    this.profile.staticPassword = ""
                }

                this.isSaving = true;

                this.profile.legalPersonality = this.legalPersonality;

                let data;

                data = JSON.parse(JSON.stringify(this.profile));

                if (this.avatarFile != null) {
                    data.avatar = this.avatarFile;
                }
                else {
                    delete data.avatar;
                }

                if (this.signatureFile != null) {
                    data.signature = this.signatureFile;
                }
                else {
                    delete data.signature;
                }

                console.log(data);
                //
                // postalCode
                // notificationToken
                // return;
                this.$store.dispatch('editProfile', data).then(() => {
                    console.log('DAMN');
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'پروفایل با موفقیت آپدیت شد.',
                    });
                }).catch((err) => {
                    console.log('WE HAVE ERROR');
                    console.log(err);
                }).finally(() => {
                    this.isSaving = false;
                });
            }
        },
        filters: {
            toJalaali(value) {
                return moment(value).format('jYYYY/jMM/jDD');
            }
        }
    }
</script>

<style>

</style>
