<template>

    <v-card
            width="100%"
            class="elevation-2"
    >
        <v-fade-transition>
            <v-dialog
                    v-model="removeItemDialog"
                    v-if="voteToRemove!=null"
                    max-width="400"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا از حذف این آیتم اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="secondary"
                                text
                                @click="removeItemDialog = false"
                        >
                            خیر
                        </v-btn>

                        <v-btn
                                color="primary"
                                text
                                @click="removeVote"
                        >
                            بله
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>
        <v-dialog
                v-model="contractOverlay"
                max-width="70%"

        >

            <v-card v-if="currentVote!=null" @click="contractOverlay = false">
                <v-card-text>
                    <v-img :src="$store.getters.getStaticBaseURL+currentVote.contract">
                    </v-img>
                </v-card-text>
            </v-card>

        </v-dialog>


        <v-card-title>
            رای های من
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
            <v-expansion-panels class="mb-6" v-if="votes.length">
                <v-expansion-panel
                        v-for="vote in votes"
                        :key="vote.id"
                >
                    <v-expansion-panel-header expand-icon="mdi-menu-down">

                        <v-row
                                align="center"
                                class="spacer"
                                no-gutters
                        >
                            <v-col
                                    cols="4"
                                    sm="2"
                                    md="1"
                            >
                                <v-avatar
                                        size="36px"
                                >
                                    <img
                                            alt="Avatar"
                                            :src="$store.getters.getStaticBaseURL+vote.companyBaseInfo.avatar"
                                    >
                                </v-avatar>
                            </v-col>

                            <v-col
                                    class="hidden-xs-only"
                                    sm="5"
                                    md="3"
                            >
                                <span>{{vote.companyBaseInfo.name}}</span>
                            </v-col>

                            <v-col
                                    class="text-no-wrap"
                                    cols="5"
                                    sm="3"
                            >
                                <span>{{vote.title}}</span>
                            </v-col>

                            <!--<v-col-->
                            <!--class="grey&#45;&#45;text text-truncate hidden-sm-and-down"-->
                            <!--&gt;-->
                            <!---->
                            <!--</v-col>-->
                        </v-row>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-divider></v-divider>
                        <v-row v-for="choice in vote.userVotes" :key="choice.id" justify="center"
                               class="align-content-center align-center align-content-center">

                            <v-col cols="12" lg="4" md="4" sm="4">
                                <Trader :trader="choice.voteCollectorUser"></Trader>
                            </v-col>
                            <v-col cols="12" lg="4" sm="4" md="3">
                                <v-text-field label="درصد" outlined :readonly="editingVote.id != choice.id"
                                              v-model="choice.percentage">
                                    <template v-slot:append>
                                        <v-btn small text @click="editVote(choice,vote)"
                                               v-if="editingVote.id != choice.id">
                                            <v-icon small>
                                                fas fa-pen
                                            </v-icon>
                                        </v-btn>
                                        <v-btn small text @click="cancelEditing()" v-else>
                                            <v-icon small color="red">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>

                                    </template>
                                </v-text-field>
                                <v-fade-transition>
                                    <SaveWithCode @proceed="confirmEdit" :saving="isSaving"
                                                  v-if="editingVote.id == choice.id"></SaveWithCode>
                                </v-fade-transition>
                            </v-col>

                            <v-col cols="12" lg="4" md="5" sm="4" class="text-center">
                                <v-hover v-slot:default="{hover}">
                                    <v-card>
                                        <v-card-title class="pa-3">
                                            قرارداد
                                        </v-card-title>
                                        <v-img :src="$store.getters.getStaticBaseURL+choice.contract" alt="contract"
                                               height="115">
                                            <v-overlay
                                                    absolute
                                                    :value="hover"
                                                    style="cursor: pointer;"

                                            >
                                                <v-btn fab small @click="showContract(choice)">
                                                    <v-icon small>fas fa-search-plus</v-icon>
                                                </v-btn>
                                            </v-overlay>

                                        </v-img>
                                    </v-card>
                                </v-hover>
                            </v-col>
                            <v-col cols="12">
                                <v-btn @click="showRemoveDialog(choice)" text class="red white--text">حذف رای</v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card v-else>
                <v-card-text>
                    شما تا کنون به کسی رای نداده اید.
                </v-card-text>
            </v-card>


        </v-container>
    </v-card>
</template>
<script>
    /*eslint-disable*/
    import {mapActions, mapGetters} from 'vuex';
    import {EventBus} from "../../event-bus";
    import Trader from '@/components/Trader/TraderCard.vue';
    import SaveWithCode from '@/components/SaveWithCode.vue';


    export default {
        components: {
            Trader,
            SaveWithCode
        },
        methods: {
            getUserVotes() {
                this.$store.dispatch('getUserVotes').then((resp) => {
                    this.votes = resp.result;
                })
            },
            showRemoveDialog(choice) {
                this.voteToRemove = choice;
                this.removeItemDialog = true;
            },
            removeVote() {
                this.$store.dispatch('deleteUserVote', this.voteToRemove.id).then((resp) => {
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت حذف شد.',
                    });
                    this.getUserVotes();
                }).finally(() => {
                    this.removeItemDialog = false;
                })
            },
            cancelEditing() {
                this.editingVote.percentage = this.editingVoteBackup;
                this.editingVote = -1;

            },
            confirmEdit(verification) {


                let votes = [{
                    userVoteId: this.editingVote.id,
                    percentage: this.editingVote.percentage
                }];

                let data = {};

                data.voteId = this.assembly.id;
                data.payload = {};
                data.payload.votes = votes;
                data.payload.code = verification.code;
                if (this.$store.getters.requiresPassword) {
                    data.payload.password = verification.password;
                }

                this.$store.dispatch('editUserVote', data).then((resp) => {
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'success',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'با موفقیت تغییر یافت.',
                    });
                    console.log(resp);
                }).catch((resp) => {
                    EventBus.$emit('notify', {
                        visibility: true,
                        color: 'red',
                        position: 'bottom',
                        timeout: 3000,
                        mode: 'vertical',
                        message: 'عملیات با شکست مواجه شد.',
                        icon: 'fas fa-times'
                    });
                    this.editingVote.percentage = this.editingVoteBackup;
                    this.editingVote = -1;
                    this.assembly = null;
                }).finally(() => {
                    this.editingVote = -1;
                    this.assembly = null;
                });
            },
            editVote(choice, assembly) {
                console.log('damn');
                this.assembly = assembly;
                this.editingVoteBackup = choice.percentage;
                this.editingVote = choice;
            },
            showContract(vote) {
                this.currentVote = vote;
                this.contractOverlay = true;
            }
        },

        data() {
            return {
                removeItemDialog: false,
                voteToRemove: null,
                assembly: null,
                isSaving: false,
                editingVoteBackup: 0,
                editingVote: -1,
                contractOverlay: false,
                currentVote: null,
                votes: []
            };
        },
        created() {
            this.getUserVotes();
        }
    }

</script>

<style>

</style>
