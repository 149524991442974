<template>
    <v-sheet class="elevation-0" elevation="8">
        <v-slide-group
                center-active
                v-model="model"
                class="pa-4"
                :show-arrows="$vuetify.breakpoint.mdAndUp"
        >
            <v-slide-item
                    v-slot:default="{ active, toggle }"
                    v-for="n in 15"
                    :key="n"
            >
                <v-hover v-slot:default="{ hover }">
                    <v-card
                            @click="toggle"
                            class="ma-4"
                            width="300"
                    >
                        <v-expand-transition v-if="hover">
                            <v-card
                                    @click="$router.push({ path: '/fa/profile/people/حسن_معجونی' })"
                                    height="100%"
                                    style="z-index: 2; height: 100%; opacity: .8"
                                    class="transition-fast-in-fast-out white v-card--reveal">
                                <v-card-title class="blue--text">
                                    Goodgoing | <small> حسن معجونی</small>
                                </v-card-title>
                                <v-card-text>
                                    <div class="font-weight-light grey--text mb-2"></div>
                                    <div class="font-weight-light mb-2">
                                        شاید برای شما هم این سوال پیش آمده است که بورس چیست باید بگوییم اندیشه ی ایجاد بورس زمانی شکل گرفت که عده ای از بازرگانان اروپایی از فعالیت های تجاری خود ضرر کردند، بنابراین به فکر راه حلی افتادند تا به وسیله ی آن بتوانند جلوی این ضرر را بگیرند.
                                    </div>
                                </v-card-text>
                                <v-card-subtitle >
                                    <small class="font-weight-light">شناخت و فهمیدن این موضوع که واقعا بورس چیست</small>
                                </v-card-subtitle>
                            </v-card>
                        </v-expand-transition>
                        <v-img
                                style="border-radius: 4px"
                                height="150"
                                :aspect-ratio="16/9"
                                :src="require('@/assets/trader.jpg')"
                        >
                            <div
                                    class="d-flex white v-card--reveal justify-start pa-3"
                                    style="height: 30%;"
                            >
                                <span class="font-weight-bold">Goodgoing</span>
                                <span class="pa-3">|</span>
                                <span class="accent--text text--darken-1">حسن معجونی</span>
                            </div>
                        </v-img>
                        <v-card-text
                                class="pt-1"
                                style="position: relative;"
                        >
                            <div class="d-flex justify-space-between align-center">
                                <div class="title font-weight-medium success--text">
                                    25.79%
                                    <v-icon small color="success">fas fa-arrow-up</v-icon>
                                    <v-card-subtitle class="pa-0">
                                        بازگشت (12 ماه)
                                    </v-card-subtitle>
                                </div>
                                <div class="d-flex flex-column align-center">
                                    <div
                                        style="border: 2px solid orange; border-radius: 4px"
                                        class="px-4 py-2 orange--text font-weight-medium">6</div>
                                    ریسک
                                </div>
                            </div>
                            <v-divider class="my-2" />
                            <div class="d-flex justify-space-between">
                                <div>
                                    <span class="font-weight-medium pl-1">1280 کپی کننده</span>
                                    (<small class="success--text font-weight-medium">
                                        <v-icon x-small color="success" class="mb-1">fas fa-arrow-up</v-icon>
                                        25.79%
                                        <span class="grey--text text--darken-1">7 روز اخیر</span>
                                    </small>)
                                </div>
                               <v-icon color="accent">mdi-playlist-plus</v-icon>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-slide-item>
        </v-slide-group>
    </v-sheet>
</template>

<script>
    export default {
        data: () => ({
            model: null,
        }),
    }
</script>

<style scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .9;
        backdrop-filter: blur(5px);
        position: absolute;
        width: 100%;
    }
</style>
