import MainLayout from '../views/layouts/MainPage.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ProfessionalSignUp from '../views/professional/ProfessionalSignUp.vue'
import UserProfile from '../views/user/UserProfile.vue'
import UserInformation from '../views/user/UserInformation.vue'
import UserCompanies from '../views/user/UserCompanies.vue'
import UserAgencies from '../views/user/UserAgencies.vue'
import UserShareHolders from '../views/user/UserShareHolders.vue'
import UserAssemblies from '../views/user/UserAssemblies.vue'
import UserVoteRequests from '../views/user/UserVoteRequests.vue'
import UserGivenVotes from '../views/user/UserGivenVotes.vue'
import UserDashboard from '../views/user/UserDashboard.vue'
import UserExpertSignUp from '../views/user/UserExpertSignUp.vue'

import Company from '../views/Company.vue'
import Companies from '../views/Companies.vue'
import Settings from '../views/worker/Settings.vue'
import UserDiscoverPeople from "../views/user/UserDiscoverPeople";

import TraderLayout from "../views/trader/TraderLayout"
import TraderFeed from "../views/trader/TraderFeed"
import TraderStats from "../views/trader/TraderStats"
import TraderPortfolio from "../views/trader/TraderPortfolio"
import TraderChart from "../views/trader/TraderChart"


const routes = [
    {
        path: '/:lang',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: 'companies',
                name: 'companies',
                component: Companies
            },
            {
                path: 'login',
                name: 'login',
                component: Login,
                meta: {
                    requiresGuest: true
                }
            },
            {
                path: 'become-a-partner',
                name: 'professionalsignup',
                component: ProfessionalSignUp,
                meta: {
                    requiresGuest: true
                }
            },
            {
                path: 'company/:code/:name',
                name: 'company',
                component: Company
            },
            {
                path: 'profile',
                name: 'userProfile',
                component: UserProfile,
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '/',
                        name: 'userHome',
                        component: UserDashboard,
                        meta: {
                            requiresAuth: true,
                            requiredRoles: ['client', 'trader']
                        }
                    },
                    {
                        path: 'information',
                        name: 'userInfo',
                        component: UserInformation
                    },
                    {
                        path: 'companies',
                        name: 'userCompanies',
                        component: UserCompanies
                    },
                    {
                        path: 'agencies',
                        name: 'userAgencies',
                        component: UserAgencies
                    },
                    {
                        path: 'discover/people',
                        name: 'userDiscoverPeople',
                        component: UserDiscoverPeople
                    },
                    {
                        path: 'people/:id',
                        name: 'traderLayout',
                        component: TraderLayout,
                        children: [
                            {
                                path: '/',
                                name: 'traderFeed',
                                component: TraderFeed
                            },
                            {
                                path: 'stats',
                                name: 'traderStats',
                                component: TraderStats
                            },
                            {
                                path: 'portfolio',
                                name: 'traderPortfolio',
                                component: TraderPortfolio
                            },
                            {
                                path: 'chart',
                                name: 'traderChart',
                                component: TraderChart
                            }
                        ]
                    },
                    {
                        path: 'shares',
                        name: 'userShareHolders',
                        component: UserShareHolders
                    },
                    {
                        path: 'assemblies',
                        name: 'userAssemblies',
                        component: UserAssemblies
                    },
                    {
                        path: 'requests',
                        name: 'userVoteRequests',
                        component: UserVoteRequests
                    },
                    {
                        path: 'votes',
                        name: 'userGivenVotes',
                        component: UserGivenVotes
                    },
                    {
                        path: 'settings',
                        name: 'settings',
                        component: Settings,
                        meta: {
                            requiresProfessional: true
                        }
                    },
                    {
                        path: 'trader-signup',
                        name: 'customerTraderSignUp',
                        component: UserExpertSignUp,
                        meta: {
                            requiresCustomer: true
                        }
                    }
                ]
            },
            {
                path: 'logout',
                name: 'logout',
            }
        ]
    }
];

export default routes
