/*eslint-disable*/
import {ajaxPost, ajaxGet, LS} from '../../utilities.js';
import {EventBus} from "../../event-bus";

export default {
    // namespaced: true,
    state: {},
    mutations: {},
    actions: {
        searchCompanies({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + "companies", payload).then((resp) => {
                return resp;
            });
        },
        getAllCompanies({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + 'companies', payload).then((resp) => {
                return resp;
            });
        },

        getAssemblies({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + 'votes', payload).then((resp) => {
                return resp;
            });
        },
        getUserAssemblies({commit, rootState}, payload) {
            return ajaxGet(rootState.baseURL + 'votes/userRelated', payload).then((resp) => {
                return resp;
            });
        },
        joinMeeting({commit, rootState}, assemblyId) {
            return ajaxGet(rootState.baseURL + `votes/joinMeeting/${assemblyId}`).then((resp) => {
                return resp;
            });
        },
        getUserCompanies({commit, rootState}) {
            return ajaxGet(rootState.baseURL + 'user/company').then((resp) => {
                return resp;
            });
        },
        addCompanyShare({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + 'user/company/setCompanySharing', payload).then((resp) => {
                return resp;
            });
        },
        removeUserCompany({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + `user/company/${payload.id}/delete`, {}).then((resp) => {
                return resp;
            })
        },
        requestVote({commit, rootState}, data) {
            return ajaxPost(rootState.baseURL + `user/voteCollecting/votes/${data.assembly}/makeRequest`, data.payload).then((resp) => {
                return resp;
            });
        },
        getRequestedVoteAssemblies({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + `user/voteCollecting/votes`, payload).then((resp) => {
                return resp;
            });
        },
        removeVoteRequest({commit, rootState}, payload) {

            return ajaxPost(rootState.baseURL + `user/voteCollecting/votes/deleteRequest/${payload.id}`).then((resp) => {
                return resp;
            })
        },
        getCompany({commit, rootState}, payload) {

            return ajaxGet(rootState.baseURL + `companies/${payload}/detail`).then((resp) => {
                return resp;
            });
        },
        getUserVotes({commit, rootState}) {
            return ajaxGet(rootState.baseURL + `user/votes`);
        },

        editUserVote({commit, rootState}, data) {
            return ajaxPost(rootState.baseURL + `user/votes/${data.voteId}/edit`, data.payload);
        },

        deleteUserVote({commit, rootState}, userVoteId) {
            return ajaxPost(rootState.baseURL + `user/votes/delete/${userVoteId}`, null);
        },
        voteAssembly({commit, rootState}, payload) {
            return ajaxPost(rootState.baseURL + `user/votes/${payload.assemblyId}/add`, payload.data);
        },
        confirmVoteAssembly({commit, rootState}, voteId) {
            return ajaxPost(rootState.baseURL + `user/votes/confirm/${voteId}`, null);
        },
        getVotingReport({commit, rootState}, votePollId) {
            return ajaxGet(rootState.baseURL + `user/voteCollecting/votes/${votePollId}/report`);
        },

    },
    getters: {}
};