/*eslint-disable*/
import {LS, ajaxPost, ajaxGet} from '../../utilities.js';
import {EventBus} from "../../event-bus";
import Axios from "axios";

const auth = {
    // namespaced: true,
    state: {
        loggedIn: LS.get('loggedIn'),
        role: LS.get('role'),
        legality: {
            real: 1,
            legal: 2
        },
        profile: {
            firstName: LS.getProp('user', 'firstName'),
            lastName: LS.getProp('user', 'lastName'),
            mobile: LS.getProp('user', 'mobile'),
            avatar: LS.getProp('user', 'avatar'),
            verificationType: LS.getProp('user', 'verificationType'),
            postalCode: LS.getProp('user', 'postalCode'),
            sex: LS.getProp('user', 'sex'),
            code: LS.getProp('user', 'code'),
            nationalCode: LS.getProp('user', 'nationalCode'),
            type: LS.getProp('user', 'type'),
            state: LS.getProp('user', 'state'),
            legalPersonality: LS.getProp('user', 'legalPersonality'),
            legality: LS.getProp('user', 'legality'),
            signed: LS.getProp('user', 'signed'),
            companyName: LS.getProp('user', 'companyName'),
            registrationCode: LS.getProp('user', 'registrationCode'),
            nationalId: LS.getProp('user', 'nationalId'),
            ceo: LS.getProp('user', 'ceo'),
            tableManager: LS.getProp('user', 'tableManager'),
            phoneNumber: LS.getProp('user', 'phoneNumber'),
            description: LS.getProp('user', 'description'),
            address: LS.getProp('user', 'address'),
            birthCertificateRegisterLocation: LS.getProp('user', 'birthCertificateRegisterLocation'),
            birthCertificateId: LS.getProp('user', 'birthCertificateId'),
            birthDay: LS.getProp('user', 'birthDay'),
            fatherName: LS.getProp('user', 'fatherName'),

        },
        access: LS.get('access'),
        refresh: LS.get('refresh'),
        axiosIsSet: false
    },
    mutations: {
        clearTokens(state) {
            state.access = '';
            state.refresh = '';
            LS.remove(['access', 'refresh']);
            Axios.defaults.headers.common['Authorization'] = null;
        },
        setTokens(state, data) {
            LS.set('access', 'Bearer ' + data.result.accessToken);
            LS.set('refresh', 'Bearer ' + data.result.refreshToken);
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.result.accessToken; // for all requests
            state.access = 'Bearer ' + data.result.accessToken;
            state.refresh = 'Bearer ' + data.result.refreshToken;
        },

        signOut(state) {
            state.loggedIn = false;
            // state.role = '';
            // state.profile = {};
            state.access = null;
            state.refresh = null;

        },
        clearProfile(state) {
            state.profile.firstName = null;
            state.profile.lastName = null;
            state.profile.mobile = null;
            state.profile.avatar = null;
            state.profile.verificationType = null;
            state.profile.postalCode = null;
            state.profile.birthCertificateRegisterLocation = null;
            state.profile.birthCertificateId = null;
            state.profile.birthDay = null;
            state.profile.fatherName = null;
            state.profile.sex = null;
            state.profile.code = null;
            state.profile.nationalCode = null;
            state.profile.type = null;
            state.profile.state = null;
            state.profile.legalPersonality = null;
            state.profile.signed = null;
            state.profile.companyName = null;
            state.profile.registrationCode = null;
            state.profile.nationalId = null;
            state.profile.ceo = null;
            state.profile.tableManager = null;
            state.profile.phoneNumber = null;
            state.profile.description = null;
            state.profile.address = null;
            state.profile.stockCode = null;
            state.profile.id = null;
            state.profile.userType = null;
            state.role = null;
        },
        updateProfile(state, data) {


            state.profile.id = data.id;
            state.profile.stockCode = data.stockCode;
            if (data.legalPersonality != null) {
                state.profile.legalPersonality = data.legalPersonality.value;
                if (data.userType.value == 1) {
                    state.profile.legality = data.legalPersonality.value == 1 ? 'realClient' : 'legalClient';
                }
                else if (data.userType.value == 2) {
                    state.profile.legality = data.legalPersonality.value == 1 ? 'realTrader' : 'legalTrader';
                }

            } else {
                state.profile.legalPersonality = null;
                state.profile.legality = null;
            }
            state.profile.userType = data.userType;
            state.profile.verificationType = data.verificationType.value;
            state.profile.signed = data.signed;
            state.profile.postalCode = data.postalCode;
            state.profile.signature = data.signature;
            state.profile.avatar = data.avatar;
            state.role = data.userType.value == 1 ? 'client' : 'trader';
            LS.set('role', state.role);

            if (data.legalPersonality != null) {
                if (data.legalPersonality.value == 1) {
                    if (data.hasOwnProperty('profileInformation')) {
                        state.profile.sex = data.profileInformation.sex.value;
                        state.profile.firstName = data.profileInformation.firstName;
                        state.profile.lastName = data.profileInformation.lastName;
                        state.profile.nationalCode = data.profileInformation.nationalCode;
                        state.profile.birthCertificateRegisterLocation = data.profileInformation.birthCertificateRegisterLocation;
                        state.profile.birthCertificateId = data.profileInformation.birthCertificateId;
                        state.profile.birthDay = data.profileInformation.birthDay;
                        state.profile.fatherName = data.profileInformation.fatherName;
                    }
                } else if (data.legalPersonality.value == 2) {
                    if (data.hasOwnProperty('profileInformation')) {
                        state.profile.companyName = data.profileInformation.companyName;
                        state.profile.registrationCode = data.profileInformation.registrationCode;
                        state.profile.nationalId = data.profileInformation.nationalId;
                        state.profile.ceo = data.profileInformation.ceo;
                        state.profile.tableManager = data.profileInformation.tableManager;
                        state.profile.phoneNumber = data.profileInformation.phoneNumber;
                        state.profile.description = data.profileInformation.description;
                        state.profile.address = data.profileInformation.address;
                    }
                }
            }


            LS.set('user', state.profile);
        },
        changeToLoggedIn(state) {

            state.loggedIn = true;
            LS.set('loggedIn', true);

        },
        changeActiveState(state, data) {
            let newState = {id: data, title: data == 1 ? 'active' : 'deactivate'};
            state.profile.activeState = newState;
            LS.setProp('user', 'activeState', newState);
        }

    },
    actions: {
        getNewToken({commit, rootState}) {
            return new Promise((resolve, reject) => {
                let url = rootState.baseURL + 'refreshAccount';
                let refreshToken = LS.get('refresh');
                if (refreshToken != null) {

                    return Axios.post(url, null, {
                        headers: {
                            'Auth-Refresh': refreshToken
                        }
                    }).then(resp => {
                        commit('setTokens', resp.data);
                        resolve();
                    }).catch((err) => {

                        commit('clearTokens');
                        commit('signOut');
                        reject(err);
                    });
                } else {
                    resolve();
                }
            })
        },
        getProfile({commit, state, rootState}) {

            let url = rootState.baseURL + 'user/profile';

            return ajaxGet(url).then(resp => {
                commit('updateProfile', resp.result);
                EventBus.$emit('profileUpdated');
                return (resp.result);
            }).catch(err => {
                console.log(err);
                return (err);
            });

        },

        requestLoginCode({commit, state, rootState}, payload) {
            let url = rootState.baseURL + 'codeSend';
            return ajaxPost(url, payload)
        },
        sendSmsCode({commit, state, rootState}, payload) {
            return new Promise((resolve, reject) => {
                let url = rootState.baseURL + 'signIn';
                ajaxPost(url, payload).then(resp => {

                    commit('changeToLoggedIn');
                    // commit('clearProfile');
                    commit('setTokens', resp);
                    resolve(resp);

                }).catch(err => {
                    reject(err);
                });
            });
        },

        editProfile({commit, state, dispatch, rootState}, data) {

            let url = rootState.baseURL + 'user/profile/edit';
            return ajaxPost(url, data).then((resp) => {
                dispatch('getProfile');
                return resp;
            }).catch(err => {
                return Promise.reject(err);
                // return err;
            });


        },
        convertToTrader({commit, state, rootState}, payload) {
            return ajaxPost(rootState.baseURL + 'user/profile/convertToTrader', payload);
        },
        getVerificationCode({commit, state, rootState}) {

            let url = rootState.baseURL + 'verificationCode';

            return ajaxGet(url).then(resp => {
                // commit('updateProfile', resp.result);
                // EventBus.$emit('profileUpdated');
                // return (resp.result);
                return resp;
            }).catch(err => {
                // console.log(err);
                // return (err);
                return err;
            });
        },

        getCompanies({state, rootState, commit}, payload) {
            let url = rootState.baseURL + 'user/company';
            return ajaxGet(url).then(resp => {
                return (resp.result);
            }).catch(err => {
                console.log(err);
                return (err);
            });
        },
        getShareHolders({state, rootState, commit}) {
            return ajaxGet(rootState.baseURL + 'user/profile/shareHolders');
        },
        updateShareholders({commit, state, rootState}, payload) {
            let url = rootState.baseURL + 'user/profile/shareHolders/update';
            return ajaxPost(url, payload);
        },

        signOut({commit}) {

            return new Promise((resolve, reject) => {
                LS.remove([
                    'loggedIn',
                    'access',
                    'refresh',
                    'user',
                    'role'
                ]);
                EventBus.$emit('loggedOut');
                commit('clearProfile');
                commit('signOut');
                resolve(true);

            });
        },

    },
    getters: {
        requiresPassword: state => state.profile.verificationType == 2,
        userIsReal: state => state.profile.legalPersonality == 1 ? true : false,
        userIs: state => legality => {
            return state.profile.legalPersonality == state.legality[legality] ? true : false;
        },
        getAccessToken: state => state.access,
        userIsLoggedIn: state => {
            return state.loggedIn;
        },
        userHasRole: (state) => (role) => {
            if (role != undefined) {
                return state.role == role ? true : false;
            } else {
                return false;
            }
        },
        userHasRoles: (state) => (roles, types = []) => {
            let result = true;
            let type = true;


            // console.log(state.profile.legality);

            if (roles != undefined) {
                result = roles.includes(state.role);
            }

            if (types.length) {
                // console.log(types, state.profile.legality);
                type = types.includes(state.profile.legality);
            }

            return result && type;
        },
        getUserProfile: state => state.profile,
        getWorkerActiveState: state => state.profile.activeState,
        isSigned: state => state.profile.signed
    }

};

export default auth;