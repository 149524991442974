<template>
    <v-card>
        <v-card-title>
            سهام
            <v-spacer></v-spacer>
<!--            <v-text-field-->
<!--                    v-model="search"-->
<!--                    append-icon="mdi-magnify"-->
<!--                    label="Search"-->
<!--                    single-line-->
<!--                    hide-details-->
<!--            ></v-text-field>-->
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
        >
            <template v-slot:item.markets="{ item }">
                <v-list-item>
                    <v-list-item-avatar
                            tile
                            size="40"
                            style="border-radius: 4px !important;"
                            color="grey"
                    >
                        <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{item.markets}}</v-list-item-title>
                        <v-list-item-subtitle class="mt-n6"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:item.sell="{ item }">
                <div  class="d-flex mt-5" style="width: 200px">
                    <v-btn color="primary" height="40" class="elevation-0 ml-n1">S</v-btn>
                    <v-text-field class="ltr" disabled dense outlined solo flat :placeholder="item.sell"></v-text-field>
                </div>
            </template>
            <template v-slot:item.buy="{ item }">
                <div  class="d-flex mt-5" style="width: 200px">
                    <v-btn color="secondary" height="40" class="elevation-0 ml-n1">B</v-btn>
                    <v-text-field class="ltr" disabled dense outlined solo flat :placeholder="item.sell"></v-text-field>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        data () {
            return {
                search: '',
                headers: [
                    {
                        text: 'مارکت ها',
                        align: 'start',
                        sortable: false,
                        value: 'markets',
                    },
                    { text: 'خرید / فروش', value: 'BS' },
                    { text: 'سرمایه گذاری شده', value: 'invested' },
                    { text: 'P/L %', value: 'PL' },
                    { text: 'ارزش', value: 'value' },
                    { text: 'فروش', value: 'sell' },
                    { text: 'خرید', value: 'buy' },
                ],
                desserts: [
                    {
                        name: 'Frozen Yogurt',
                        BS: 159,
                        invested: 6.0,
                        PL: 24,
                        value: 4.0,
                        sell: '1%',
                        buy: '1%',
                        markets: 'Work'
                    },
                    {
                        name: 'Ice cream sandwich',
                        BS: 237,
                        invested: 9.0,
                        PL: 37,
                        value: 4.3,
                        sell: '1%',
                        buy: '1%',
                        markets: 'Work'
                    },
                    {
                        name: 'Eclair',
                        BS: 262,
                        invested: 16.0,
                        PL: 23,
                        value: 6.0,
                        sell: '7%',
                        buy: '7%',
                        markets: 'Work'
                    },
                    {
                        name: 'Cupcake',
                        BS: 305,
                        invested: 3.7,
                        PL: 67,
                        value: 4.3,
                        sell: '8%',
                        buy: '8%',
                        markets: 'Work'
                    },
                    {
                        name: 'Gingerbread',
                        BS: 356,
                        invested: 16.0,
                        PL: 49,
                        value: 3.9,
                        sell: '16%',
                        buy: '16%',
                        markets: 'Work'
                    },
                    {
                        name: 'Jelly bean',
                        BS: 375,
                        invested: 0.0,
                        PL: 94,
                        value: 0.0,
                        sell: '0%',
                        buy: '0%',
                        markets: 'Work'
                    },
                    {
                        name: 'Lollipop',
                        BS: 392,
                        invested: 0.2,
                        PL: 98,
                        value: 0,
                        sell: '2%',
                        buy: '2%',
                        markets: 'Work'
                    },
                    {
                        name: 'Honeycomb',
                        BS: 408,
                        invested: 3.2,
                        PL: 87,
                        value: 6.5,
                        sell: '45%',
                        buy: '45%',
                        markets: 'Work'
                    },
                    {
                        name: 'Donut',
                        BS: 452,
                        invested: 25.0,
                        PL: 51,
                        value: 4.9,
                        sell: '22%',
                        buy: '22%',
                        markets: 'Work'
                    },
                    {
                        name: 'KitKat',
                        BS: 518,
                        invested: 26.0,
                        PL: 65,
                        value: 7,
                        sell: '6%',
                        buy: '6%',
                        markets: 'Work'
                    },
                ],
            }
        },
    }
</script>
