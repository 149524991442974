<template>

    <v-card
            width="100%"
            class="elevation-2"
    >

        <v-fade-transition>
            <v-dialog
                    v-model="showCancelAgencyRequestDialog"
                    v-if="agencyToQuit !=null"
                    max-width="400"
            >
                <v-card>
                    <v-card-title class="headline">
                        <span>توجه</span>
                    </v-card-title>
                    <v-divider class="pa-2"></v-divider>

                    <v-card-text>
                        آیا نسبت به لغو نمایندگی خود اطمینان دارید؟
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="secondary"
                                text
                                @click="showCancelAgencyRequestDialog = false"
                        >
                            خیر
                        </v-btn>

                        <v-btn
                                color="primary"
                                text
                                @click="cancelAgencyRequest"
                        >
                            بله
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-fade-transition>

        <v-window v-model="currentWindow">
            <v-window-item :value="1">

                <CompanyAgency @requestSent="getUserAgencyRequests"></CompanyAgency>

                <v-card-title>
                    نمایندگی ها

                </v-card-title>
                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col v-for="(agency,index) in approvedAgencies" :key="index" cols="6" sm="4" md="3" lg="3"
                               xl="3">
                            <v-card class="elevation-1">
                                <v-img :src="'http://betabours.ir:8080'+agency.companyBaseInfo.avatar"
                                       max-height="100"
                                >
                                </v-img>
                                <v-card-title>
                                    <!--<router-link-->
                                    <!--:to="{name:'userAgency',params:{code:agency.companyBaseInfo.id,name:agency.companyBaseInfo.slug}}">-->
                                    <!---->
                                    <!--</router-link>-->
                                    <div>{{agency.companyBaseInfo.name}}</div>


                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text class="primary" @click="gotoAgency(agency)">مدیریت</v-btn>
                                </v-card-actions>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>


                <v-card-title>
                    درخواست های ارسالی
                    <v-spacer></v-spacer>
                    <!--<v-btn small @click="showAddCompany = true" class="primary elevation-0">-->
                    <!--<v-icon small>mdi-plus</v-icon>-->
                    <!--افزودن شرکت جدید-->
                    <!--</v-btn>-->
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-card class="mt-3 elevation-1">

                        <v-card-text>

                            <v-data-table
                                    :headers="sentRequestHeaders"
                                    hide-default-footer
                                    :items="sentRequests"
                            >
                                <template v-slot:item.companyBaseInfo.avatar="{item}">

                                    <v-avatar large class="elevation-0">
                                        <v-img :src="'http://betabours.ir:8080'+item.companyBaseInfo.avatar"
                                               contain
                                        >
                                        </v-img>
                                    </v-avatar>

                                </template>

                                <template v-slot:item.companyBaseInfo.name="{item}">
                                    {{item.companyBaseInfo.name}}
                                </template>


                                <template v-slot:item.state.title="{item}">
                                    <v-chip :class="{
                                    'green white--text':item.state.value == 2,
                                    'red white--text':item.state.value == 3
                                    }">
                                        {{item.state.title}}
                                    </v-chip>
                                </template>


                                <template v-slot:item.actions="{item}">


                                    <!--<v-btn small text @click="showEditRequestVoteDialog(item)">-->
                                    <!--<v-icon color="primary" small>fas fa-vote-yea</v-icon>-->
                                    <!--</v-btn>-->

                                    <v-btn small text @click="removeAgencyDialog(item)">
                                        <v-icon color="red" small>fas fa-trash</v-icon>
                                    </v-btn>

                                    <!--<v-btn small text @click="showReportsDialog(item)">-->
                                    <!--<v-icon color="green" small>fas fa-poll</v-icon>-->
                                    <!--</v-btn>-->

                                </template>

                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-pagination
                                    v-model="page"
                                    :length="length"
                                    :total-visible="totalVisible"
                            ></v-pagination>
                        </v-card-actions>
                    </v-card>
                </v-container>

            </v-window-item>
            <v-window-item :value="2">
                <SingleAgency :agency="currentAgency" @return="currentWindow=1"></SingleAgency>
            </v-window-item>
        </v-window>


    </v-card>
</template>
<script>
    import {mapGetters} from 'vuex';
    import moment from 'moment-jalali';
    import CompanyAgency from '@/components/CompanyAgency.vue';
    import SingleAgency from '@/components/UserAgency.vue';

    import {EventBus} from "../../event-bus";

    export default {
        components: {CompanyAgency, SingleAgency},

        computed: {
            approvedAgencies() {
                return this.sentRequests.filter(item => item.state.value == 2);
            },
            ...mapGetters({
                staticBaseURL: 'getStaticBaseURL'
            }),
            sentRequestHeaders() {
                return [
                    {
                        text: this.$t('userAssembliesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'companyBaseInfo.name'
                    },
                    {
                        text: this.$t('userAssembliesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'companyBaseInfo.avatar'
                    },
                    {
                        text: this.$t('userAssemblesTableStatusColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'state.title'
                    },

                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},
                ];
            },
            headers() {
                return [
                    {
                        text: this.$t('userAssembliesTableNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'companyBaseInfo.name'
                    },
                    {
                        text: this.$t('userAssembliesTableLogoColumn'),
                        align: 'center',
                        sortable: false,
                        value: 'company.avatar'
                    },
                    {
                        text: this.$t('userAssemblesTableAssemblyNameColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'title'
                    },
                    {
                        text: this.$t('userAssemblesTableStartDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'startDateTime'
                    },
                    {
                        text: this.$t('userAssemblesTableEndDateColumn'),
                        align: 'center',
                        sortable: true,
                        value: 'endDateTime'
                    },
                    {text: this.$t('actions'), align: 'center', sortable: false, value: 'actions'},


                ]
            }
        },
        data: () => ({
            currentAgency: null,
            currentWindow: 1,
            showCancelAgencyRequestDialog: false,
            reportDialog: false,
            reportItem: null,
            showRequestVote: false,
            requestToRemove: null,
            actionType: null, //1 - add 2- edit
            requestDialogTitle: 'ثبت درخواست برای آرای مجمع',
            sentRequests: [],
            devPlan: null,
            extension: null,
            requestVoteItem: null,
            requestVoteDialog: false,
            userAssemblies: [],
            showAddCompany: false,
            page: null,
            length: null,
            totalVisible: 7,
            editingItemShare: "",
            editingMode: false,
            editingItem: null,
            removeItemDialog: false,
            agencyToQuit: null,
            moment: null,


        }),
        created() {
            this.getUserAgencyRequests();
        },
        methods: {
            gotoAgency(item) {
                this.currentAgency = item;
                this.currentWindow = 2;
            },
            removeAgencyDialog(agency) {
                this.agencyToQuit = agency;
                this.showCancelAgencyRequestDialog = true;
            },
            cancelAgencyRequest() {

                this.$store.dispatch('cancelAgencyRequest', this.agencyToQuit.companyBaseInfo.id).then(() => {
                    EventBus.$emit('notif', 'green', undefined, 'نمایندگی شما با موفقیت لغو شد.');
                    this.getUserAgencyRequests();
                    this.showCancelAgencyRequestDialog = false;
                    this.agencyToQuit = null;
                });
            },
            getUserAgencyRequests() {
                this.$store.dispatch('getUserAgencyRequests').then((resp) => {
                    console.log(resp);
                    this.sentRequests = resp.result;
                });
            },

        },
        watch: {
            searchInput() {
                this.search();
            }
        },
        filters: {
            toJalali(input) {
                return moment(input).format('HH:MM - jYYYY/jMM/jDD');
            }
        }
    }

</script>

<style>

</style>



