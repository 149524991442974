<template>
    <v-container>
        <v-row justify="center">
            <v-col v-for="company in companies" :key="company.id" lg="3" cols="12" md="4" sm="6"
                   @click="$router.push({name:'company',params:{code:company.id,name:company.slug}})" style="cursor: pointer;">

                <v-card class="fill-height d-flex flex-column justify-space-between">
                    <v-card-text class="grey lighten-4 py-3">
                        <v-avatar class="elevation-2 white">
                            <v-img :src="$store.getters.getStaticBaseURL+company.avatar"
                                   contain
                            ></v-img>
                        </v-avatar>
                    </v-card-text>
                    <v-card-text class="title flex-grow-1">
                        <span>شرکت {{company.name}}</span>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-2">
                        تلفن : {{company.phoneNumber}}
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
        <v-pagination>

        </v-pagination>
    </v-container>
</template>

<script>
    /*eslint-disable*/

    export default {
        data() {
            return {
                companies: [
                    {
                        "name": "اسپیدو",
                        "id": 1,
                        "address": "همین اطراف",
                        "slug": "speido",
                        "phoneNumber": "123123",
                        "avatar": "/images/uploads/companies/company_1583155640130_2297206348987015173.png"
                    }, {
                        "name": "مس کاشان",
                        "id": 2,
                        "address": "همین اطراف",
                        "slug": "kashan-copper-industry-stc22134",
                        "phoneNumber": "12333",
                        "avatar": "/images/defaults/company/default-company.png"
                    }
                ]
            }
        },
        created() {
            this.getAllCompanies();
        },
        methods: {
            getAllCompanies() {
                let payload = {
                    "name": "",
                    "pageNumber": 1,
                    "pageSize": 10
                };
                this.$store.dispatch('getAllCompanies', payload).then((resp) => {
                    this.companies = resp.result.data;
                    console.log(resp);
                });
            }
        }
    }
</script>

<style scoped>

</style>
