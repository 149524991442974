<template>
    <v-card v-if="agency!=null">
        <assemblyForm
                type="edit"
                v-if="assemblyToEdit!=null"
                :assembly-data="assemblyEditData"
                @onAssembly="editAssembly"
                @closeDialog="editAssemblyDialog = false"
                :assembly-dialog="editAssemblyDialog">
        </assemblyForm>
        <assemblyForm
                type="add"
                v-if="assemblyToEdit!=null"
                :assembly-data="assemblyEditData"
                @onAssembly="addAssembly"
                @closeDialog="addAssemblyDialog = false"
                :assembly-dialog="addAssemblyDialog">
        </assemblyForm>


<!--        <v-dialog v-model="editAssemblyDialog" v-if="assemblyToEdit!=null" max-width="800">-->
<!--            <v-card>-->
<!--                <v-card-title>-->
<!--                    {{assemblyToEdit.title ? assemblyToEdit.title : 'ایجاد مجمع جدید'}}-->
<!--                </v-card-title>-->
<!--                <v-divider></v-divider>-->
<!--                <v-card-text>-->
<!--                    <v-row dense class="mt-5">-->
<!--                        <v-col cols="12" sm="6">-->
<!--                            <v-text-field-->
<!--                                    dense outlined-->
<!--                                    label="عنوان"-->
<!--                                    v-model="assemblyEditData.title">-->
<!--                            </v-text-field>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6">-->
<!--                            <div class="d-flex justify-space-around">-->
<!--                                <div class="mt-2">وضعیت:</div>-->
<!--                                <v-radio-group v-model="assemblyEditData.state" class="my-0">-->
<!--                                    <v-radio-->
<!--                                            v-for="n in 2"-->
<!--                                            :key="n"-->
<!--                                            class="mb-0 px-5"-->
<!--                                            :label="n==1 ? 'فعال' : 'غیرفعال'"-->
<!--                                            :value="n"-->
<!--                                    ></v-radio>-->
<!--                                </v-radio-group>-->
<!--                            </div>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="12" md="12" class="py-0">-->
<!--                            <v-col md="6" class="pa-0">-->
<!--                                <v-dialog-->
<!--                                        ref="startTimeClock"-->
<!--                                        v-model="modal2"-->
<!--                                        :return-value.sync="assemblyEditData.startTime"-->
<!--                                        width="290px"-->
<!--                                >-->
<!--                                    <template v-slot:activator="{ on }">-->
<!--                                        <v-text-field-->
<!--                                                v-model="assemblyEditData.startTime"-->
<!--                                                label="ساعت شروع"-->
<!--                                                prepend-inner-icon="access_time"-->
<!--                                                dense outlined-->
<!--                                                v-on="on"-->
<!--                                        ></v-text-field>-->
<!--                                    </template>-->
<!--                                    <v-time-picker-->
<!--                                            v-if="modal2"-->
<!--                                            use-seconds-->
<!--                                            v-model="assemblyEditData.startTime"-->
<!--                                            full-width-->
<!--                                    >-->
<!--                                        <v-spacer></v-spacer>-->
<!--                                        <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>-->
<!--                                        <v-btn text color="primary" @click="$refs.startTimeClock.save(assemblyEditData.startTime)">OK</v-btn>-->
<!--                                    </v-time-picker>-->
<!--                                </v-dialog>-->
<!--                            </v-col>-->
<!--                            <v-spacer />-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6" md="4" class="mb-5">-->
<!--                            <div class="mb-3">تاریخ تقریبی جلسه:</div>-->
<!--                            <DatePicker inputFormat="YYYY-MM-DD" format="jYYYY-jMM-jDD" color="#4caf50" v-model="assemblyEditData.approximateDate" class=""></DatePicker>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="4" class="mb-5">-->
<!--                            <div class="mb-3">تاریخ شروع:</div>-->
<!--                            <DatePicker inputFormat="YYYY-MM-DD" format="jYYYY-jMM-jDD" color="#4caf50" v-model="assemblyEditData.startDate" class=""></DatePicker>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="4" class="mb-5">-->
<!--                            <div class="mb-3">تاریخ پایان:</div>-->
<!--                            <DatePicker inputFormat="YYYY-MM-DD" format="jYYYY-jMM-jDD" color="#4caf50" v-model="assemblyEditData.endDate" class=""></DatePicker>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6">-->
<!--                            <v-text-field type="number" dense outlined v-model="assemblyEditData.realUserPersonLimit"-->
<!--                                          label="محدودیت اشخاص حقیقی">-->
<!--                            </v-text-field>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6">-->
<!--                            <v-text-field type="number" dense outlined v-model="assemblyEditData.legalUserPersonLimit"-->
<!--                                          label="محدودیت اشخاص حقوقی">-->
<!--                            </v-text-field>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="12" md="12" class="mb-5">-->
<!--                            <v-divider class="mb-5"/>-->
<!--                            <v-checkbox-->
<!--                                    class="mt-0"-->
<!--                                    v-model="assemblyEditData.hasOnlineMeeting"-->
<!--                                    label="این مجمع جلسه آنلاین دارد"-->
<!--                                    color="primary"-->
<!--                                    :value="assemblyEditData.hasOnlineMeeting"-->
<!--                                    hide-details-->
<!--                            ></v-checkbox>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6" md="6" v-if="assemblyEditData.hasOnlineMeeting">-->
<!--                            <v-dialog-->
<!--                                    ref="liveMeetingTimeClock"-->
<!--                                    v-model="modal3"-->
<!--                                    :return-value.sync="assemblyEditData.liveMeetingTime"-->
<!--                                    width="290px"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on }">-->
<!--                                    <v-text-field-->
<!--                                            v-model="assemblyEditData.liveMeetingTime"-->
<!--                                            label="ساعت شروع"-->
<!--                                            prepend-inner-icon="access_time"-->
<!--                                            dense outlined-->
<!--                                            v-on="on"-->
<!--                                    ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-time-picker-->
<!--                                        v-if="modal3"-->
<!--                                        use-seconds-->
<!--                                        v-model="assemblyEditData.liveMeetingTime"-->
<!--                                        full-width-->
<!--                                >-->
<!--                                    <v-spacer></v-spacer>-->
<!--                                    <v-btn text color="primary" @click="modal3 = false">Cancel</v-btn>-->
<!--                                    <v-btn text color="primary" @click="$refs.liveMeetingTimeClock.save(assemblyEditData.liveMeetingTime)">OK</v-btn>-->
<!--                                </v-time-picker>-->
<!--                            </v-dialog>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6" v-if="assemblyEditData.hasOnlineMeeting">-->
<!--                            <DatePicker inputFormat="YYYY-MM-DD"  format="jYYYY-jMM-jDD" color="#4caf50" v-model="assemblyEditData.liveMeetingDate" class=""></DatePicker>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-card-text>-->
<!--                <v-card-actions>-->
<!--                    <v-spacer></v-spacer>-->

<!--                    <v-btn-->
<!--                            color="red"-->
<!--                            text-->
<!--                            class="elevation-0"-->
<!--                            @click="editAssemblyDialog = false"-->
<!--                    >-->
<!--                        بستن-->
<!--                    </v-btn>-->

<!--                    <v-btn-->
<!--                            color="primary"-->
<!--                            class="elevation-0"-->
<!--                            @click="addAssembly"-->
<!--                    >-->
<!--                        ثبت-->
<!--                    </v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
        <v-dialog v-model="deleteDialog"
                  v-if="assemblyToRemove !=null"
                  max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>توجه</span>
                </v-card-title>
                <v-divider class="pa-2"></v-divider>

                <v-card-text>
                    آیا نسبت به حذف مجمع اطمینان دارید؟
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="secondary"
                            text
                            @click="deleteDialog = false"
                    >
                        خیر
                    </v-btn>

                    <v-btn
                            color="primary"
                            text
                            @click="deleteAssembly"
                    >
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-card-title>
            <v-btn text class="primary--text" @click="$emit('return')">
                <v-icon small class="mx-1">fas fa-long-arrow-alt-right</v-icon>
                بازگشت به شرکت ها
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title>
            شرکت {{agency.companyBaseInfo.name}}
        </v-card-title>


        <v-container>
            <v-row>
                <v-card-title>
                    مجامع
                </v-card-title>
                <v-spacer />
                <v-btn class="ma-2" dark color="blue-grey lighten-3"  @click="showAddAssembly()">
                    <v-icon small dark left color="secondary darken-1">fas fa-plus</v-icon>افزودن مجمع
                </v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-card-text>
                <v-row justify="center">
                    <v-col v-for="(assembly,index) in assemblies" :key="index" cols="12" lg="6" sm="8" md="6">
                        <v-card>
                            <v-card-title class="py-2">
                                {{assembly.title}}
                                <v-spacer></v-spacer>
                                <v-chip small v-if="assembly.hasOnlineMeeting" label>
                                    {{assembly.liveMeetingDateTime | toJalali}}
                                    <v-icon class="mx-1" small color="primary"> fas fa-video</v-icon>
                                </v-chip>
                            </v-card-title>
                            <v-divider></v-divider>
                            <!--<v-card-text v-html="assembly.description">-->
                            <!--</v-card-text>-->
                            <v-card-text>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="elevation-0 primary--text" text>
                                    جزئیات
                                </v-btn>
                            </v-card-actions>
                            <v-divider></v-divider>
                            <v-card-actions class="pt-1 mt-1">
                                <v-chip label small class="mx-1" color="primary">{{assembly.startDateTime | toJalali}}
                                </v-chip>
                                <v-chip label small class="mx-1" color="secondary">{{assembly.endDateTime | toJalali}}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <!--<v-icon small v-if="assembly.started" class="mx-1 " color="green">-->
                                <!--fas fa-calendar-check-->
                                <!--</v-icon>-->
                                <!--<v-icon small color="primary" v-if="assembly.hasOnlineMeeting"> fas fa-video-->
                                <!--</v-icon>-->
                                <v-btn fab small text @click="showDeleteDialog(assembly)">
                                    <v-icon small color="red">
                                        fas fa-trash
                                    </v-icon>

                                </v-btn>
                                <v-btn fab small text @click="showEditAssembly(assembly)">
                                    <v-icon small color="primary">
                                        fas fa-pen
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <!--<v-col v-for="(assembly,index) in assemblies" :key="index" cols="6" sm="4" class="my-4">-->
                <!--<v-card class="elevation-1">-->
                <!--<v-img :src="'http://betabours.ir:8080'+company.avatar"-->
                <!--max-height="150"-->
                <!--&gt;-->
                <!--</v-img>-->
                <!--<v-card-title>-->
                <!--{{assembly.title | excerpt}}-->
                <!--</v-card-title>-->
                <!--</v-card>-->
                <!--<v-card-actions>-->
                <!--<v-btn class="primary" text @click="selectAssembly(assembly)">ارائه حق رای-->
                <!--</v-btn>-->
                <!--<v-spacer></v-spacer>-->
                <!--<v-btn class="primary" text-->
                <!--@click="requestVoteAssembly(assembly)">اخذ وکالت-->
                <!--</v-btn>-->

                <!--</v-card-actions>-->

                <!--</v-col>-->

            </v-card-text>
        </v-container>
    </v-card>

</template>

<script>
    /*eslint-disable*/
    import moment from 'moment-jalali';
    import {EventBus} from "../event-bus";
    import DateWrapper from "@/components/DateWrapper.vue";
    import DatePicker from 'vue-persian-datetime-picker';
    import assemblyForm from "./assemblyForm";

    export default {
        components:{
            DateWrapper,
            DatePicker,
            assemblyForm
        },
        props: ['agency'],
        data() {
            return {
                startTimeDialog: false,
                modal: false,
                modal2:false,
                modal3: false,
                assemblyEditData: {
                    title: null,
                    approximateDate: null,
                    startDate: null,
                    startTime: null,
                    endDate: null,
                    endTime: null,
                    description: null,
                    hasOnlineMeeting: null,
                    liveMeetingDate: null,
                    liveMeetingTime: null,
                    state: null,
                    realUserPersonLimit: null,
                    legalUserPersonLimit: null,
                },
                editAssemblyDialog: false,
                addAssemblyDialog: false,
                assemblies: [],
                deleteDialog: false,
                assemblyToRemove: null,
                assemblyToEdit: null

            }
        },

        created() {

            this.getAssemblies();
        },
        computed: {
            validAssemblies() {
                return this.assemblies.filter(item => !item.expired);
            }
        },
        methods: {
            showEditAssembly(assembly) {
                console.log(assembly)

                this.assemblyToEdit = assembly;
                this.assemblyEditData.title = assembly.title;
                this.assemblyEditData.approximateDate = assembly.approximateDate;
                this.assemblyEditData.startDate = moment(assembly.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.assemblyEditData.startTime = moment(assembly.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                this.assemblyEditData.endDate = moment(assembly.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.assemblyEditData.endTime = moment(assembly.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                this.assemblyEditData.description = assembly.description;
                this.assemblyEditData.hasOnlineMeeting = assembly.hasOnlineMeeting;
                this.assemblyEditData.liveMeetingDate = assembly.liveMeetingDateTime.length != 0 ? moment(assembly.liveMeetingDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : "";
                this.assemblyEditData.liveMeetingTime = assembly.liveMeetingDateTime.length != 0 ? moment(assembly.liveMeetingDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') : "";
                this.assemblyEditData.state = assembly.state;
                this.assemblyEditData.realUserPersonLimit = assembly.realUserPersonLimit;
                this.assemblyEditData.legalUserPersonLimit = assembly.legalUserPersonLimit;

                this.editAssemblyDialog = true;
            },
            editAssembly() {
                let data = {
                    title: this.assemblyEditData.title,
                    approximateDate: this.assemblyEditData.approximateDate,
                    startDateTime: this.assemblyEditData.startDate + ' ' + this.assemblyEditData.startTime,
                    endDateTime: this.assemblyEditData.endDate + ' ' + this.assemblyEditData.endTime,
                    description: this.assemblyEditData.description,
                    hasOnlineMeeting: this.assemblyEditData.hasOnlineMeeting,
                    liveMeetingDateTime: this.assemblyEditData.liveMeetingDate + ' ' + this.assemblyEditData.liveMeetingTime,
                    state: this.assemblyEditData.state.value,
                    realUserPersonLimit: this.assemblyEditData.realUserPersonLimit,
                    legalUserPersonLimit: this.assemblyEditData.legalUserPersonLimit
                }
                if(!data.hasOnlineMeeting) {
                    data.liveMeetingDateTime = ""
                }
                this.$store.dispatch('companyEditVotingEvent', {
                    voteId: this.assemblyToEdit.id,
                    companyId: this.agency.companyBaseInfo.id,
                    assemblyToEdit: data
                }).then(() => {
                    this.getAssemblies();
                    EventBus.$emit('notif', 'green', undefined, undefined, 'fas fa-check');
                    this.editAssemblyDialog = false;
                });
            },
            showAddAssembly() {
                this.assemblyToEdit = {};
                this.assemblyEditData.title = "";
                this.assemblyEditData.approximateDate = "";
                this.assemblyEditData.startDate = "";
                this.assemblyEditData.startTime = "";
                this.assemblyEditData.endDate = "";
                this.assemblyEditData.endTime =  "";
                this.assemblyEditData.description = "";
                this.assemblyEditData.hasOnlineMeeting = false;
                this.assemblyEditData.liveMeetingDate = "";
                this.assemblyEditData.liveMeetingTime = "";
                this.assemblyEditData.state = 1;
                this.assemblyEditData.realUserPersonLimit = null;
                this.assemblyEditData.legalUserPersonLimit = null;

                this.addAssemblyDialog = true
            },
            addAssembly() {
                let data = {
                    title: this.assemblyEditData.title,
                    approximateDate: this.assemblyEditData.approximateDate,
                    startDateTime: this.assemblyEditData.startDate + ' ' + this.assemblyEditData.startTime,
                    endDateTime: this.assemblyEditData.endDate + ' ' + this.assemblyEditData.endTime,
                    description: this.assemblyEditData.description,
                    hasOnlineMeeting: this.assemblyEditData.hasOnlineMeeting,
                    liveMeetingDateTime: this.assemblyEditData.liveMeetingDate + ' ' + this.assemblyEditData.liveMeetingTime,
                    state: this.assemblyEditData.state,
                    realUserPersonLimit: Number(this.assemblyEditData.realUserPersonLimit),
                    legalUserPersonLimit: Number(this.assemblyEditData.legalUserPersonLimit)
                }
                if(!data.hasOnlineMeeting) {
                    data.liveMeetingDateTime = ""
                }
                this.$store.dispatch('companyAddVotingEvent', {
                    companyId: this.agency.companyBaseInfo.id,
                    assemblyToAdd: data
                }).then(() => {
                    this.getAssemblies();
                    EventBus.$emit('notif', 'green', undefined, undefined, 'fas fa-check');
                    this.addAssemblyDialog = false;
                });
            },
            getAssemblies() {
                this.$store.dispatch('companyVotingEvents', this.agency.companyBaseInfo.id).then((resp) => {
                    console.log(resp);
                    this.assemblies = resp.result.data;
                })
            },
            showDeleteDialog(assembly) {
                this.assemblyToRemove = assembly;
                this.deleteDialog = true;
            },
            deleteAssembly() {
                this.$store.dispatch('companyDeleteVotingEvent', {
                    voteId: this.assemblyToRemove.id,
                    companyId: this.agency.companyBaseInfo.id
                }).then(() => {
                    EventBus.$emit('notif', 'green');
                    this.getAssemblies();
                    this.deleteDialog = false;
                });
            }
        },
        filters: {
            toJalali(input) {
                return moment(input, 'YYYY-MM-DD hh:mm:ss').format('hh:mm - jYYYY/jMM/jDD');
            }
        }

    }
</script>

<style>
.v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row !important;
}
.vpd-input-group{
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
}
.vpd-input-group input {
    width: 100%;
    height: 40px;
    border-radius: 4px 0 0 4px;
    border: 1px solid rgba(0.25, 0.8, 0.5, .3);
}
</style>
