/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';
import {LS} from '../utilities.js';
import {EventBus} from "../event-bus";

import auth from './modules/auth.js'
import blog from './modules/blog.js'
import localization from './modules/localization.js'
import companies from './modules/companies.js'
import agencies from './modules/agencies.js'
import traders from './modules/traders.js'
// import authorization from './modules/authorization.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        layout: {
            loading: false
        },
        baseURL: 'https://betabours.ir:8443/api/v1/',
        blogBaseURL: 'https://betabours.ir/blog/wp-json/wp/v2/',
        staticBaseURL: 'https://betabours.ir:8080',
    },
    mutations: {
        toggleLoading(state, data) {
            state.layout.loading = data;
        },
    },
    actions: {

        changeLocal({commit, state}, local) {
            commit("changeLocal", local);
        },
        setDefaultLanguage({commit}, country) {

            // localStorage.setItem('defaultCountry', JSON.stringify(country));
            // commit('changeDefaultCountry', country);
            // return true;
            // console.log('SETTING DEFAULT LANGUAGE');
        },

    },
    getters: {
        isPageLoading: state => state.layout.loading,
        getBaseURL: state => state.baseURL,
        getStaticBaseURL: state => state.staticBaseURL,
    },
    modules: {
        auth,
        blog,
        // // authorization,
        localization,
        companies,
        agencies,
        traders
    }
})
