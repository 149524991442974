<template>
    <v-container fluid class="pa-0 elevation-0">
        <v-row class="elevation-0">
            <v-col cols="12" class="pt-3 px-6">
                <v-banner single-line class="mb-5 elevation-1" v-if="showBanner">
                    <v-avatar
                            slot="icon"
                    >
                        <v-icon color="red">
                            info
                        </v-icon>
                    </v-avatar>

                    <span>Your working status has been set to <span
                            class="font-weight-black red--text">Deactivated</span>. to change it go to
                        <router-link
                                class="no-underline"
                                :to="{name:'settings'}">settings.
                        </router-link>
                    </span>

                    <template v-slot:actions>
                        <v-btn text @click.prevent="hideBanner">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </template>
                </v-banner>
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    // import {EventBus} from '@/../src/event-bus.js';
    // import {mapGetters} from 'vuex';

    export default {
        props: {
            source: String,
        },

        data() {
            return {
                showBanner: false,
                dialog: false,
                drawer: null,
                // items: [
                //     // {
                //     //     icon: 'dashboard',
                //     //     text: 'profileMenuUserDashboard',
                //     //     route: 'userHome',
                //     //     requiredRoles: ['customer', 'trader']
                //     // },
                //     {
                //         icon: 'fas fa-user',
                //         text: 'profileMenuUserInfo',
                //         route: 'userInfo',
                //         requiredRoles: ['customer', 'trader']
                //     },
                //     {
                //         icon: 'fas fa-list',
                //         text: 'profileMenuUserOrders',
                //         route: 'userOrders',
                //         requiredRoles: ['customer', 'trader']
                //     },
                //     {
                //         icon: 'mdi-chevron-up',
                //         'icon-alt': 'mdi-chevron-down',
                //         text: 'profileMenuOrderRequests',
                //         model: false,
                //         requiredRoles: ['trader'],
                //         children: [
                //             {text: 'Accepted', route: 'acceptedRequests', icon: 'fas fa-check'},
                //             {text: 'New', route: 'newRequests', icon: 'fas fa-clipboard-list'},
                //             {text: 'Started', route: 'startedRequests', icon: 'fas fa-tasks'},
                //         ],
                //     },
                //     // {
                //     //     icon: 'fas fa-random',
                //     //     text: 'profileMenuOrderRequests',
                //     //     route: 'requests',
                //     //     requiredRoles: ['trader']
                //     // },
                //     // {
                //     //     icon: 'fas fa-toolbox',
                //     //     text: 'profileMenuAcceptedRequests',
                //     //     route: 'acceptedRequests',
                //     //     requiredRoles: ['trader']
                //     // },
                //     {
                //         icon: 'fas fa-credit-card',
                //         text: 'profileMenuUserWallet',
                //         route: 'userWallet',
                //         requiredRoles: ['customer', 'trader']
                //     },
                //     {
                //         icon: 'mdi-tools',
                //         text: 'profileMenuUserProfessions',
                //         route: 'workerSkills',
                //         requiredRoles: ['trader']
                //     },
                //     {
                //         icon: 'mdi-map',
                //         text: 'profileMenuUserAddresses',
                //         route: 'userAddresses',
                //         requiredRoles: ['customer', 'trader']
                //     },
                //     {
                //         icon: 'fas fa-hands-helping',
                //         text: 'profileMenuBecomeAWorker',
                //         route: 'customerExpertSignup',
                //         requiredRoles: ['customer']
                //     },
                //     {
                //         icon: 'mdi-settings',
                //         text: 'Settings',
                //         route: 'settings',
                //         requiredRoles: ['trader']
                //     },
                //     {icon: 'mdi-message', text: 'Send feedback'},
                //     {icon: 'mdi-help-circle', text: 'Help'},
                //     {icon: 'mdi-cellphone-link', text: 'Download App'},
                // ],
                isMobile: false,
                cancelledBanner: false
            }
        },
        methods: {
            gotoPage(item) {
                console.log("----------------------START-item----------------------");
                console.log(item);
                console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-item+-+-+-+-+-+-+-+-+-+-+-");
                if (item.route != undefined) {
                    this.$router.push({name: item.route});
                }
            },
            resizeEvent() {
                if (window.innerWidth < 800) {
                    this.usMo
                }
            },
            checkBannerStatus() {

                //
                if (this.$store.getters.getUserProfile.activeState.id == 2 && this.$route.name != 'settings' && !this.cancelledBanner) {
                    this.showBanner = true;
                } else {
                    this.showBanner = false;
                }
            },
            hideBanner() {
                this.showBanner = false;
                this.cancelledBanner = true;
            }
        },
        created() {
            // this.checkBannerStatus();

            window.addEventListener("resize", this.resizeEvent);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEvent);
        },
        mounted() {
            // if (this.$store.getters.isProfessional) {

            // EventBus.$emit('all-loaded');
            // this.items.push({icon: 'mdi-map', text: 'profileMenuUserProfessions', route: 'workerSkills'});

        },
        computed: {
            activeState() {
                return this.$store.getters.getWorkerActiveState
            }
        },
        watch: {
            'activeState'() {
                // this.checkBannerStatus();
            },

            '$route'() {
                // this.checkBannerStatus();
            }
        }

    }
</script>

<style>

    .no-underline {
        text-decoration: none;
    }

    .highlighted {
        background-color: rgba(0, 0, 200, .1);
    }
</style>
